
import { ContentAssessor } from 'vue-yoast'
import AssessorList from './assessor-list.vue'
import AssessorMixin from '../mixin.js'

export default {
  name: 'ContentAssessor',
  extends: ContentAssessor,
  mixins: [AssessorMixin],
  components: {
    AssessorList
  }
}
