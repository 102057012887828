module.exports = {
  theme: {
    colors: {
      primary: '#3490dc'
    },
    fonts: {
      opensans: [
        'Open Sans',
        'Arial',
        'sans-serif'
      ],
      roboto: [
        'Roboto',
        'Arial',
        'sans-serif'
      ]
    },
    typography: {
      body: {
        font: 'opensans',
        leading: 'md',
        color: 'black',
        weight: 'normal',
        size: 'md'
      },
      title: {
        font: 'roboto',
        leading: 'sm',
        weight: 'bold'
      },
      paragraph: {
        leading: 'lg',
        weight: 'light'
      },
      link: {
        color: 'primary'
      }
    },
    border: {
      radius: 'default',
      color: 'grey-light'
    }
  }
}
