
import { select } from 'vessel/utils'

export default {
  name: 'ModelForm',
  inheritAttrs: false,
  props: {
    model: {
      type: Object,
      default: null
    },
    path: {
      type: String,
      required: true
    },
    schema: {
      type: String,
      default: 'Form'
    },
    localeKey: {
      type: String,
      default: 'inputs'
    },
    columns: {
      type: [Number, String],
      default: 1
    },
    showButton: {
      type: Boolean,
      default: true
    },
    buttonText: {
      type: String,
      default: 'update'
    },
    gutter: {
      type: [String, Object],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingBackground: {
      type: String,
      default: null
    }
  },
  vessel: {
    form: {
      minHeight: 'full',
      $gutter: 'padding'
    },
    slotGrid: {
      $hasSlots: {
        marginTop(value) {
          return value ? 'sm' : null
        }
      }
    },
    buttons: {
      borderTopWidth: 1,
      paddingY: 'sm',
      $gutter: 'paddingX'
    }
  },
  data() {
    return {
      isValid: false,
      fields: []
    }
  },
  computed: {
    value() {
      const values = this.$refs.form.value
      return select(values, key => this.fields.includes(key))
    },
    loadingBg() {
      return this.loadingBackground == 'grey'
        ? 'rgba(250, 250, 250, 0.6)'
        : null
    }
  },
  methods: {
    onGenerate(inputs) {
      this.fields = Object.keys(inputs)
    },
    onCancel() {
      this.$emit('cancel')
    },
    onReset() {
      this.$emit('reset')
    },
    onSubmit() {
      this.submitForm()
      this.$emit('submit')
    },
    validateForm() {
      this.$refs.form.validate(valid => this.isValid = valid)
    },
    submitForm() {
      this.validateForm()

      if (this.isValid) {
        this.$emit('valid', this.value)
      } else {
        this.$emit('invalid', this.value)
      }
    },
    resetForm() {
      this.$refs.form.resetFields()
    }
  }
}
