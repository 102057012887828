import { render, staticRenderFns } from "./GeoLocation.vue?vue&type=template&id=524e5a6c&"
import script from "./GeoLocation.vue?vue&type=script&lang=js&"
export * from "./GeoLocation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseInput: require('/opt/build/repo/components/BaseInput.vue').default})
