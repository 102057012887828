
export default {
  inheritAttrs: false,
  props: {
    index: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: 'Label'
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputType() {
      const types = {
        name: 'text',
        message: 'textarea'
      }

      return types[this.type] || this.type
    },
    inputTag() {
      const tags = {
        date: 'vs-date-picker',
        time: 'vs-time-picker',
        number: 'vs-input-number'
      }

      return tags[this.type] || 'vs-input'
    },
    colSpan() {
      return this.options.size || 12
    }
  },
  methods: {
    onFocus() {
      this.$emit('activate', this.index)
    }
  }
}
