export default {
  options: {
    columns: 4
  },
  translates: [
    'name',
    'description'
  ],
  inputs: {
    name: {
      span: 4,
      required: true
    },
    description: {
      $input: 'text-editor',
      span: 4
    },
    code: {
      span: 4
    },
    default: {
      $input: 'switch',
      span: 4
    }
  }
}
