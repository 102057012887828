
export default {
  name: 'ActionSection',
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    removable: {
      type: Boolean,
      default: true
    },
    removeIcon: {
      type: String,
      default: 'trash'
    }
  },
  vessel: {
    bar: {
      paddingX: 'xs',
      paddingY: '2xs',
      borderBottomWidth: 1,
      backgroundColor: 'grey-lightest',
      $disabled: {
        color: val => val ? 'grey' : 'grey-darker'
      }
    },
    icon: {
      padding: 'xs',
      cursor: 'pointer',
      hover: {
        color: 'danger'
      }
    }
  },
  methods: {
    onRemove() {
      this.$emit('remove')
    }
  }
}
