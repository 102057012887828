import Vue from 'vue'

export default function({ app, store }, inject) {
  const tenant = Vue.observable({
    loading: false,
    get state() {
      return store.state.tenant
    },
    get active() {
      const current = this.state.previous || this.state.active
      return this.loading ? current : this.state.active
    },
    async switch(value, reload = true) {
      const redirect = '/dashboard'

      this.loading = true
      await store.dispatch('tenant/setTenant', value)

      const refresh = async () => {
        this.loading = false
        await app.$graphql.resetStore()
      }

      if (reload) {
        await app.$graphql.clearStore()
        window.location.assign(redirect)
      } else {
        app.router.push(redirect, refresh, refresh)
      }
    }
  })

  inject('tenant', tenant)
}
