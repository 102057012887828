
import Input from '@/mixins/input'

export default {
  name: 'CountryDropdown',
  mixins: [Input],
  props: {
    value: {
      type: [Array, String, Number],
      default: null
    },
    relation: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.inputValue = newValue
      },
      immediate: true
    }
  },
  data() {
    return {
      inputValue: null,
      country: null
    }
  },
  methods: {
    parser(data) {
      return {
        value: data.id,
        code: data.iso,
        label: data.name
      }
    }
  }
}
