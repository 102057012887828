
import Column from '@/mixins/column'

export default {
  name: 'CountryColumn',
  mixins: [Column],
  props: {
    isoProp: {
      type: String,
      default: 'iso'
    }
  }
}
