
import { toCamel } from 'vessel/utils'

export default {
  name: 'EditorToolbarButton',
  inject: ['toolbar'],
  props: {
    name: {
      type: String,
      required: true
    },
    command: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    compact: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  vessel: {
    button: {
      whiteSpace: 'no-wrap',
      lineHeight: 'xs',
      borderWidth: 1,
      $compact: {
        padding(value) {
          return value ? '2xs' : 'xs'
        }
      },
      $active: {
        borderColor(value) {
          return value ? 'grey-light' : 'transparent'
        },
        backgroundColor(value) {
          return value ? 'grey-lightest' : null
        },
        cursor(value) {
          return value ? 'default' : 'pointer'
        }
      }
    }
  },
  data() {
    return {
      active: false
    }
  },
  methods: {
    onUpdate() {
      this.active = this.toolbar.isActive(this.name, this.options)
    },
    onClick() {
      const command = this.command || toCamel(`toggle-${this.name}`)
      this.toolbar.runCommand(command, this.options)
    }
  },
  mounted() {
    this.toolbar.$on('update:active', this.onUpdate)
    this.onUpdate()
  },
  destroyed() {
    this.toolbar.$off('update:active', this.onUpdate)
  }
}
