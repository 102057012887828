
export default {
  name: 'InputActions',
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    totalLabel: {
      type: String,
      default: 'Total items'
    },
    totalColor: {
      type: String,
      default: 'primary'
    },
    buttonSize: {
      type: String,
      default: 'mini'
    },
    showAdd: {
      type: Boolean,
      default: true
    },
    addButtonType: {
      type: String,
      default: 'default'
    },
    addButtonText: {
      type: String,
      default: 'Add'
    },
    showClear: {
      type: Boolean,
      default: true
    },
    clearButtonType: {
      type: String,
      default: 'danger'
    },
    clearButtonText: {
      type: String,
      default: 'Clear'
    }
  },
  vessel: {
    counter: {
      marginX: '2xs',
      fontWeight: 'bold',
      $disabled: {
        color(value) {
          return value ? 'grey' : this.totalColor
        }
      }
    }
  },
  methods: {
    onAddClick() {
      this.$emit('add')
    },
    onClearClick() {
      this.$emit('clear')
    }
  }
}
