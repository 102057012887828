
import { SeoAssessor, SeoRating, SnippetPreview } from '@/packages/yoast'
import Input from '@/mixins/input'
import { isEqual, hasProp, isArray, hyphenate } from 'vessel/utils'

export default {
  name: 'SeoMeta',
  mixins: [Input],
  components: {
    SeoAssessor,
    SeoRating,
    SnippetPreview
  },
  inject: {
    seoPath: {
      default: null
    }
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    path: {
      type: String,
      default() {
        return this.seoPath
      }
    },
    contentKey: {
      type: String,
      default: 'description'
    },
    imagesKey: {
      type: [String, Array],
      default: () => ['imageAttributes', 'imagesAttributes']
    },
    showInputs: {
      type: Boolean,
      default: false
    },
    maxTitleLength: {
      type: [Number, String],
      default: 70
    },
    maxSlugLength: {
      type: [Number, String],
      default: 60
    },
    maxDescriptionLength: {
      type: [Number, String],
      default: 160
    }
  },
  vessel: {
    input: {
      overflow: 'hidden',
      display: 'grid',
      $disabled: {
        pointerEvents: val => val ? 'none' : null
      }
    },
    button: {
      padding: 'sm',
      textColor: 'grey-darker',
      cursor: 'pointer',
      lineHeight: 'lg',
      hover: {
        backgroundColor: 'grey-lightest'
      }
    }
  },
  watch: {
    showAdd(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.newKeyword.focus()
        })
      }
    },
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        const value = Object(newValue)

        this.metaTitle       = value.title
        this.metaSlug        = value.slug
        this.metaDescription = value.description
        this.metaScores      = Object(value.scores)
        this.redirects       = (value.redirects || []).map(url => ({ url }))
      }
    },
    metaTitle(title, oldValue) {
      if (title != oldValue) {
        this.updateModel({ title })
      }
    },
    metaSlug(slug, oldValue) {
      if (slug != oldValue) {
        this.updateModel({ slug })
      }
    },
    metaDescription(description, oldValue) {
      if (description != oldValue) {
        this.updateModel({ description })
      }
    },
    metaScores(scores, oldValue) {
      if (!isEqual(scores, oldValue)) {
        this.keywords = Object.keys(scores)
        this.updateModel({ scores })
      }
    },
    redirects(slugs, oldValue) {
      if (!isEqual(slugs, oldValue)) {
        const redirects = slugs.filter(item => !item._destroy)
          .map(item => item.url)

        this.updateModel({ redirects })
      }
    }
  },
  data() {
    return {
      metaTitle: null,
      metaSlug: null,
      metaDescription: null,
      metaScores: {},
      keywords: [],
      activeKeyword: null,
      newKeyword: null,
      showAdd: false,
      previewMode: 'desktop',
      showEdit: this.showInputs,
      redirects: []
    }
  },
  computed: {
    content() {
      return this.record[this.contentKey]
    },
    image() {
      const keys = [].concat(this.imagesKey)
      const ikey = keys.find(key => hasProp(key, this.record))
      const data = ikey && this.record[ikey]

      return isArray(data) ? data[0] : data
    },
    text() {
      const { image, content, metaTitle } = this

      if (image) {
        return `<img src="${image.url}" alt="${metaTitle}" />\n${content}`
      } else {
        return content
      }
    },
    seoAttrs() {
      return {
        title: this.metaTitle,
        slug: this.metaSlug,
        description: this.metaDescription,
        text: this.text,
        baseUrl: this.baseUrl,
        url: this.urlPath
      }
    },
    overallScore() {
      const scores = Object.values(this.metaScores)
      const total  = scores.reduce((sum, score) => sum + score, 0)

      return total > 0 ? Math.round(total / scores.length) : 0
    },
    baseUrl() {
      return `https://${this.$activeProperty.url}/`
    },
    basePath() {
      return this.value && this.value.basePath || this.path
    },
    urlPath() {
      const value = hyphenate(this.metaSlug || 'not-found')
      const slug  = value.toLowerCase().replace(/\s+/g, '-').replace(/-$/, '')
      const path  = this.basePath ? `${this.basePath}/${slug}` : slug

      return this.locale == this.defaultLocale ? path : `${this.locale}/${path}`
    }
  },
  methods: {
    onPreviewActivate(field) {
      this.showEdit = true

      this.$nextTick(() => {
        const elm = this.$refs[field]
        if (elm) elm.focus()
      })
    },
    onScoreUpdate({ keyword, overallScore }) {
      this.$set(this.metaScores, keyword, overallScore)
    },
    onAddKeyword() {
      const keyword = this.newKeyword

      if (keyword && !this.keywords.includes(keyword)) {
        this.keywords.push(keyword)

        this.newKeyword    = null
        this.showAdd       = false
        this.activeKeyword = keyword
      }
    },
    onRemoveKeyword(index, keyword) {
      this.keywords.splice(index, 1)
      this.$delete(this.metaScores, keyword)

      if (this.activeKeyword == keyword) {
        this.activeKeyword = null
        this.activateKeyword()
      }
    },
    tabClass(index, keyword) {
      return this.$vessel.apply({
        paddingY: '2xs',
        paddingX: 'xs',
        cursor: 'pointer',
        backgroundColor: this.activeKeyword == keyword ? 'grey-lightest' : null,
        borderRightWidth: 1
      })
    },
    activateKeyword() {
      if (this.keywords.length) {
        this.activeKeyword = this.keywords[0]
      }
    },
    toggleKeyword(keyword) {
      if (this.activeKeyword == keyword) {
        this.activeKeyword = null
      } else {
        this.activeKeyword = keyword
      }
    },
    updateModel(newValue) {
      this.$emit('input', {
        ...this.value,
        ...newValue
      })
    }
  }
}
