
import Input from '@/mixins/input'
import { isEqual } from 'vessel/utils'

export default {
  name: 'ShippingCalculatorInput',
  mixins: [Input],
  props: {
    value: {
      type: Object,
      default: null
    },
    dataKey: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    input: {
      overflow: 'hidden',
      $disabled: {
        backgroundColor: val => val && 'grey-lightest',
        color: val => val && 'grey'
      }
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.calculator = this.parseValue(newValue)
      }
    },
    calculator(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.$emit('input', newValue)
      }
    }
  },
  data() {
    return {
      calculator: this.parseValue(this.value)
    }
  },
  computed: {
    kind() {
      const name = this.calculator.kind
      const comp = name.toLowerCase().replace(/_/g, '-')

      return `input-calculators-payment-${comp}`
    },
    kindEnum() {
      return Object(this.record[this.dataKey]).kindEnum || {}
    },
    kindOptions() {
      return this.kindEnum.enumeration || []
    }
  },
  methods: {
    parseValue(value) {
      return { kind: 'FLAT_RATE', options: {}, ...value }
    },
    onKindChange() {
      this.$set(this.calculator, 'options', {})
    }
  }
}
