
export default {
  name: 'TopBar',
  inject: {
    $layout: {
      default: null
    }
  },
  vessel: {
    topbar: {
      backgroundColor: 'white',
      paddingX: 'md'
    }
  },
  methods: {
    onMenuClick() {
      if (this.$layout) {
        this.$layout.toggleSidebar()
      }
    }
  }
}
