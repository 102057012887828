export default {
  options: {
    columns: 4
  },
  inputs: {
    email: {
      type: 'email',
      span: 4,
      required: true
    }
  }
}
