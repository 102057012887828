
import Input from '@/mixins/input'

export default {
  name: 'RegionSelectInput',
  mixins: [Input],
  props: {
    value: {
      type: [Array, String, Number],
      default: null
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.inputValue = newValue
      }
    }
  },
  data() {
    return {
      inputValue: null,
      region: null
    }
  },
  methods: {
    parser(data) {
      return {
        key: data.id,
        value: data[this.valueKey],
        label: data.name
      }
    }
  }
}
