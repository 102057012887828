
export default {
  name: 'SelectDropdownList',
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    clearOnSelect: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    wrapper: {
      $disabled: {
        color: val => val && 'grey',
        pointerEvents: val => val && 'none'
      }
    },
    icon: {
      $disabled: {
        color: val => val && 'grey'
      }
    },
    active: {
      paddingX: 'xs',
      paddingY: '2xs',
      fontSize: 'xs',
      $active: {
        color(val) {
          if (this.disabled) {
            return 'grey'
          } else {
            return !val && 'grey-darker'
          }
        }
      }
    }
  },
  watch: {
    value(value) {
      if (value != this.active) {
        this.active = value
      }
    }
  },
  data() {
    return {
      active: this.value
    }
  },
  computed: {
    activeLabel() {
      if (this.active) {
        const active = this.options.find(({ value }) => this.active == value)
        return active && active.label
      } else {
        return this.placeholder
      }
    }
  },
  methods: {
    setActive(value) {
      this.active = value

      this.$emit('input', value)
      this.$emit('change', value)

      if (this.clearOnSelect) {
        this.active = null
      }
    }
  }
}
