import { render, staticRenderFns } from "./MainSidebar.vue?vue&type=template&id=6cb3659e&"
import script from "./MainSidebar.vue?vue&type=script&lang=js&"
export * from "./MainSidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoBar: require('/opt/build/repo/components/LogoBar.vue').default,MenuItems: require('/opt/build/repo/components/MenuItems.vue').default,MenuList: require('/opt/build/repo/components/MenuList.vue').default})
