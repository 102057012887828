export default function({ env }, inject) {
  inject('endpoints', {
    main: env.MAIN_ENDPOINT,
    data: env.DATA_ENDPOINT,
    get(client) {
      if (client.startsWith('default')) {
        return this.main
      } else {
        return this[client] || this.data
      }
    }
  })
}
