
import Sortable from 'sortablejs'

export default {
  name: 'SortableList',
  props: {
    selector: {
      type: String,
      default: null
    },
    enabled: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      default: null
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      list: this.data || [],
      sortable: null
    }
  },
  watch: {
    data(newValue) {
      this.list = newValue
    }
  },
  methods: {
    initSortable() {
      const el = this.$el.querySelector(this.selector) || this.$el
      el.classList.add('sortable-list')

      this.sortable = Sortable.create(el, {
        animation: 100,
        onStart: this.onStart,
        onEnd: this.onEnd,
        ...this.options
      })
    },
    onStart(event) {
      this.$emit('start', event, this.list)
    },
    onEnd(event) {
      if (this.list) {
        const targetRow = this.list.splice(event.oldIndex, 1)[0]

        if (targetRow) {
          this.list.splice(event.newIndex, 0, targetRow)
        }
      }

      this.$emit('end', event, this.list)
    }
  },
  beforeMount() {
    if (this.enabled) {
      this.$nextTick(() => this.initSortable())
    }
  },
  beforeDestroy() {
    if (this.sortable) {
      this.sortable.destroy()
    }
  },
  render() {
    return this.$scopedSlots.default({ list: this.list })
  }
}
