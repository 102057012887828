export default {
  options: {
    columns: 8
  },
  translates: [
    'title',
    'description'
  ],
  inputs: {
    title: {
      span: 4,
      required: true
    },
    name: {
      span: 2,
      required: true
    },
    active: {
      $input: 'switch',
      span: 2
    },
    description: {
      $input: 'text-editor',
      span: 8,
      $el: {
        minimal: true
      }
    },
    fieldsAttributes: {
      $input: 'form-builder',
      span: 8
    }
  }
}
