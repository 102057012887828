import { render, staticRenderFns } from "./ShippingCalculator.vue?vue&type=template&id=4a0b0cd6&"
import script from "./ShippingCalculator.vue?vue&type=script&lang=js&"
export * from "./ShippingCalculator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SelectDropdown: require('/opt/build/repo/components/SelectDropdown.vue').default,ActionBar: require('/opt/build/repo/components/ActionBar.vue').default,BaseInput: require('/opt/build/repo/components/BaseInput.vue').default})
