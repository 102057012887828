
import Input from '@/mixins/input'

export default {
  name: 'PercentageInput',
  mixins: [Input],
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.percentage = newValue && newValue * 100
        }
      },
      immediate: true
    },
    percentage(newValue) {
      this.$emit('input', newValue / 100)
    }
  },
  data() {
    return {
      percentage: 0
    }
  }
}
