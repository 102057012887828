const form = {
  options: {
    columns: 4
  },
  translates: [
    'name',
    'description',
    'excerpt',
    'imageAttributes'
  ]
}

const inputs = {
  description: {
    $input: 'text-editor',
    span: 4,
    $el: {
      seo: true
    }
  },
  excerpt: {
    $input: 'text-editor',
    span: 4,
    $el: {
      minimal: true
    }
  },
  imageAttributes: {
    $input: 'file-upload',
    span: 4,
    $el: {
      clientId: 'eshop',
      type: 'image',
      multiple: false
    }
  }
}

export default {
  Create: {
    ...form,
    inputs: {
      name: {
        span: 2,
        required: true
      },
      taxonomyId: {
        $input: 'relation-select',
        span: 2,
        required: true,
        $el: {
          path: 'eshop/taxonomy',
          textKey: 'id'
        }
      },
      ...inputs
    }
  },
  Update: {
    ...form,
    inputs: {
      name: {
        span: 4,
        required: true
      },
      ...inputs
    }
  },
  Tree: {
    ...form,
    inputs: {
      name: {
        span: 4,
        required: true
      }
    }
  }
}
