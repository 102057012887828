export default {
  options: {
    columns: 4
  },
  translates: [
    'name'
  ],
  inputs: {
    name: {
      span: 4,
      required: true
    },
    continentId: {
      $input: 'relation-select',
      span: 4,
      required: true,
      $el: {
        path: 'eshop/continent',
        variables: {
          orderBy: 'name_ASC'
        }
      }
    },
    iso: {
      span: 2
    },
    iso3: {
      span: 2
    }
  }
}
