
export default {
  middleware: [
    'auth',
    'tenant',
    'redirect'
  ],
  mounted() {
    document.body.classList.add('overflow-y-scroll')
  },
  destroyed() {
    document.body.classList.remove('overflow-y-scroll')
  }
}
