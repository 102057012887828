
import Input from '@/mixins/input'
import { codemirror } from 'vue-codemirror'
import { hideOverflow, showOverflow } from 'vessel/utils'

import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/addon/edit/closetag.js'

export default {
  name: 'TextEditor',
  mixins: [Input],
  components: {
    codemirror
  },
  props: {
    options: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    input: {
      overflow: 'hidden',
      $disabled: {
        pointerEvents(value) {
          return value ? 'none' : null
        },
        backgroundColor(val) {
          return val && 'grey-lightest'
        },
        color(val) {
          return val && 'grey'
        }
      },
      $focused: {
        borderColor(val) {
          return val && { all: 'primary', hover: 'primary' }
        }
      },
      $fullscreen: {
        position(val) {
          return val && 'fixed'
        },
        zIndex(val) {
          return val && 50
        },
        borderRadius(val) {
          return val ? 'none' : true
        },
        borderWidth(val) {
          return val ? 0 : 1
        },
        pin(val) {
          return val
        }
      }
    }
  },
  data() {
    return {
      active: false,
      fullscreen: false
    }
  },
  computed: {
    focused() {
      return this.active && !this.disabled
    },
    editorAttrs() {
      const options = {
        tabSize: 2,
        lineNumbers: true,
        line: true,
        autoCloseTags: true,
        ...this.options,
        mode: 'htmlmixed',
        theme: 'neo',
        extraKeys: {
          F11: (cm) => {
            this.fullscreen = !cm.getOption('fullScreen')
            cm.setOption('fullScreen', this.fullscreen)

            if (this.fullscreen) {
              hideOverflow()
            } else {
              showOverflow()
            }
          },
          Esc: (cm) => {
            const state = cm.getOption('fullScreen')

            if (state) {
              this.fullscreen = false
              cm.setOption('fullScreen', this.fullscreen)

              showOverflow()
            }
          }
        }
      }

      return { ...this.$attrs, options }
    }
  }
}
