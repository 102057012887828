
import { isEqual } from 'vessel/utils'

export default {
  name: 'PromotionShippingAdjustmentAction',
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.action = newValue && newValue.options
      }
    },
    action(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.$emit('input', {
          ...this.value,
          type: 'shipping_adjustment',
          options: newValue
        })
      }
    }
  },
  data() {
    return {
      action: this.value && this.value.options
    }
  }
}
