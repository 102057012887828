import Middleware from './middleware'

Middleware.authRoute = async function ({ app, route }) {
  const handler = async () => {
    await app.$auth.autoLogin()

    if (app.$auth.loggedIn) {
      app.$auth.redirect()
    }
  }

  if (route.path.match('/auth/')) {
    return handler()
  }
}
