import { render, staticRenderFns } from "./CodeEditor.vue?vue&type=template&id=61901c47&"
import script from "./CodeEditor.vue?vue&type=script&lang=js&"
export * from "./CodeEditor.vue?vue&type=script&lang=js&"
import style0 from "./CodeEditor.vue?vue&type=style&index=0&id=61901c47&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseInput: require('/opt/build/repo/components/BaseInput.vue').default})
