import { render, staticRenderFns } from "./ModelDialog.vue?vue&type=template&id=d29255c0&"
import script from "./ModelDialog.vue?vue&type=script&lang=js&"
export * from "./ModelDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModelForm: require('/opt/build/repo/components/ModelForm.vue').default,FormDialog: require('/opt/build/repo/components/FormDialog.vue').default})
