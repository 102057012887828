
import { isEqual } from 'vessel/utils'

export default {
  name: 'Calculator',
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        const value = this.parseValue(newValue)

        this.calculator = value.type
        this.rate       = value.options.rate
      }
    },
    calculator(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit('input', { type: newValue, options: { rate: this.rate } })
      }
    },
    rate(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit('input', { type: this.calculator, options: { rate: newValue } })
      }
    }
  },
  data() {
    const value = this.parseValue(this.value)

    return {
      calculator: value.type,
      rate: value.options.rate,
      typeOptions: [
        { label: 'Flat Percent', value: 'flat_percent' },
        { label: 'Flat Rate', value: 'flat_rate' }
      ]
    }
  },
  computed: {
    isPercentage() {
      return this.calculator == 'flat_percent'
    },
    prefix() {
      const prefixes = { flat_percent: '%', flat_rate: 'EUR' }
      return prefixes[this.calculator]
    }
  },
  methods: {
    parseValue(value) {
      const { type, options } = value || {}
      return { type: type || 'flat_percent', options: { rate: 0, ...options } }
    }
  }
}
