export default {
  options: {
    columns: 4
  },
  translates: [
    'name'
  ],
  inputs: {
    name: {
      span: 4,
      required: true
    },
    countryId: {
      $input: 'country-select',
      span: 4,
      required: true
    },
    abbr: {
      span: 4
    }
  }
}
