import { render, staticRenderFns } from "./Nestable.vue?vue&type=template&id=d4d34166&"
import script from "./Nestable.vue?vue&type=script&lang=js&"
export * from "./Nestable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputActions: require('/opt/build/repo/components/InputActions.vue').default,NestedDraggable: require('/opt/build/repo/components/NestedDraggable.vue').default,ModelDialog: require('/opt/build/repo/components/ModelDialog.vue').default,BaseInput: require('/opt/build/repo/components/BaseInput.vue').default})
