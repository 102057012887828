
import Input from '@/mixins/input'
import { nanoid } from 'nanoid'

export default {
  name: 'AttributeAssignments',
  mixins: [Input],
  props: {
    path: {
      type: String,
      default: null
    },
    query: {
      type: String,
      default: null
    },
    variables: {
      type: Function,
      default: null
    },
    dataKey: {
      type: String,
      default: null,
      required: true
    },
    valuesKey: {
      type: String,
      default: 'values'
    },
    value: {
      type: Array,
      default: null
    }
  },
  watch: {
    value(newValue) {
      this.values = newValue
    }
  },
  data() {
    return {
      options: [],
      values: [],
      queryData: null,
      dialogKey: nanoid(),
      createModel: {},
      createProperty: null,
      dialogLoading: false
    }
  },
  computed: {
    data() {
      if (this.queryData) {
        return this.queryData[this.dataKey] || {}
      } else {
        return this.record[this.dataKey] || {}
      }
    },
    valueIds() {
      return this.values.map(({ valueId }) => valueId)
    }
  },
  methods: {
    async fetchData() {
      const { data } = await this.$graphql.query({
        path: this.path,
        query: this.query,
        locale: this.locale,
        fetchPolicy: 'network-only',
        variables: this.variables && this.variables.call(this)
      })

      this.queryData = data
    },
    itemValue(option) {
      const valKey = this.valuesKey
      const values = option[valKey].map(({ id }) => id)

      return this.valueIds.find(item => values.includes(item))
    },
    onChange(option, newValue) {
      const oldValue = this.itemValue(option)
      const optValue = this.values.find(({ valueId }) => valueId == oldValue)

      if (optValue) {
        optValue.valueId = newValue
      } else {
        this.values.push({ id: null, valueId: newValue })
      }

      this.$emit('input', this.values)
    },
    onAddValueClick(option) {
      this.createProperty = option
      this.$nextTick(() => this.$refs.createDialog.open())
    },
    onCreateReset() {
      this.createModel = {}
    },
    async onCreateValid(value) {
      const dialog = this.$refs.createDialog

      this.dialogLoading = true

      const { data } = await this.$graphql.mutate({
        path: 'eshop/attribute-value',
        mutation: 'Create',
        locale: this.locale,
        variables: {
          input: {
            data: {
              propertyId: this.createProperty.id,
              ...value
            }
          }
        }
      })

      if (this.path) {
        await this.fetchData()
      }

      this.dialogLoading = false

      this.$nextTick(() => {
        this.onChange(this.createProperty, data.id)

        this.createModel    = {}
        this.createProperty = null

        dialog.close()
        this.dialogKey = nanoid()
      })
    }
  },
  mounted() {
    if (this.path) {
      this.$nextTick(this.fetchData)
    }
  }
}
