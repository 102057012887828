
import { isEqual } from 'vessel/utils'

export default {
  name: 'OrderItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  vessel: {
    label: {
      marginBottom: 'xs'
    },
    value: {
      lineHeight: 'xl'
    }
  },
  watch: {
    value(value) {
      if (!isEqual(value, this.data)) {
        this.data = value
      }
    }
  },
  data() {
    return {
      data: this.value
    }
  },
  computed: {
    variant() {
      return this.item.variant || {}
    },
    product() {
      return this.variant.product || {}
    },
    image() {
      const image = this.variant.image || this.product.image
      return { src: '/image-missing.svg', ...image }
    },
    quantity() {
      return this.data.quantity || 1
    },
    priceMoney() {
      return this.item.price || {}
    },
    currency() {
      return this.priceMoney.currency || 'EUR'
    },
    price() {
      return this.priceMoney.value || 0
    },
    total() {
      return this.quantity * this.price
    },
    priceText() {
      return this.priceMoney.formatted || this.formatMoney(0)
    },
    totalText() {
      return this.formatMoney(this.total)
    }
  },
  methods: {
    formatMoney(amount) {
      const locale   = this.defaultLocale
      const currency = this.currency

      return amount.toLocaleString(locale, { style: 'currency', currency })
    },
    onUpdate() {
      this.$emit('input', this.data)
      this.$emit('update')
    },
    onRemove() {
      if (this.data.id) {
        this.data._destroy = true
      } else {
        this.$emit('input', null)
      }

      this.$emit('update')
    }
  }
}
