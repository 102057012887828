
import { Editor } from '@/packages/editor'
import Input from '@/mixins/input'

export default {
  name: 'TextEditor',
  mixins: [Input],
  components: {
    Editor
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    input: {
      $disabled: {
        pointerEvents(value) {
          return value ? 'none' : null
        }
      }
    }
  },
  data() {
    return {
      active: false
    }
  },
  computed: {
    editorAttrs() {
      const options = {
        border: true,
        background: 'white',
        color: 'grey-darkest'
      }

      if (this.active && !this.disabled) {
        options.border = { all: 'primary', hover: 'primary' }
      }

      if (this.disabled) {
        options.background = 'grey-lightest'
        options.color      = 'grey'
      }

      return { ...options, ...this.$attrs }
    }
  }
}
