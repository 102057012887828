export default ({ app }, inject) => {
  inject('activeProperty', {
    get tenant() {
      return app.$tenant.active
    },
    get exists() {
      const properties = app.$user.data.properties || []
      return properties.some(({ key }) => key == this.tenant)
    },
    get data() {
      const properties = app.$user.data.properties || []
      return properties.find(({ key }) => key == this.tenant) || {}
    },
    get name() {
      return this.data.name
    },
    get subdomain() {
      const service = process.env.SERVICE_URL
      return `${this.data.subdomain || 'www'}.${service}`
    },
    get domain() {
      return this.data.domain
    },
    get url() {
      return this.domain ? `www.${this.domain}` : this.subdomain
    },
    get services() {
      return this.data.services || []
    },
    get settings() {
      const general = { defaultLanguage: 'en', availableLanguages: ['en'] }
      return { general, ...this.data.settings }
    },
    get defaultLocale() {
      return this.settings.general.defaultLanguage
    },
    get availableLocales() {
      return this.settings.general.availableLanguages
    },
    get localized() {
      return this.availableLocales.length > 1
    },
    hasService(service) {
      return this.services.includes(service)
    },
    hasFeature(service, feature) {
      const staticFeature = (name) => {
        switch (name) {
          case 'orders':
          case 'bookings':
            return this.hasService('auth')
          default:
            return true
        }
      }

      if (this.hasService(service)) {
        return feature ? staticFeature(feature) : true
      } else {
        return false
      }
    }
  })

  if (process.client) {
    window.onNuxtReady(() => app.$user.fetch())
  }
}
