
import { ContentAssessor } from '@/packages/yoast'

export default {
  name: 'EditorContentAssessor',
  components: {
    ContentAssessor
  },
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  vessel: {
    seoBar: {
      paddingY: 'xs',
      paddingX: 'sm',
      borderTopWidth: 1,
      fontSize: 'xs',
      textColor: 'grey-darker',
      $showDetails: {
        borderBottomWidth(value) {
          return value ? 1 : null
        },
        backgroundColor(value) {
          return value ? 'grey-lightest' : null
        }
      }
    }
  },
  data() {
    return {
      showDetails: false,
      seoScore: {
        score: 0,
        icon: 'circle',
        color: 'grey'
      }
    }
  },
  methods: {
    onAssessorUpdate({ overallScore, overallIcon, overallColor }) {
      this.$set(this.seoScore, 'score', overallScore)
      this.$set(this.seoScore, 'icon', overallIcon)
      this.$set(this.seoScore, 'color', overallColor)
    }
  }
}
