
export default {
  props: {
    fields: {
      type: Array,
      default: null
    },
    active: {
      type: Number,
      default: null
    },
    activeChild: {
      type: Number,
      default: null
    },
    breakpoint: {
      type: String,
      default: 'desktop'
    }
  },
  data() {
    return {
      screens: {
        desktop: 'desktop',
        tablet: 'tablet',
        mobile: 'mobile-android'
      }
    }
  },
  computed: {
    maxWidth() {
      const widths = { tablet: '768px', mobile: '480px' }
      return widths[this.breakpoint] || '100%'
    }
  },
  methods: {
    setScreen(bp) {
      this.$emit('screen', bp)
    }
  }
}
