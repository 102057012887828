
export default {
  name: 'MenuList',
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: null
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    label: {
      type: Boolean,
      default: true
    },
    defaultIcon: {
      type: String,
      default: 'circle'
    },
    icon: {
      type: [String, Boolean],
      default: 'left'
    },
    iconSize: {
      type: String,
      default: 'sm'
    },
    paddingX: {
      type: [String, Object],
      default: 'md'
    },
    paddingY: {
      type: [String, Object],
      default: 'sm'
    },
    marginY: {
      type: [String, Object],
      default: null
    },
    textSize: {
      type: [String, Object],
      default: 'sm'
    },
    color: {
      type: [String, Object],
      default: 'grey-dark'
    },
    activeColor: {
      type: [String, Object],
      default: 'white'
    },
    hoverColor: {
      type: [String, Object],
      default: 'grey-darkest'
    },
    activeBackground: {
      type: [String, Object],
      default: 'primary'
    }
  },
  vessel: {
    icon: {
      $iconSize: 'fontSize',
      $rightIcon: {
        marginLeft(value) {
          return value ? this.iconMargin : null
        },
        marginRight(value) {
          return value ? null : this.iconMargin
        }
      }
    }
  },
  computed: {
    showLabel() {
      return this.label && !this.collapsed
    },
    rightIcon() {
      return this.icon == 'right'
    },
    iconMargin() {
      return this.showLabel ? 'xs' : null
    },
    showIcon() {
      return this.icon !== false
    },
    itemProps() {
      const justify = this.rightIcon ? 'between' : 'start'

      return {
        items: 'center',
        direction: this.rightIcon ? 'row-reverse' : 'row',
        justify: this.showLabel ? justify : 'center'
      }
    },
    activeItem() {
      const exact = this.items.find(({ route }) => this.$route.path == route)
      const child = this.items.find(({ route }) => this.$route.path.startsWith(route))

      return exact ? Object(exact).route : Object(child).route
    }
  },
  methods: {
    linkItemClass({ disabled }) {
      return this.$vessel.apply({
        outline: 'none',
        opacity: disabled ? 50 : null,
        pointerEvents: disabled ? 'none' : null
      })
    },
    itemClass({ route }) {
      const fill   = this.collapsed ? null : this.activeBackground
      const aColor = this.collapsed ? this.activeBackground : this.activeColor
      const active = this.activeItem == route

      return this.$vessel.apply({
        paddingX: this.paddingX,
        paddingY: this.paddingY,
        marginY: this.marginY,
        textSize: this.textSize,
        all: {
          textColor: active ? aColor : this.color,
          backgroundColor: active ? fill : null
        },
        hover: {
          textColor: active ? aColor : this.hoverColor
        }
      })
    }
  }
}
