export default {
  options: {
    columns: 6
  },
  translates: [
    'name',
    'description',
    'excerpt'
  ],
  inputs: {
    name: {
      span: 6,
      required: true
    },
    description: {
      $input: 'text-editor',
      span: 6,
      $el: {
        seo: true
      }
    },
    excerpt: {
      $input: 'text-editor',
      span: 6,
      $el: {
        minimal: true
      }
    },
    promotable: {
      $input: 'switch',
      span: 3
    },
    backorderable: {
      $input: 'switch',
      span: 3
    },
    taxCategoryId: {
      $input: 'relation-select',
      span: 3,
      $el: {
        path: 'eshop/tax-category',
        textKey: 'id'
      }
    },
    shippingCategoryId: {
      $input: 'relation-select',
      span: 3,
      $el: {
        path: 'eshop/shipping-category',
        textKey: 'id'
      }
    },
    selfTaxonIds: {
      $input: 'taxons',
      span: 6,
      label: false
    },
    propertyAssignmentsAttributes: {
      $input: 'attributes',
      label: 'properties',
      span: 6,
      $el: {
        type: 'PROPERTY'
      }
    },
    optionAssignmentsAttributes: {
      $input: 'attributes',
      label: 'options',
      span: 6,
      $el: {
        type: 'OPTION'
      }
    }
  }
}
