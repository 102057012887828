
import Input from '@/mixins/input'
import Draggable from 'vuedraggable'
import { isEqual, clone } from 'vessel/utils'

export default {
  name: 'AddMore',
  mixins: [Input],
  components: {
    Draggable
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    sortable: {
      type: Boolean,
      default: false
    },
    sortKey: {
      type: String,
      default: 'sortOrder'
    },
    inputs: {
      type: Array,
      default: () => []
    },
    inline: {
      type: Boolean,
      default: false
    },
    columns: {
      type: [String, Number, Object],
      default: null
    },
    gap: {
      type: [String, Object],
      default: 'sm'
    },
    rowGap: {
      type: [String, Object],
      default: 'xs'
    },
    splitRows: {
      type: [Boolean, Object],
      default: () => ({ all: true, sm: false })
    },
    controlsDirection: {
      type: [String, Object],
      default: null
    },
    alignControls: {
      type: [String, Object],
      default: 'center'
    },
    primaryKey: {
      type: String,
      default: 'id'
    },
    emptyTemplate: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    translates: {
      type: Array,
      default: () => []
    }
  },
  vessel: {
    input: {
      borderWidth: 1,
      borderRadius: true,
      $disabled: {
        backgroundColor: val => val ? 'grey-lightest' : null,
        textColor: val => val ? 'grey' : null
      }
    },
    icon: {
      padding: 'xs',
      cursor: 'pointer',
      $actionsDisabled: {
        pointerEvents: val => val ? 'none' : null
      }
    },
    handle: {
      $extend: 'icon',
      $actionsDisabled: {
        cursor: val => val ? null : 'move'
      }
    },
    draggable: {
      display: 'grid',
      $rowGap: 'gridGap'
    }
  },
  data() {
    return {
      items: null,
      fieldsets: [this.newItem()]
    }
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.items     = clone(newValue)
          this.fieldsets = clone(newValue)

          if (this.isEmpty) {
            this.fieldsets.push(this.newItem(1))
          }
        }
      },
      immediate: true
    },
    items: {
      handler(newValue) {
        this.$emit('input', newValue)
      },
      deep: true
    },
    fieldsets: {
      handler(newValue) {
        this.items = this.filterInvalid(newValue)
      },
      deep: true
    }
  },
  computed: {
    split() {
      return this.$screen.match(this.splitRows)
    },
    wrap() {
      return !this.inline && !this.split
    },
    isEmpty() {
      if (this.items) {
        return this.items.every(({ _destroy }) => _destroy == true)
      } else {
        return false
      }
    },
    hasNewItem() {
      if (this.items) {
        return this.items.length < this.fieldsets.length
      } else {
        return this.fieldsets.length > 0
      }
    },
    showTranslations() {
      return this.locale != this.defaultLocale
    },
    dragDisabled() {
      return !this.sortable || this.actionsDisabled
    },
    actionsDisabled() {
      return this.disabled || this.showTranslations
    },
    wrapperTag() {
      return this.wrap ? 'base-input' : 'div'
    },
    wrapperVessel() {
      return this.wrap && this.$vessels.input
    },
    wrapperStyle() {
      return this.wrap && { padding: '10px' }
    },
    rowVessel() {
      return this.split && { 'el-textarea__inner': true, ...this.$vessels.input }
    },
    rowStyle() {
      return this.split && { padding: '10px' }
    }
  },
  methods: {
    newItem(offset = 0) {
      const item = { ...this.emptyTemplate }

      if (this.primaryKey) {
        item._destroy = false
      }

      this.inputs.forEach(input => {
        item[input.prop] = null
      })

      if (this.sortable) {
        const items        = this.fieldsets || []
        item[this.sortKey] = items.length + offset
      }

      return item
    },
    filterInvalid(list) {
      const items = clone(list || [])
      const attrs = this.inputs.map(({ prop }) => prop)
      const empty = Object.keys(this.emptyTemplate || {})
      const props = [...attrs, ...empty].filter(key => key !== this.sortKey)
      const valid = val => val !== null && val !== undefined

      return items.filter(item => {
        return props.some(key => valid(item[key]))
      })
    },
    removeRow(index) {
      if (!this.isEmpty) {
        const row = this.fieldsets[index]

        if (this.primaryKey && row[this.primaryKey]) {
          this.$set(row, '_destroy', true)
        } else {
          this.fieldsets.splice(index, 1)
        }

        this.updateOrder()
      }

      this.$emit('remove', index)
    },
    addRow(index) {
      if (!this.hasNewItem) {
        const empty = this.newItem(1)
        this.fieldsets.splice((index + 1), 0, empty)

        this.updateOrder()
      }

      this.$emit('add', index)
    },
    updateOrder() {
      if (this.sortable) {
        this.fieldsets.forEach((item, index) => {
          this.$set(item, this.sortKey, index)
        })
      }
    },
    inputTag({ $input }) {
      return $input ? `input-${$input}` : 'vs-input'
    },
    inputDisabled({ prop }) {
      return this.showTranslations
        ? !this.translates.includes(prop)
        : this.disabled
    }
  }
}
