
import Input from '@/mixins/input'

export default {
  name: 'RelationSelect',
  mixins: [Input],
  props: {
    value: {
      type: [Array, String, Number],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    path: {
      type: String,
      default: null
    },
    fragment: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Please select'
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    iconKey: {
      type: String,
      default: null
    },
    textKey: {
      type: String,
      default: null
    },
    variables: {
      type: Object,
      default: null
    },
    skip: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: null
    },
    clearable: {
      type: Boolean,
      default: true
    },
    filter: {
      type: Function,
      default: val => val
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.inputValue = newValue
      },
      immediate: true
    }
  },
  data() {
    return {
      inputValue: null,
      relation: null
    }
  },
  methods: {
    parser(data) {
      return {
        value: data[this.valueKey],
        label: data[this.labelKey],
        icon: data[this.iconKey],
        text: data[this.textKey]
      }
    }
  }
}
