
import Input from '@/mixins/input'
import Draggable from 'vuedraggable'
import { clone, isEqual } from 'vessel/utils'

export default {
  name: 'FileUploadInput',
  mixins: [Input],
  components: {
    Draggable
  },
  provide() {
    return {
      fileUpload: this
    }
  },
  props: {
    type: {
      type: String,
      default: 'file'
    },
    value: {
      type: [Array, Object],
      default: () => []
    },
    clientId: {
      type: String,
      default: 'default'
    },
    path: {
      type: String,
      default: 'files'
    },
    inputName: {
      type: String,
      default: 'file'
    },
    preview: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    drag: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Object,
      default: () => ({})
    }
  },
  vessel: {
    wrapper: {
      $isDisabled: {
        backgroundColor: val => val ? 'grey-lightest' : null
      }
    }
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          const value   = this.cloneValue(newValue)
          this.fileList = this.parseList(value)
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      fileList: [],
      error: null,
      isSorting: false,
      isDragging: false,
      singleValue: null,
      previewFile: null,
      previewModel: {},
      accept: {
        image: '.png,.jpg,.jpeg,.png,.gif,.svg,.webp',
        video: '.mp4,.m4v,.ogv,.webm',
        file: '.pdf'
      }
    }
  },
  computed: {
    isDisabled() {
      if (this.disabled) return true

      const translates = this.recordForm.translates || []
      return translates.includes(this.prop) && this.locale !== this.defaultLocale
    },
    isEditable() {
      return !this.disabled
    },
    reqHeaders() {
      return {
        Tenant: this.$tenant.active,
        ...this.headers
      }
    },
    client() {
      return this.$endpoints.get(this.clientId)
    },
    action() {
      return `${this.client}/${this.path}/`
    },
    totalItems() {
      return this.fileList.filter(({ _destroy }) => !_destroy).length
    },
    uploadClass() {
      return {
        'file-upload': true,
        'has-files': this.totalItems,
        'is-disabled': this.isDisabled,
        'is-editable': this.isEditable,
        'is-dragging': this.isDragging,
        'is-sorting': this.isSorting
      }
    },
    uploadAttrs() {
      return {
        ...this.$attrs,
        value: undefined,
        name: this.inputName
      }
    },
    autoFill() {
      switch (this.type) {
        case 'image': return 'xl'
        case 'video': return '3xl'
        default:      return '5xl'
      }
    },
    draggableAttrs() {
      return {
        attrs: {
          autoFill: this.autoFill,
          gap: 'xs',
          opacity: this.disabled && '25'
        }
      }
    },
    itemTag() {
      return `input-uploads-${this.type}-upload`
    },
    previewHandler() {
      return this.preview ? this.onPreview : null
    },
    colors() {
      if (this.disabled) {
        return {
          total: 'grey',
          icon: 'grey-light',
          text: 'grey',
          link: 'grey'
        }
      } else {
        return {
          total: 'primary',
          icon: 'grey',
          text: 'grey-darker',
          link: 'primary'
        }
      }
    },
    modalSchema() {
      const schema = this.type == 'file' ? 'File' : 'Media'
      const suffix = this.multiple ? '' : 'Single'

      return `${schema}${suffix}`
    }
  },
  methods: {
    onError(error, file, fileList) {
      this.fileList = this.parseList(fileList)
      this.updateValue()

      this.$emit('error', error, file)
    },
    onProgress(progress, file, fileList) {
      this.fileList = this.parseList(fileList)
      this.updateValue()

      this.$emit('progress', progress, file)
    },
    onSuccess(response, file, fileList) {
      this.fileList = this.parseList(fileList)

      this.addItem(file)
      this.updateValue()

      this.$emit('success', response, file)
    },
    onRemove(file) {
      this.removeItem(file)
      this.updateValue()

      this.$emit('remove', file)
    },
    onPreview(file) {
      this.previewFile = file
      this.onResetForm()

      this.$refs.dialog.open()
      this.$emit('preview', file)
    },
    addItem({ response, ...data }) {
      const index = this.fileList.findIndex(({ uid }) => uid == data.uid)
      const file  = this.fileList[index]

      if (response) {
        file.url  = response.url
        file.file = response.data
      }

      if (this.multiple) {
        this.$set(file, 'sortOrder', this.fileList.length)
      } else {
        this.fileList = [{ ...file, sortOrder: 0 }]
      }
    },
    removeItem(data) {
      const index = this.fileList.findIndex(({ uid }) => uid == data.uid)
      const file  = this.fileList[index]

      if (data.id) {
        this.$set(file, '_destroy', true)
      } else {
        this.fileList.splice(index, 1)
      }
    },
    parseItem(item) {
      const { response } = item

      if (response && response.data) {
        item.url  = response.url
        item.file = response.data

        delete item.response
        delete item.raw
        delete item.size
      }

      return item
    },
    parseList(newValue) {
      const last  = newValue.length - 1
      const value = this.multiple ? newValue : [newValue[last]]

      return value.filter(item => item)
    },
    parseValue(newValue) {
      const last = newValue.length - 1
      return this.multiple ? newValue : newValue[last]
    },
    cloneValue(newValue) {
      const value = clone(newValue)

      if (value) {
        const files = Array.isArray(value) ? value : [value]
        return this.parseList(files)
      } else {
        return []
      }
    },
    updateValue() {
      const files = this.fileList.map(this.parseItem)
      const value = this.parseValue(files)

      this.$emit('input', value)
    },
    onAddClick() {
      const input = this.$refs.upload.$refs['upload-inner']
      if (input) input.handleClick()
    },
    onClearClick() {
      this.fileList.forEach(file => {
        this.$nextTick(() => this.removeItem(file))
      })

      this.updateValue()
    },
    onSortEnd() {
      this.isSorting = false

      this.fileList.forEach((item, index) => {
        this.$set(item, 'sortOrder', index)
      })

      this.updateValue()
    },
    onResetForm() {
      this.previewModel = clone(this.previewFile)
    },
    onSubmitForm(data) {
      const file = this.fileList.find(({ uid }) => uid == this.previewFile.uid)

      if (data.featured) {
        this.fileList.forEach(file => file.featured = false)
      }

      Object.entries(data).forEach(([key, value]) => {
        this.$set(file, key, value)
      })

      this.$refs.dialog.close()
      this.updateValue()
    },
    onDragLeave({ relatedTarget, target }) {
      const isDragger = el => el && el.classList && el.classList.contains('el-upload-dragger')

      const noTargets = !relatedTarget || !target
      const noDragger = !isDragger(relatedTarget) && !isDragger(target)

      if (noTargets || noDragger) {
        this.isDragging = false
      }
    }
  }
}
