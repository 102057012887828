
import Column from '@/mixins/column'

export default {
  name: 'TitleColumn',
  mixins: [Column],
  props: {
    tagProp: {
      type: String,
      default: null
    },
    tagText: {
      type: String,
      default: null
    }
  }
}
