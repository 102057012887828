import 'cross-fetch/polyfill'

class RestClient {
  constructor(app, endpoint) {
    this.endpoint  = endpoint.endsWith('/') ? endpoint : `${endpoint}/`

    this.getLocale = () => app.i18n.locale
    this.getTenant = () => app.$tenant.active
  }

  request(method, path, { params, body, headers, ...options } = {}) {
    const opt = {
      method: method.toUpperCase(),
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      headers: {
        Tenant: this.getTenant(),
        Locale: this.getLocale(),
        Timezone: this.getTimezone(),
        ...headers
      },
      ...options
    }

    const url = new URL(path, this.endpoint)

    if (params) {
      const search = new URLSearchParams()
      Object.keys(params).forEach(key => search.set(key, params[key]))

      url.search = search.toString()
    }

    if (body && typeof body == 'object') {
      opt.headers['Content-Type'] = 'application/json'
      opt.body = JSON.stringify(body)
    }

    return fetch(url.toString(), opt)
  }

  getTimezone() {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (e) {
      return 'UTC'
    }
  }
}

export default ({ app }, inject) => {
  const restClient = endpoint => new RestClient(app, endpoint)

  const clients = {
    default: restClient(process.env.DATA_ENDPOINT),
    main: restClient(process.env.MAIN_ENDPOINT),
    data: restClient(process.env.DATA_ENDPOINT)
  }

  const makeRequest = (method, path, { client, clientId, ...options }) => {
    const name = clientId || client
    const klass = clients[name] || clients.default

    return klass.request(method, path, options)
  }

  inject('rest', {
    request(path, options = {}) {
      const method = options.method || 'GET'
      return makeRequest(method, path, options)
    },
    get(path, options = {}) {
      return makeRequest('GET', path, options)
    },
    post(path, options = {}) {
      return makeRequest('POST', path, options)
    }
  })
}
