import Middleware from './middleware'

const authDisabled = route => route.matched.some(match => {
  const components = Object.values(match.components)
  const isDisabled = comp => comp.options && comp.options['auth'] === false

  if (process.client) {
    return components.some(isDisabled)
  } else {
    return components.some(comp => Object.values(comp._Ctor).some(isDisabled))
  }
})

Middleware.auth = async function ({ app, route }) {
  const handler = async () => {
    app.$auth.setLastPath(route.path)

    await app.$auth.autoLogin()

    if (!app.$auth.loggedIn) {
      app.$auth.setRedirect(route.fullPath)
      app.$auth.redirect('login')
    }
  }

  if (!route.path.match('/auth/') && !authDisabled(route)) {
    return handler()
  }
}
