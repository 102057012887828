
import Input from '@/mixins/input'

export default {
  name: 'SwitchInput',
  mixins: [Input],
  props: {
    value: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icons: {
      type: Boolean,
      default: false
    },
    onText: {
      type: String,
      default: 'Enabled'
    },
    offText: {
      type: String,
      default: 'Disabled'
    },
    onLabel: {
      type: String,
      default: 'On'
    },
    offLabel: {
      type: String,
      default: 'Off'
    },
    onIcon: {
      type: String,
      default: 'check'
    },
    offIcon: {
      type: String,
      default: 'times'
    }
  },
  vessel: {
    wrapper: {
      $disabled: {
        backgroundColor: val => val ? 'grey-lightest' : null,
        textColor: val => val ? 'grey' : null,
        cursor: val => val ? 'not-allowed' : 'pointer'
      }
    },
    button: {
      paddingX: 'xs',
      textTransform: 'uppercase',
      $icons: {
        fontSize: val => val ? 'lg' : 'xs'
      },
      $active: {
        textColor(val) {
          return this.disabled ? 'grey' : (val ? 'success' : 'danger')
        }
      }
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.active = newValue
      }
    },
    active(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit('input', newValue)
      }
    }
  },
  data() {
    return {
      active: this.value
    }
  },
  computed: {
    stateLabel() {
      return this.active ? this.onText : this.offText
    },
    switchLabel() {
      return this.active ? this.onLabel : this.offLabel
    },
    switchIcon() {
      return this.active ? this.onIcon : this.offIcon
    }
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.active = !this.active
      }
    }
  }
}
