
import Input from '@/mixins/input'
import { clone } from 'vessel/utils'

export default {
  name: 'OrderItemsInput',
  formItem: false,
  mixins: [Input],
  props: {
    formColumnProps: {
      type: Object,
      default: null
    },
    formItemProps: {
      type: Object,
      default: null
    },
    dataKey: {
      type: String,
      default: 'orderItems'
    },
    value: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    input: {
      overflow: 'hidden',
      $disabled: {
        backgroundColor: val => val && 'grey-lightest',
        color: val => val && 'grey'
      }
    },
    counter: {
      marginX: '2xs',
      fontWeight: 'bold',
      $disabled: {
        color(value) {
          return value ? 'grey' : 'primary'
        }
      }
    },
    empty: {
      $extend: 'text',
      paddingY: 'xs',
      paddingX: 'sm',
      borderBottomWidth: 1,
      borderColor: 'transparent',
      fontSize: 'sm',
      lineHeight: 'xl'
    }
  },
  watch: {
    value(value) {
      this.items = clone(value || [])
    }
  },
  data() {
    return {
      nestedFormItem: {},
      items: [],
      newItems: []
    }
  },
  computed: {
    visibleItems() {
      return this.items.filter(this.showItem)
    },
    showItems() {
      return this.items.some(this.showItem)
    },
    totalItems() {
      return this.visibleItems.length
    },
    itemsData() {
      const existing = this.record[this.dataKey] || []
      return [...existing, ...this.newItems]
    },
    error() {
      return this.nestedFormItem.validateMessage
    }
  },
  methods: {
    getItemData({ variantId }) {
      return this.itemsData.find(item => item.variantId == variantId)
    },
    showItem(item) {
      return item && !item._destroy
    },
    onUpdate() {
      const value = this.items.filter(item => !!item)

      value.forEach(item => {
        const data = this.getItemData(item)

        this.$set(data, 'quantity', item.quantity)
        this.$set(data, '_destroy', item._destroy)
      })

      this.$emit('input', value)
    },
    onAddItem(data, orderItem) {
      this.nestedFormItem.clearValidate()

      const itemData = this.getItemData(data)

      if (itemData) {
        this.$set(itemData, 'quantity', itemData.quantity + data.quantity)
      } else {
        this.newItems.push(orderItem)
      }

      const value = [...this.items]
      const item  = value.find(item => item.variantId == data.variantId)

      if (item) {
        this.$set(item, 'quantity', item.quantity + data.quantity)
      } else {
        value.push(data)
      }

      this.$emit('input', value)
    },
    onClearClick() {
      this.$emit('input', [])
    }
  },
  mounted() {
    this.nestedFormItem = this.$refs.formItem
  }
}
