import { render, staticRenderFns } from "./UserMenu.vue?vue&type=template&id=75e228e8&"
import script from "./UserMenu.vue?vue&type=script&lang=js&"
export * from "./UserMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthSession: require('/opt/build/repo/node_modules/@evince/auth/lib/packages/AuthSession.vue').default})
