import { render, staticRenderFns } from "./CountryValue.vue?vue&type=template&id=6b6bf924&"
import script from "./CountryValue.vue?vue&type=script&lang=js&"
export * from "./CountryValue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseInput: require('/opt/build/repo/components/BaseInput.vue').default,InputCountryList: require('/opt/build/repo/inputs/CountryList.vue').default})
