export default {
  options: {
    columns: 4
  },
  translates: [
    'value'
  ],
  inputs: {
    value: {
      label: 'label',
      span: 4,
      required: true
    },
    internalValue: {
      label: 'value',
      span: 4,
      required: true
    }
  }
}
