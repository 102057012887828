export const state = () => ({
  previous: null,
  active: null
})

export const mutations = {
  SET_PREVIOUS (state, data) {
    state.previous = data
  },
  SET_ACTIVE (state, data) {
    state.active = data
  }
}

export const getters = {
  previous(state) {
    return state.previous
  },
  active(state) {
    return state.active
  }
}

export const actions = {
  async setTenant({ commit, state }, data) {
    commit('SET_PREVIOUS', state.active)
    commit('SET_ACTIVE', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
