
import Input from '@/mixins/input'

export default {
  name: 'Enum',
  mixins: [Input],
  props: {
    value: {
      type: String,
      default: null
    },
    enumeration: {
      type: Array,
      default: null
    },
    enumKey: {
      type: String,
      default: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.selected = newValue
      }
    }
  },
  data() {
    return {
      selected: null
    }
  },
  computed: {
    options() {
      if (this.enumeration) {
        return this.enumeration
      } else if (this.enumKey) {
        const data = this.record[this.enumKey]
        return Object(data).enumeration
      } else {
        return []
      }
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    }
  }
}
