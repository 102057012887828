
export default {
  name: 'AddOrderItem',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    text: {
      $disabled: {
        color(val) {
          return val ? 'grey' : null
        }
      }
    },
    label: {
      $extend: 'text',
      marginBottom: 'xs'
    },
    value: {
      lineHeight: 'xl'
    }
  },
  data() {
    return {
      loading: false,
      products: [],
      productId: null,
      variantId: null,
      quantity: 1
    }
  },
  computed: {
    locked() {
      return this.disabled || this.loading || !this.variantId
    },
    product() {
      return this.products.find(({ id }) => id == this.productId) || {}
    },
    master() {
      return this.product.master || {}
    },
    variants() {
      return this.product.variants || []
    },
    variant() {
      return this.variants.find(({ id }) => id == this.variantId) || this.master
    },
    image() {
      return this.variant.image || this.master.image
    },
    priceMoney() {
      return this.variant.price || {}
    },
    taxMoney() {
      return this.variant.tax || {}
    },
    price() {
      return this.priceMoney.value || 0
    },
    total() {
      return this.quantity * this.price
    },
    currency() {
      return this.priceMoney.currency || 'EUR'
    },
    priceText() {
      return this.priceMoney.formatted || this.formatMoney(0)
    },
    totalText() {
      return this.formatMoney(this.total)
    }
  },
  methods: {
    async fetchProducts(search) {
      this.loading = true

      const result = await this.$graphql.query({
        path: 'eshop/product',
        query: 'OrderProducts',
        prefetch: false,
        collection: true,
        variables: {
          first: 5,
          where: { name_contains: search }
        },
        locale: this.locale
      })

      this.products = result && result.data || []
      this.loading  = false
    },
    formatMoney(amount) {
      const locale   = this.defaultLocale
      const currency = this.currency

      return amount.toLocaleString(locale, { style: 'currency', currency })
    },
    resetForm() {
      this.products  = []
      this.productId = null
      this.variantId = null
      this.quantity  = 1
    },
    onSelectProduct(id) {
      if (!id) {
        this.variantId = null
      } else if (this.variants.length) {
        this.variantId = this.variants[0].id
      } else {
        this.variantId = this.master.id
      }
    },
    onAdd() {
      const { variantId, quantity, variant, product, priceMoney, taxMoney } = this

      const data = { id: null, variantId, quantity }
      const item = { ...data, tax: taxMoney, price: priceMoney, variant: { ...variant, product } }

      this.$emit('add', data, item)
      this.resetForm()
    }
  }
}
