export default {
  options: {
    columns: 4
  },
  inputs: {
    sku: {
      required: true,
      span: 4
    },
    price: {
      $input: 'number',
      required: true,
      span: 4,
      $el: {
        label: 'EUR'
      }
    },
    optionAssignmentsAttributes: {
      $input: 'attribute-assignments',
      label: false,
      span: 4,
      $el: {
        path: 'eshop/product',
        query: 'ProductOptions',
        variables() {
          return { where: { id: this.$route.params.id } }
        },
        dataKey: 'options',
        valuesKey: 'values'
      }
    },
    length: {
      $input: 'number',
      span: 2,
      $el: {
        label: 'm',
        precision: 3
      }
    },
    width: {
      $input: 'number',
      span: 2,
      $el: {
        label: 'm',
        precision: 3
      }
    },
    height: {
      $input: 'number',
      span: 2,
      $el: {
        label: 'm',
        precision: 3
      }
    },
    weight: {
      $input: 'number',
      span: 2,
      $el: {
        label: 'kg',
        precision: 3
      }
    }
  }
}
