
import { get } from 'vuex-pathify'

export default {
  name: 'MainSidebar',
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    wrapper: {
      width: 'full',
      $darkTheme: {
        backgroundColor(value) {
          return value ? 'black' : 'white'
        }
      }
    },
    menu: {
      paddingY: 'xs',
      borderTopWidth: 1,
      overflowY: 'auto',
      $darkTheme: {
        borderColor(value) {
          return value ? 'black' : 'default'
        }
      }
    }
  },
  data() {
    return {
      colors: {
        light: {
          borderColor: 'grey-light',
          textColor: 'grey-darkest',
          activeTextColor: 'primary'
        },
        dark: {
          backgroundColor: 'grey-darkest',
          borderColor: 'black',
          textColor: 'grey-lighter',
          activeTextColor: 'primary'
        }
      }
    }
  },
  computed: {
    activeTheme: get('preferences/activeTheme'),
    activePath() {
      return this.$route.path.replace(/\/edit\/\d+\/?.*$/, '').replace(/\/new\/?$/, '')
    },
    darkTheme() {
      return this.activeTheme == 'dark'
    },
    themeColors() {
      return this.colors[this.activeTheme]
    },
    menuClass() {
      return {
        'main-menu': true,
        'dark-menu': this.darkTheme
      }
    },
    menuColors() {
      return {
        color: this.darkTheme ? 'grey' : 'grey-darker',
        hoverColor: this.darkTheme ? 'white' : 'grey-darkest'
      }
    }
  }
}
