export default ({ app }, inject) => {
  const pagePath = (path, locale) => {
    const local   = app.localePath(path, locale || app.i18n.locale)
    const isLocal = local !== '/'

    let route = isLocal ? local : path
    route = route.replace('index', '/').trim()

    if (route.startsWith('//'))
      route = route.replace('//', '/')

    if (!route.startsWith('/'))
      route = `/${route}`

    if (route.endsWith('/') && route != '/')
      route = route.replace(/\/+$/g, '')

    return route
  }

  const navigateTo = (path, locale, callback) => {
    const route = pagePath(path, locale)
    app.router.push({ path: route }, callback)
  }

  const assetUrl = (path) => {
    if (path) {
      const fragment = path.startsWith('/') ? path : `/${path}`
      return `https://cdn.statica.eu/assets/${app.$tenant.active}${fragment}`
    }
  }

  inject('pagePath', pagePath)
  inject('navigateTo', navigateTo)
  inject('assetUrl', assetUrl)
}
