
import Input from '@/mixins/input'
import { toArray, clone } from 'vessel/utils'

export default {
  name: 'FormBuilder',
  mixins: [Input],
  props: {
    value: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    input: {
      $disabled: {
        pointerEvents(value) {
          return value ? 'none' : null
        }
      }
    }
  },
  watch: {
    value(value) {
      const findActive = (data, active) => {
        const length = data.length
        const endIdx = length - 1

        if (length == 0 || active == null) {
          return null
        }

        let newActive = active > endIdx ? endIdx : active

        if (data[newActive]._destroy) {
          const list = data
            .map((item, idx) => item._destroy ? null : idx)
            .filter(item => item != null)

          const prev = list.filter(item => item < active)
          const next = list.filter(item => item > active)

          if (next.length) {
            newActive = next[0]
          } else {
            newActive = prev[prev.length - 1]
          }
        }

        return newActive
      }

      const items = toArray(value)
      const child = toArray(Object(items[this.active]).fields)

      this.active = findActive(items, this.active)
      this.activeChild = findActive(child, this.activeChild)
    }
  },
  data() {
    return {
      active: null,
      activeChild: null,
      breakpoint: 'desktop'
    }
  },
  computed: {
    activeItem() {
      if (this.activeChild == null) {
        return this.value[this.active]
      } else {
        return this.value[this.active].fields[this.activeChild]
      }
    }
  },
  methods: {
    onActivate(index) {
      this.active = index
      this.activeChild = null
    },
    onActivateChild(parent, index) {
      this.active = parent
      this.activeChild = index
    },
    onSetScreen(bp) {
      this.breakpoint = bp
    },
    onSortChange({ newIndex, oldIndex }, items) {
      const value = items.map((item, index) => ({ ...item, sortOrder: index }))

      if (this.active != null && this.active == oldIndex) {
        this.active = newIndex
      }

      this.$emit('input', value)
    },
    onSortGroup(parent, { newIndex, oldIndex }, items) {
      const value = clone(this.value)
      const field = value[parent]

      field.fields = items.map(
        (item, index) => ({ ...item, sortOrder: index })
      )

      if (this.activeChild != null && this.activeChild == oldIndex) {
        this.activeChild = newIndex
      }

      this.$emit('input', value)
    },
    onUpdateField(prop, data) {
      const value = clone(this.value)
      const field = value[this.active]

      if (this.activeChild == null) {
        field[prop] = data
      } else {
        const child = field.fields[this.activeChild]
        child[prop] = data
      }

      this.$emit('input', value)
    },
    onRemoveField() {
      const value = clone(this.value)
      const field = value[this.active]

      if (this.activeChild == null) {
        if (field.id == null) {
          value.splice(this.active, 1)
        } else {
          field._destroy = true
        }
      } else {
        const items = field.fields
        const child = items[this.activeChild]

        if (child.id == null) {
          items.splice(this.activeChild, 1)
        } else {
          child._destroy = true
        }
      }

      this.$emit('input', value)
    },
    onAddGroup() {
      const value = [...this.value]
      const index = value.length

      value.push({
        name: null,
        label: null,
        options: {},
        fields: [],
        sortOrder: index,
        type: 'group'
      })

      this.active = index
      this.$emit('input', value)
    },
    onAddGroupField(parent) {
      const value = clone(this.value)
      const field = value[parent]
      const index = field.fields.length

      field.fields.push({
        name: null,
        label: null,
        placeholder: null,
        required: false,
        options: {},
        sortOrder: index,
        type: 'text'
      })

      this.active = parent
      this.activeChild = index

      this.$emit('input', value)
    },
    onAddField() {
      const value = [...this.value]
      const index = value.length

      value.push({
        name: null,
        label: null,
        placeholder: null,
        required: false,
        options: {},
        sortOrder: index,
        type: 'text'
      })

      this.active = index
      this.$emit('input', value)
    }
  }
}
