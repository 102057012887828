import { render, staticRenderFns } from "./OrderSummary.vue?vue&type=template&id=761deda6&"
import script from "./OrderSummary.vue?vue&type=script&lang=js&"
export * from "./OrderSummary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StateLabel: require('/opt/build/repo/node_modules/@evince/core/lib/packages/StateLabel.vue').default,OrderSection: require('/opt/build/repo/components/OrderSection.vue').default})
