
import Column from '@/mixins/column'

export default {
  name: 'ActiveColumn',
  mixins: [Column],
  data() {
    return {
      loading: false
    }
  },
  methods: {
    onChange(value) {
      const { $refs: { query } } = this.indexPage
      const { gid } = this.row

      if (gid && query) {
        this.loading = true

        const options = {
          path: 'mutations',
          mutation: 'UpdateActive',
          clientId: query.client,
          variables: {
            input: { data: { globalId: gid, active: value } }
          }
        }

        this.$graphql.mutate(options).then(async () => {
          await query.refetchQueries()
          this.loading = false
        })
      }
    }
  }
}
