export const AuthSession = () => import('../../node_modules/@evince/auth/lib/packages/AuthSession.vue' /* webpackChunkName: "components/auth-session" */).then(c => wrapFunctional(c.default || c))
export const AuthSessionForm = () => import('../../node_modules/@evince/auth/lib/packages/AuthSessionForm.vue' /* webpackChunkName: "components/auth-session-form" */).then(c => wrapFunctional(c.default || c))
export const ScMutationForm = () => import('../../node_modules/@evince/core/lib/components/MutationForm.vue' /* webpackChunkName: "components/sc-mutation-form" */).then(c => wrapFunctional(c.default || c))
export const ScCountrySelect = () => import('../../node_modules/@evince/core/lib/components/country/Select.vue' /* webpackChunkName: "components/sc-country-select" */).then(c => wrapFunctional(c.default || c))
export const ScLanguageSelect = () => import('../../node_modules/@evince/core/lib/components/language/Select.vue' /* webpackChunkName: "components/sc-language-select" */).then(c => wrapFunctional(c.default || c))
export const ErrorDetails = () => import('../../node_modules/@evince/core/lib/packages/ErrorDetails.vue' /* webpackChunkName: "components/error-details" */).then(c => wrapFunctional(c.default || c))
export const PwaInstall = () => import('../../node_modules/@evince/core/lib/packages/PwaInstall.vue' /* webpackChunkName: "components/pwa-install" */).then(c => wrapFunctional(c.default || c))
export const PwaPrompt = () => import('../../node_modules/@evince/core/lib/packages/PwaPrompt.vue' /* webpackChunkName: "components/pwa-prompt" */).then(c => wrapFunctional(c.default || c))
export const StateLabel = () => import('../../node_modules/@evince/core/lib/packages/StateLabel.vue' /* webpackChunkName: "components/state-label" */).then(c => wrapFunctional(c.default || c))
export const GraphqlMutation = () => import('../../node_modules/@evince/core/lib/packages/graphql/Mutation.vue' /* webpackChunkName: "components/graphql-mutation" */).then(c => wrapFunctional(c.default || c))
export const GraphqlQuery = () => import('../../node_modules/@evince/core/lib/packages/graphql/Query.vue' /* webpackChunkName: "components/graphql-query" */).then(c => wrapFunctional(c.default || c))
export const ActionBar = () => import('../../components/ActionBar.vue' /* webpackChunkName: "components/action-bar" */).then(c => wrapFunctional(c.default || c))
export const ActionSection = () => import('../../components/ActionSection.vue' /* webpackChunkName: "components/action-section" */).then(c => wrapFunctional(c.default || c))
export const BaseFormItem = () => import('../../components/BaseFormItem.vue' /* webpackChunkName: "components/base-form-item" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/BaseInput.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BottomBar = () => import('../../components/BottomBar.vue' /* webpackChunkName: "components/bottom-bar" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const BulkActions = () => import('../../components/BulkActions.vue' /* webpackChunkName: "components/bulk-actions" */).then(c => wrapFunctional(c.default || c))
export const Chart = () => import('../../components/Chart.vue' /* webpackChunkName: "components/chart" */).then(c => wrapFunctional(c.default || c))
export const CreatePage = () => import('../../components/CreatePage.vue' /* webpackChunkName: "components/create-page" */).then(c => wrapFunctional(c.default || c))
export const DataTable = () => import('../../components/DataTable.vue' /* webpackChunkName: "components/data-table" */).then(c => wrapFunctional(c.default || c))
export const DeleteButton = () => import('../../components/DeleteButton.vue' /* webpackChunkName: "components/delete-button" */).then(c => wrapFunctional(c.default || c))
export const EditButton = () => import('../../components/EditButton.vue' /* webpackChunkName: "components/edit-button" */).then(c => wrapFunctional(c.default || c))
export const EditLink = () => import('../../components/EditLink.vue' /* webpackChunkName: "components/edit-link" */).then(c => wrapFunctional(c.default || c))
export const EditPage = () => import('../../components/EditPage.vue' /* webpackChunkName: "components/edit-page" */).then(c => wrapFunctional(c.default || c))
export const EmptySection = () => import('../../components/EmptySection.vue' /* webpackChunkName: "components/empty-section" */).then(c => wrapFunctional(c.default || c))
export const FormActions = () => import('../../components/FormActions.vue' /* webpackChunkName: "components/form-actions" */).then(c => wrapFunctional(c.default || c))
export const FormDialog = () => import('../../components/FormDialog.vue' /* webpackChunkName: "components/form-dialog" */).then(c => wrapFunctional(c.default || c))
export const FormGroup = () => import('../../components/FormGroup.vue' /* webpackChunkName: "components/form-group" */).then(c => wrapFunctional(c.default || c))
export const FormItems = () => import('../../components/FormItems.vue' /* webpackChunkName: "components/form-items" */).then(c => wrapFunctional(c.default || c))
export const FormLangSwitcher = () => import('../../components/FormLangSwitcher.vue' /* webpackChunkName: "components/form-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const FormMessages = () => import('../../components/FormMessages.vue' /* webpackChunkName: "components/form-messages" */).then(c => wrapFunctional(c.default || c))
export const FormReply = () => import('../../components/FormReply.vue' /* webpackChunkName: "components/form-reply" */).then(c => wrapFunctional(c.default || c))
export const FormSection = () => import('../../components/FormSection.vue' /* webpackChunkName: "components/form-section" */).then(c => wrapFunctional(c.default || c))
export const FormSectionAttributes = () => import('../../components/FormSectionAttributes.vue' /* webpackChunkName: "components/form-section-attributes" */).then(c => wrapFunctional(c.default || c))
export const FormSectionList = () => import('../../components/FormSectionList.vue' /* webpackChunkName: "components/form-section-list" */).then(c => wrapFunctional(c.default || c))
export const FormSectionObject = () => import('../../components/FormSectionObject.vue' /* webpackChunkName: "components/form-section-object" */).then(c => wrapFunctional(c.default || c))
export const FormSectionProduct = () => import('../../components/FormSectionProduct.vue' /* webpackChunkName: "components/form-section-product" */).then(c => wrapFunctional(c.default || c))
export const FormSummary = () => import('../../components/FormSummary.vue' /* webpackChunkName: "components/form-summary" */).then(c => wrapFunctional(c.default || c))
export const GenerateVariants = () => import('../../components/GenerateVariants.vue' /* webpackChunkName: "components/generate-variants" */).then(c => wrapFunctional(c.default || c))
export const HeaderBar = () => import('../../components/HeaderBar.vue' /* webpackChunkName: "components/header-bar" */).then(c => wrapFunctional(c.default || c))
export const IndexFilters = () => import('../../components/IndexFilters.vue' /* webpackChunkName: "components/index-filters" */).then(c => wrapFunctional(c.default || c))
export const IndexPage = () => import('../../components/IndexPage.vue' /* webpackChunkName: "components/index-page" */).then(c => wrapFunctional(c.default || c))
export const IndexTable = () => import('../../components/IndexTable.vue' /* webpackChunkName: "components/index-table" */).then(c => wrapFunctional(c.default || c))
export const InputActions = () => import('../../components/InputActions.vue' /* webpackChunkName: "components/input-actions" */).then(c => wrapFunctional(c.default || c))
export const InviteUser = () => import('../../components/InviteUser.vue' /* webpackChunkName: "components/invite-user" */).then(c => wrapFunctional(c.default || c))
export const LangSwitcher = () => import('../../components/LangSwitcher.vue' /* webpackChunkName: "components/lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const Layout = () => import('../../components/Layout.vue' /* webpackChunkName: "components/layout" */).then(c => wrapFunctional(c.default || c))
export const LoadPrototype = () => import('../../components/LoadPrototype.vue' /* webpackChunkName: "components/load-prototype" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const LogoBar = () => import('../../components/LogoBar.vue' /* webpackChunkName: "components/logo-bar" */).then(c => wrapFunctional(c.default || c))
export const MainSidebar = () => import('../../components/MainSidebar.vue' /* webpackChunkName: "components/main-sidebar" */).then(c => wrapFunctional(c.default || c))
export const MenuItems = () => import('../../components/MenuItems.vue' /* webpackChunkName: "components/menu-items" */).then(c => wrapFunctional(c.default || c))
export const MenuList = () => import('../../components/MenuList.vue' /* webpackChunkName: "components/menu-list" */).then(c => wrapFunctional(c.default || c))
export const ModelDialog = () => import('../../components/ModelDialog.vue' /* webpackChunkName: "components/model-dialog" */).then(c => wrapFunctional(c.default || c))
export const ModelForm = () => import('../../components/ModelForm.vue' /* webpackChunkName: "components/model-form" */).then(c => wrapFunctional(c.default || c))
export const NestedDraggable = () => import('../../components/NestedDraggable.vue' /* webpackChunkName: "components/nested-draggable" */).then(c => wrapFunctional(c.default || c))
export const NotesForm = () => import('../../components/NotesForm.vue' /* webpackChunkName: "components/notes-form" */).then(c => wrapFunctional(c.default || c))
export const NotesItem = () => import('../../components/NotesItem.vue' /* webpackChunkName: "components/notes-item" */).then(c => wrapFunctional(c.default || c))
export const NotesList = () => import('../../components/NotesList.vue' /* webpackChunkName: "components/notes-list" */).then(c => wrapFunctional(c.default || c))
export const NotesPage = () => import('../../components/NotesPage.vue' /* webpackChunkName: "components/notes-page" */).then(c => wrapFunctional(c.default || c))
export const OrderCancel = () => import('../../components/OrderCancel.vue' /* webpackChunkName: "components/order-cancel" */).then(c => wrapFunctional(c.default || c))
export const OrderCustomer = () => import('../../components/OrderCustomer.vue' /* webpackChunkName: "components/order-customer" */).then(c => wrapFunctional(c.default || c))
export const OrderItem = () => import('../../components/OrderItem.vue' /* webpackChunkName: "components/order-item" */).then(c => wrapFunctional(c.default || c))
export const OrderItems = () => import('../../components/OrderItems.vue' /* webpackChunkName: "components/order-items" */).then(c => wrapFunctional(c.default || c))
export const OrderNotifier = () => import('../../components/OrderNotifier.vue' /* webpackChunkName: "components/order-notifier" */).then(c => wrapFunctional(c.default || c))
export const OrderPayment = () => import('../../components/OrderPayment.vue' /* webpackChunkName: "components/order-payment" */).then(c => wrapFunctional(c.default || c))
export const OrderPayments = () => import('../../components/OrderPayments.vue' /* webpackChunkName: "components/order-payments" */).then(c => wrapFunctional(c.default || c))
export const OrderSection = () => import('../../components/OrderSection.vue' /* webpackChunkName: "components/order-section" */).then(c => wrapFunctional(c.default || c))
export const OrderShipment = () => import('../../components/OrderShipment.vue' /* webpackChunkName: "components/order-shipment" */).then(c => wrapFunctional(c.default || c))
export const OrderShipments = () => import('../../components/OrderShipments.vue' /* webpackChunkName: "components/order-shipments" */).then(c => wrapFunctional(c.default || c))
export const OrderSummary = () => import('../../components/OrderSummary.vue' /* webpackChunkName: "components/order-summary" */).then(c => wrapFunctional(c.default || c))
export const PageBar = () => import('../../components/PageBar.vue' /* webpackChunkName: "components/page-bar" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageLayout = () => import('../../components/PageLayout.vue' /* webpackChunkName: "components/page-layout" */).then(c => wrapFunctional(c.default || c))
export const PageMessage = () => import('../../components/PageMessage.vue' /* webpackChunkName: "components/page-message" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const Paperclip = () => import('../../components/Paperclip.vue' /* webpackChunkName: "components/paperclip" */).then(c => wrapFunctional(c.default || c))
export const ParentLayout = () => import('../../components/ParentLayout.vue' /* webpackChunkName: "components/parent-layout" */).then(c => wrapFunctional(c.default || c))
export const PaymentCreate = () => import('../../components/PaymentCreate.vue' /* webpackChunkName: "components/payment-create" */).then(c => wrapFunctional(c.default || c))
export const PaymentDetails = () => import('../../components/PaymentDetails.vue' /* webpackChunkName: "components/payment-details" */).then(c => wrapFunctional(c.default || c))
export const PrototypesList = () => import('../../components/PrototypesList.vue' /* webpackChunkName: "components/prototypes-list" */).then(c => wrapFunctional(c.default || c))
export const QueryPage = () => import('../../components/QueryPage.vue' /* webpackChunkName: "components/query-page" */).then(c => wrapFunctional(c.default || c))
export const RecordDialog = () => import('../../components/RecordDialog.vue' /* webpackChunkName: "components/record-dialog" */).then(c => wrapFunctional(c.default || c))
export const RecordForm = () => import('../../components/RecordForm.vue' /* webpackChunkName: "components/record-form" */).then(c => wrapFunctional(c.default || c))
export const RecordPage = () => import('../../components/RecordPage.vue' /* webpackChunkName: "components/record-page" */).then(c => wrapFunctional(c.default || c))
export const RelationInput = () => import('../../components/RelationInput.vue' /* webpackChunkName: "components/relation-input" */).then(c => wrapFunctional(c.default || c))
export const SelectActionBar = () => import('../../components/SelectActionBar.vue' /* webpackChunkName: "components/select-action-bar" */).then(c => wrapFunctional(c.default || c))
export const SelectDropdown = () => import('../../components/SelectDropdown.vue' /* webpackChunkName: "components/select-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SeoPage = () => import('../../components/SeoPage.vue' /* webpackChunkName: "components/seo-page" */).then(c => wrapFunctional(c.default || c))
export const ShipmentCreate = () => import('../../components/ShipmentCreate.vue' /* webpackChunkName: "components/shipment-create" */).then(c => wrapFunctional(c.default || c))
export const ShipmentDetails = () => import('../../components/ShipmentDetails.vue' /* webpackChunkName: "components/shipment-details" */).then(c => wrapFunctional(c.default || c))
export const SortableList = () => import('../../components/SortableList.vue' /* webpackChunkName: "components/sortable-list" */).then(c => wrapFunctional(c.default || c))
export const TableActions = () => import('../../components/TableActions.vue' /* webpackChunkName: "components/table-actions" */).then(c => wrapFunctional(c.default || c))
export const TableColumn = () => import('../../components/TableColumn.vue' /* webpackChunkName: "components/table-column" */).then(c => wrapFunctional(c.default || c))
export const TableTag = () => import('../../components/TableTag.vue' /* webpackChunkName: "components/table-tag" */).then(c => wrapFunctional(c.default || c))
export const TenantMenu = () => import('../../components/TenantMenu.vue' /* webpackChunkName: "components/tenant-menu" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/TopBar.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const UserHeader = () => import('../../components/UserHeader.vue' /* webpackChunkName: "components/user-header" */).then(c => wrapFunctional(c.default || c))
export const UserMenu = () => import('../../components/UserMenu.vue' /* webpackChunkName: "components/user-menu" */).then(c => wrapFunctional(c.default || c))
export const Widget = () => import('../../components/Widget.vue' /* webpackChunkName: "components/widget" */).then(c => wrapFunctional(c.default || c))
export const InputAddMore = () => import('../../inputs/AddMore.vue' /* webpackChunkName: "components/input-add-more" */).then(c => wrapFunctional(c.default || c))
export const InputAttributeAssignments = () => import('../../inputs/AttributeAssignments.vue' /* webpackChunkName: "components/input-attribute-assignments" */).then(c => wrapFunctional(c.default || c))
export const InputAttributeOptions = () => import('../../inputs/AttributeOptions.vue' /* webpackChunkName: "components/input-attribute-options" */).then(c => wrapFunctional(c.default || c))
export const InputAttributeValues = () => import('../../inputs/AttributeValues.vue' /* webpackChunkName: "components/input-attribute-values" */).then(c => wrapFunctional(c.default || c))
export const InputAttributes = () => import('../../inputs/Attributes.vue' /* webpackChunkName: "components/input-attributes" */).then(c => wrapFunctional(c.default || c))
export const InputCalculator = () => import('../../inputs/Calculator.vue' /* webpackChunkName: "components/input-calculator" */).then(c => wrapFunctional(c.default || c))
export const InputCode = () => import('../../inputs/Code.vue' /* webpackChunkName: "components/input-code" */).then(c => wrapFunctional(c.default || c))
export const InputCodeEditor = () => import('../../inputs/CodeEditor.vue' /* webpackChunkName: "components/input-code-editor" */).then(c => wrapFunctional(c.default || c))
export const InputCountryList = () => import('../../inputs/CountryList.vue' /* webpackChunkName: "components/input-country-list" */).then(c => wrapFunctional(c.default || c))
export const InputCountrySelect = () => import('../../inputs/CountrySelect.vue' /* webpackChunkName: "components/input-country-select" */).then(c => wrapFunctional(c.default || c))
export const InputDataSelect = () => import('../../inputs/DataSelect.vue' /* webpackChunkName: "components/input-data-select" */).then(c => wrapFunctional(c.default || c))
export const InputEnum = () => import('../../inputs/Enum.vue' /* webpackChunkName: "components/input-enum" */).then(c => wrapFunctional(c.default || c))
export const InputFileUpload = () => import('../../inputs/FileUpload.vue' /* webpackChunkName: "components/input-file-upload" */).then(c => wrapFunctional(c.default || c))
export const InputFilterAttribute = () => import('../../inputs/FilterAttribute.vue' /* webpackChunkName: "components/input-filter-attribute" */).then(c => wrapFunctional(c.default || c))
export const InputFilterDisplay = () => import('../../inputs/FilterDisplay.vue' /* webpackChunkName: "components/input-filter-display" */).then(c => wrapFunctional(c.default || c))
export const InputFormBuilder = () => import('../../inputs/FormBuilder.vue' /* webpackChunkName: "components/input-form-builder" */).then(c => wrapFunctional(c.default || c))
export const InputGeoLocation = () => import('../../inputs/GeoLocation.vue' /* webpackChunkName: "components/input-geo-location" */).then(c => wrapFunctional(c.default || c))
export const InputLinkedSelect = () => import('../../inputs/LinkedSelect.vue' /* webpackChunkName: "components/input-linked-select" */).then(c => wrapFunctional(c.default || c))
export const InputMoney = () => import('../../inputs/Money.vue' /* webpackChunkName: "components/input-money" */).then(c => wrapFunctional(c.default || c))
export const InputNestable = () => import('../../inputs/Nestable.vue' /* webpackChunkName: "components/input-nestable" */).then(c => wrapFunctional(c.default || c))
export const InputNumber = () => import('../../inputs/Number.vue' /* webpackChunkName: "components/input-number" */).then(c => wrapFunctional(c.default || c))
export const InputOrderItems = () => import('../../inputs/OrderItems.vue' /* webpackChunkName: "components/input-order-items" */).then(c => wrapFunctional(c.default || c))
export const InputPaymentCalculator = () => import('../../inputs/PaymentCalculator.vue' /* webpackChunkName: "components/input-payment-calculator" */).then(c => wrapFunctional(c.default || c))
export const InputPercentage = () => import('../../inputs/Percentage.vue' /* webpackChunkName: "components/input-percentage" */).then(c => wrapFunctional(c.default || c))
export const InputPromotionActions = () => import('../../inputs/PromotionActions.vue' /* webpackChunkName: "components/input-promotion-actions" */).then(c => wrapFunctional(c.default || c))
export const InputPromotionRules = () => import('../../inputs/PromotionRules.vue' /* webpackChunkName: "components/input-promotion-rules" */).then(c => wrapFunctional(c.default || c))
export const InputRegionSelect = () => import('../../inputs/RegionSelect.vue' /* webpackChunkName: "components/input-region-select" */).then(c => wrapFunctional(c.default || c))
export const InputRelationSelect = () => import('../../inputs/RelationSelect.vue' /* webpackChunkName: "components/input-relation-select" */).then(c => wrapFunctional(c.default || c))
export const InputSeoMeta = () => import('../../inputs/SeoMeta.vue' /* webpackChunkName: "components/input-seo-meta" */).then(c => wrapFunctional(c.default || c))
export const InputShippingCalculator = () => import('../../inputs/ShippingCalculator.vue' /* webpackChunkName: "components/input-shipping-calculator" */).then(c => wrapFunctional(c.default || c))
export const InputSlug = () => import('../../inputs/Slug.vue' /* webpackChunkName: "components/input-slug" */).then(c => wrapFunctional(c.default || c))
export const InputSortableRelation = () => import('../../inputs/SortableRelation.vue' /* webpackChunkName: "components/input-sortable-relation" */).then(c => wrapFunctional(c.default || c))
export const InputSwitch = () => import('../../inputs/Switch.vue' /* webpackChunkName: "components/input-switch" */).then(c => wrapFunctional(c.default || c))
export const InputTaxons = () => import('../../inputs/Taxons.vue' /* webpackChunkName: "components/input-taxons" */).then(c => wrapFunctional(c.default || c))
export const InputTextEditor = () => import('../../inputs/TextEditor.vue' /* webpackChunkName: "components/input-text-editor" */).then(c => wrapFunctional(c.default || c))
export const InputUploadMeta = () => import('../../inputs/UploadMeta.vue' /* webpackChunkName: "components/input-upload-meta" */).then(c => wrapFunctional(c.default || c))
export const InputUserEmail = () => import('../../inputs/UserEmail.vue' /* webpackChunkName: "components/input-user-email" */).then(c => wrapFunctional(c.default || c))
export const InputAttributesColorValue = () => import('../../inputs/attributes/ColorValue.vue' /* webpackChunkName: "components/input-attributes-color-value" */).then(c => wrapFunctional(c.default || c))
export const InputAttributesCountryValue = () => import('../../inputs/attributes/CountryValue.vue' /* webpackChunkName: "components/input-attributes-country-value" */).then(c => wrapFunctional(c.default || c))
export const InputAttributesLengthValue = () => import('../../inputs/attributes/LengthValue.vue' /* webpackChunkName: "components/input-attributes-length-value" */).then(c => wrapFunctional(c.default || c))
export const InputAttributesNumberValue = () => import('../../inputs/attributes/NumberValue.vue' /* webpackChunkName: "components/input-attributes-number-value" */).then(c => wrapFunctional(c.default || c))
export const InputAttributesTextValue = () => import('../../inputs/attributes/TextValue.vue' /* webpackChunkName: "components/input-attributes-text-value" */).then(c => wrapFunctional(c.default || c))
export const InputAttributesVolumeValue = () => import('../../inputs/attributes/VolumeValue.vue' /* webpackChunkName: "components/input-attributes-volume-value" */).then(c => wrapFunctional(c.default || c))
export const InputAttributesWeightValue = () => import('../../inputs/attributes/WeightValue.vue' /* webpackChunkName: "components/input-attributes-weight-value" */).then(c => wrapFunctional(c.default || c))
export const InputOrdersAddOrderItem = () => import('../../inputs/orders/AddOrderItem.vue' /* webpackChunkName: "components/input-orders-add-order-item" */).then(c => wrapFunctional(c.default || c))
export const InputOrdersOrderItem = () => import('../../inputs/orders/OrderItem.vue' /* webpackChunkName: "components/input-orders-order-item" */).then(c => wrapFunctional(c.default || c))
export const InputPromotionsCountryRule = () => import('../../inputs/promotions/CountryRule.vue' /* webpackChunkName: "components/input-promotions-country-rule" */).then(c => wrapFunctional(c.default || c))
export const InputPromotionsOrderAdjustmentAction = () => import('../../inputs/promotions/OrderAdjustmentAction.vue' /* webpackChunkName: "components/input-promotions-order-adjustment-action" */).then(c => wrapFunctional(c.default || c))
export const InputPromotionsOrderRule = () => import('../../inputs/promotions/OrderRule.vue' /* webpackChunkName: "components/input-promotions-order-rule" */).then(c => wrapFunctional(c.default || c))
export const InputPromotionsPriceAdjustmentAction = () => import('../../inputs/promotions/PriceAdjustmentAction.vue' /* webpackChunkName: "components/input-promotions-price-adjustment-action" */).then(c => wrapFunctional(c.default || c))
export const InputPromotionsProductRule = () => import('../../inputs/promotions/ProductRule.vue' /* webpackChunkName: "components/input-promotions-product-rule" */).then(c => wrapFunctional(c.default || c))
export const InputPromotionsRegionRule = () => import('../../inputs/promotions/RegionRule.vue' /* webpackChunkName: "components/input-promotions-region-rule" */).then(c => wrapFunctional(c.default || c))
export const InputPromotionsShippingAdjustmentAction = () => import('../../inputs/promotions/ShippingAdjustmentAction.vue' /* webpackChunkName: "components/input-promotions-shipping-adjustment-action" */).then(c => wrapFunctional(c.default || c))
export const InputPromotionsTaxonRule = () => import('../../inputs/promotions/TaxonRule.vue' /* webpackChunkName: "components/input-promotions-taxon-rule" */).then(c => wrapFunctional(c.default || c))
export const InputPromotionsZoneRule = () => import('../../inputs/promotions/ZoneRule.vue' /* webpackChunkName: "components/input-promotions-zone-rule" */).then(c => wrapFunctional(c.default || c))
export const InputUploadsFileUpload = () => import('../../inputs/uploads/FileUpload.vue' /* webpackChunkName: "components/input-uploads-file-upload" */).then(c => wrapFunctional(c.default || c))
export const InputUploadsImageUpload = () => import('../../inputs/uploads/ImageUpload.vue' /* webpackChunkName: "components/input-uploads-image-upload" */).then(c => wrapFunctional(c.default || c))
export const InputUploadsVideoUpload = () => import('../../inputs/uploads/VideoUpload.vue' /* webpackChunkName: "components/input-uploads-video-upload" */).then(c => wrapFunctional(c.default || c))
export const InputCalculatorsPaymentFlatRate = () => import('../../inputs/calculators/payment/FlatRate.vue' /* webpackChunkName: "components/input-calculators-payment-flat-rate" */).then(c => wrapFunctional(c.default || c))
export const InputCalculatorsPaymentFlatWeightRate = () => import('../../inputs/calculators/payment/FlatWeightRate.vue' /* webpackChunkName: "components/input-calculators-payment-flat-weight-rate" */).then(c => wrapFunctional(c.default || c))
export const InputCalculatorsPaymentFlexibleWeightRate = () => import('../../inputs/calculators/payment/FlexibleWeightRate.vue' /* webpackChunkName: "components/input-calculators-payment-flexible-weight-rate" */).then(c => wrapFunctional(c.default || c))
export const InputCalculatorsShippingFlatPercent = () => import('../../inputs/calculators/shipping/FlatPercent.vue' /* webpackChunkName: "components/input-calculators-shipping-flat-percent" */).then(c => wrapFunctional(c.default || c))
export const InputCalculatorsShippingFlatRate = () => import('../../inputs/calculators/shipping/FlatRate.vue' /* webpackChunkName: "components/input-calculators-shipping-flat-rate" */).then(c => wrapFunctional(c.default || c))
export const InputCalculatorsShippingFlatRatePerItem = () => import('../../inputs/calculators/shipping/FlatRatePerItem.vue' /* webpackChunkName: "components/input-calculators-shipping-flat-rate-per-item" */).then(c => wrapFunctional(c.default || c))
export const InputCalculatorsShippingFlatWeightRate = () => import('../../inputs/calculators/shipping/FlatWeightRate.vue' /* webpackChunkName: "components/input-calculators-shipping-flat-weight-rate" */).then(c => wrapFunctional(c.default || c))
export const InputCalculatorsShippingFlexibleRatePerItem = () => import('../../inputs/calculators/shipping/FlexibleRatePerItem.vue' /* webpackChunkName: "components/input-calculators-shipping-flexible-rate-per-item" */).then(c => wrapFunctional(c.default || c))
export const InputCalculatorsShippingFlexibleWeightRate = () => import('../../inputs/calculators/shipping/FlexibleWeightRate.vue' /* webpackChunkName: "components/input-calculators-shipping-flexible-weight-rate" */).then(c => wrapFunctional(c.default || c))
export const InputCalculatorsShippingPriceSack = () => import('../../inputs/calculators/shipping/PriceSack.vue' /* webpackChunkName: "components/input-calculators-shipping-price-sack" */).then(c => wrapFunctional(c.default || c))
export const InputFormBuilderControls = () => import('../../inputs/form/builder/Controls.vue' /* webpackChunkName: "components/input-form-builder-controls" */).then(c => wrapFunctional(c.default || c))
export const InputFormBuilderField = () => import('../../inputs/form/builder/Field.vue' /* webpackChunkName: "components/input-form-builder-field" */).then(c => wrapFunctional(c.default || c))
export const InputFormBuilderFields = () => import('../../inputs/form/builder/Fields.vue' /* webpackChunkName: "components/input-form-builder-fields" */).then(c => wrapFunctional(c.default || c))
export const InputFormBuilderGroup = () => import('../../inputs/form/builder/Group.vue' /* webpackChunkName: "components/input-form-builder-group" */).then(c => wrapFunctional(c.default || c))
export const InputFormBuilderPreview = () => import('../../inputs/form/builder/Preview.vue' /* webpackChunkName: "components/input-form-builder-preview" */).then(c => wrapFunctional(c.default || c))
export const ColumnActive = () => import('../../columns/Active.vue' /* webpackChunkName: "components/column-active" */).then(c => wrapFunctional(c.default || c))
export const ColumnAvatar = () => import('../../columns/Avatar.vue' /* webpackChunkName: "components/column-avatar" */).then(c => wrapFunctional(c.default || c))
export const ColumnBase = () => import('../../columns/Base.vue' /* webpackChunkName: "components/column-base" */).then(c => wrapFunctional(c.default || c))
export const ColumnBoolean = () => import('../../columns/Boolean.vue' /* webpackChunkName: "components/column-boolean" */).then(c => wrapFunctional(c.default || c))
export const ColumnCountry = () => import('../../columns/Country.vue' /* webpackChunkName: "components/column-country" */).then(c => wrapFunctional(c.default || c))
export const ColumnDate = () => import('../../columns/Date.vue' /* webpackChunkName: "components/column-date" */).then(c => wrapFunctional(c.default || c))
export const ColumnImage = () => import('../../columns/Image.vue' /* webpackChunkName: "components/column-image" */).then(c => wrapFunctional(c.default || c))
export const ColumnLabel = () => import('../../columns/Label.vue' /* webpackChunkName: "components/column-label" */).then(c => wrapFunctional(c.default || c))
export const ColumnList = () => import('../../columns/List.vue' /* webpackChunkName: "components/column-list" */).then(c => wrapFunctional(c.default || c))
export const ColumnNumber = () => import('../../columns/Number.vue' /* webpackChunkName: "components/column-number" */).then(c => wrapFunctional(c.default || c))
export const ColumnPaperclip = () => import('../../columns/Paperclip.vue' /* webpackChunkName: "components/column-paperclip" */).then(c => wrapFunctional(c.default || c))
export const ColumnPrice = () => import('../../columns/Price.vue' /* webpackChunkName: "components/column-price" */).then(c => wrapFunctional(c.default || c))
export const ColumnSeo = () => import('../../columns/Seo.vue' /* webpackChunkName: "components/column-seo" */).then(c => wrapFunctional(c.default || c))
export const ColumnSlug = () => import('../../columns/Slug.vue' /* webpackChunkName: "components/column-slug" */).then(c => wrapFunctional(c.default || c))
export const ColumnState = () => import('../../columns/State.vue' /* webpackChunkName: "components/column-state" */).then(c => wrapFunctional(c.default || c))
export const ColumnTitle = () => import('../../columns/Title.vue' /* webpackChunkName: "components/column-title" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
