import { render, staticRenderFns } from "./RegionRule.vue?vue&type=template&id=6b72f348&"
import script from "./RegionRule.vue?vue&type=script&lang=js&"
export * from "./RegionRule.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputRelationSelect: require('/opt/build/repo/inputs/RelationSelect.vue').default,ActionSection: require('/opt/build/repo/components/ActionSection.vue').default})
