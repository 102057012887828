
import Input from '@/mixins/input'

export default {
  name: 'UserEmailInput',
  mixins: [Input],
  props: {
    value: {
      type: String,
      default: null
    }
  },
  watch: {
    value(newValue) {
      this.email = newValue
    }
  },
  data() {
    return {
      users: [],
      email: null
    }
  },
  methods: {
    async fetchUsers(search) {
      const result = await this.$graphql.query({
        path: 'auth/user',
        prefetch: false,
        collection: true,
        variables: {
          first: 5,
          where: { email_contains: search }
        },
        locale: this.locale
      })

      this.users = result && result.data || []
    },
    onChange(value) {
      this.$emit('input', value)

      const user = this.users.find(({ email }) => email == value)

      if (user) {
        this.$emit('selected', user, this)
      }
    }
  }
}
