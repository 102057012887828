
import Column from '@/mixins/column'

export default {
  name: 'LabelColumn',
  mixins: [Column],
  props: {
    types: {
      type: Object,
      default: null
    }
  },
  computed: {
    type() {
      const types = this.types || {}
      return types[this.value] || 'info'
    }
  }
}
