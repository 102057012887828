export default {
  options: {
    columns: 4
  },
  translates: [
    'name',
    'description'
  ],
  inputs: {
    name: {
      span: 4,
      required: true
    },
    internalName: {
      span: 4,
      required: true
    },
    description: {
      $input: 'text-editor',
      span: 4
    },
    minAmount: {
      $input: 'number',
      span: 2,
      $el: {
        label: 'EUR'
      }
    },
    maxAmount: {
      $input: 'number',
      span: 2,
      $el: {
        label: 'EUR'
      }
    },
    kind: {
      $input: 'enum',
      label: 'provider',
      span: 4,
      required: true,
      $updateValue: {
        id: 'provider',
        value: {}
      },
      $el: {
        enumKey: 'kindEnum'
      }
    },
    provider: {
      $type: 'group',
      $columns: 4,
      span: 4,
      $disableWhen: { kind: 'OFFLINE' },
      $inputs: {
        accounts: {
          $input: 'add-more',
          label: 'bankAccounts',
          span: 4,
          required: true,
          $el: {
            primaryKey: null,
            columns: 2,
            gap: 'xs',
            splitRows: true,
            rowGap: 'sm',
            controlsDirection: 'col',
            alignControls: 'end',
            inputs: [
              { span: 1, prop: 'bankName', label: 'bankName' },
              { span: 1, prop: 'holderName', label: 'holderName' },
              { span: 2, prop: 'iban', label: 'iban' }
            ]
          },
          $enableWhen: { kind: 'BANK_TRANSFER' }
        },
        apiKey: {
          span: 4,
          required: true,
          $enableWhen: { kind: 'STRIPE' }
        },
        publishableKey: {
          span: 4,
          required: true,
          $enableWhen: { kind: 'STRIPE' }
        },
        clientId: {
          label: 'clientID',
          span: 4,
          required: true,
          $enableWhen: { kind: 'VIVA_WALLET' }
        },
        clientSecret: {
          span: 4,
          required: true,
          $enableWhen: { kind: 'VIVA_WALLET' }
        },
        apiUsername: {
          span: 4,
          required: true,
          $enableWhen: { kind: 'KLARNA' }
        },
        apiPassword: {
          span: 4,
          required: true,
          $enableWhen: { kind: 'KLARNA' }
        },
        merchantId: {
          label: 'merchantID',
          span: 4,
          required: true,
          $enableWhen: { kind: 'IBANK_ECOMMERCE' }
        },
        publicKey: {
          span: 4,
          required: true,
          $enableWhen: { kind: 'EVERYPAY' }
        },
        privateKey: {
          span: 4,
          required: true,
          $enableWhen: [
            { kind: 'EVERYPAY' },
            { kind: 'IBANK_ECOMMERCE' }
          ]
        },
        allowTokenize: {
          span: 4,
          $input: 'switch',
          required: false,
          $enableWhen: { kind: 'EVERYPAY' }
        },
        installmentsOptions: {
          span: 4,
          $input: 'add-more',
          $el: {
            primaryKey: null,
            columns: 2,
            gap: 'xs',
            splitRows: true,
            rowGap: 'sm',
            controlsDirection: 'col',
            alignControls: 'end',
            inputs: [
              { span: 2, prop: 'amount', $input: 'money' },
              { span: 1, prop: 'min', $input: 'number', $attrs: { label: 'MIN', step: 1, precision: 0 } },
              { span: 1, prop: 'max', $input: 'number', $attrs: { label: 'MAX', step: 1, precision: 0 } }
            ]
          },
          $enableWhen: { kind: 'IBANK_ECOMMERCE' }
        },
        installmentsRates: {
          span: 4,
          $input: 'add-more',
          $el: {
            primaryKey: null,
            gap: 'xs',
            rowGap: 'sm',
            inputs: [
              { prop: 'max', $input: 'number', $attrs: { label: 'Up to', step: 1, precision: 0 } },
              { prop: 'rate', $input: 'percentage' }
            ]
          },
          $enableWhen: { kind: 'IBANK_ECOMMERCE' }
        }
      }
    },
    active: {
      $input: 'switch',
      span: 2
    },
    displayOn: {
      $input: 'enum',
      span: 2,
      $el: {
        enumKey: 'displayOnEnum'
      }
    },
    calculatorAttributes: {
      $input: 'payment-calculator',
      label: 'costCalculator',
      span: 4,
      $el: {
        dataKey: 'calculator'
      }
    },
    autoCapture: {
      $input: 'switch',
      span: 4
    },
    shippingMethodIds: {
      $input: 'relation-select',
      span: 4,
      $el: {
        path: 'eshop/shipping-method',
        multiple: true,
        labelKey: 'internalName',
        textKey: 'id'
      }
    },
    zoneIds: {
      $input: 'relation-select',
      span: 4,
      $el: {
        path: 'eshop/zone',
        multiple: true,
        textKey: 'id'
      }
    },
    zoneFromShipAddress: {
      $input: 'switch',
      span: 4
    }
  }
}
