export default {
  options: {
    columns: 4
  },
  inputs: {
    name: {
      span: 4,
      required: true
    },
    kind: {
      $input: 'enum',
      span: 4,
      required: true,
      $updateValue: [
        {
          id: 'continentIds',
          value: null
        },
        {
          id: 'countryIds',
          value: null
        },
        {
          id: 'regionIds',
          value: null
        }
      ],
      $el: {
        enumKey: 'kindEnum'
      }
    },
    continentIds: {
      $input: 'relation-select',
      required: true,
      span: 4,
      $el: {
        path: 'eshop/continent',
        variables: {
          orderBy: 'name_ASC'
        },
        multiple: true
      },
      $enableWhen: { kind: 'CONTINENT' }
    },
    countryIds: {
      $input: 'country-select',
      required: true,
      span: 4,
      $el: {
        multiple: true
      },
      $enableWhen: { kind: 'COUNTRY' }
    },
    regionIds: {
      $input: 'relation-select',
      required: true,
      span: 4,
      $el: {
        path: 'eshop/region',
        textKey: 'id',
        multiple: true
      },
      $enableWhen: { kind: 'REGION' }
    },
    geoRange: {
      $input: 'geo-location',
      required: true,
      span: 4,
      $enableWhen: { kind: 'GEO_RANGE' }
    },
    defaultTax: {
      $input: 'switch',
      span: 4
    }
  }
}
