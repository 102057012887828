
import { isEqual } from 'vessel/utils'

export default {
  name: 'PromotionTaxonRule',
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.taxonomy = newValue && newValue.options.taxonomyId
        this.selected = newValue && newValue.options.taxonIds
      }
    },
    taxonomy(newValue, oldValue) {
      if (newValue != oldValue) {
        this.selected = null
      }
    },
    selected(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit('input', {
          ...this.value,
          type: 'taxon',
          options: { taxonIds: newValue, taxonomyId: this.taxonomy }
        })
      }
    }
  },
  data() {
    return {
      taxonomy: this.value && this.value.options.taxonomyId,
      selected: this.value && this.value.options.taxonIds
    }
  }
}
