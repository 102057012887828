export default {
  Details: {
    options: {
      columns: 4
    },
    translates: [
      'name',
      'description',
      'excerpt',
      'imageAttributes'
    ],
    inputs: {
      name: {
        span: 4,
        required: true
      },
      kind: {
        $input: 'enum',
        span: 2,
        required: true,
        $el: {
          enumKey: 'kindEnum'
        }
      },
      uniqueId: {
        span: 2,
        required: true
      },
      nested: {
        $input: 'switch',
        $enableWhen: {
          kind: 'CUSTOM'
        },
        span: 2
      },
      multiple: {
        $input: 'switch',
        $enableWhen: {
          kind: 'CUSTOM'
        },
        span: 2
      },
      description: {
        $input: 'text-editor',
        span: 4,
        $el: {
          seo: true
        }
      },
      excerpt: {
        $input: 'text-editor',
        span: 4,
        $el: {
          minimal: true
        }
      },
      imageAttributes: {
        $input: 'file-upload',
        span: 4,
        $el: {
          clientId: 'eshop',
          type: 'image',
          multiple: false
        }
      }
    }
  },
  Tree: {
    options: {
      columns: 4
    },
    inputs: {
      childrenAttributes: {
        $input: 'nestable',
        label: 'taxons',
        span: 4,
        $el: {
          path: 'eshop/taxon',
          schema: 'Tree',
          deepKey: 'nested'
        }
      }
    }
  }
}
