
import formItems from '@/utils/form-items'
import { isFunction } from 'vessel/utils'

export default {
  name: 'FormItems',
  inject: {
    recordForm: {
      default: {
        $refs: {}
      }
    }
  },
  props: {
    path: {
      type: String,
      required: true
    },
    schema: {
      type: String,
      default: 'Form'
    },
    localeKey: {
      type: String,
      default: 'inputs'
    }
  },
  data() {
    return {
      items: null,
      options: null,
      parser: null,
      translates: null
    }
  },
  computed: {
    defaultLocale() {
      return this.recordForm.defaultLocale
    },
    locale() {
      return this.recordForm.locale
    },
    isLocalized() {
      return this.translates && this.translates.length
    }
  },
  methods: {
    t(message) {
      return this.$t(`${this.localeKey}.${message}`)
    },
    loadSchema(callback) {
      let schema = {}

      try {
        const forms  = require(`@/forms/${this.path}`)
        const object = forms.default || forms

        schema = object.inputs ? object : object[this.schema]
      } catch (e) {
        console.warn('Form Error:', e)
      }

      if (isFunction(callback)) {
        callback.call(null, schema)
      } else {
        return schema
      }
    },
    generateFields(inputs) {
      this.items = inputs
        ? formItems(this, this.localeKey, inputs)
        : null
    },
    generateForm() {
      this.loadSchema(({ translates, options, parser, inputs }) => {
        this.options    = options
        this.parser     = parser
        this.translates = translates

        this.generateFields(inputs)
        this.$emit('generate', inputs)
      })
    },
    reloadForm() {
      this.loadSchema(({ inputs }) => {
        this.items = inputs
          ? formItems(this, this.localeKey, inputs)
          : null
      })
    }
  },
  render() {
    if (this.$scopedSlots && this.$scopedSlots.default) {
      return this.$scopedSlots.default({
        items: this.items,
        options: this.options,
        parser: this.parser,
        translates: this.translates
      })
    }

    return null
  },
  created() {
    this.generateForm()
  }
}
