
import { sync } from 'vuex-pathify'
import { camelize } from 'vessel/utils'

export default {
  props: {
    routeBase: {
      type: String,
      default: null
    },
    routePath: {
      type: String,
      default: null
    }
  },
  provide() {
    return {
      $layout: this
    }
  },
  head() {
    return {
      title: this.pageTitle,
      titleTemplate: title => {
        return title ? `${title} (Statica)` : 'Statica Admin'
      }
    }
  },
  computed: {
    collapsedState: sync('preferences/sidebarCollapsed'),
    collapsed() {
      return this.$screen.xl ? this.collapsedState : true
    },
    sidebarWidth() {
      return this.collapsed ? 'md' : '3xl'
    },
    menuPath() {
      return this.routeBase
    },
    routePrefix() {
      const parts = [this.routeBase, this.routePath].filter(i => !!i)
      return parts.length && parts.join('/') || null
    },
    pageTitle() {
      const parts = this.$route.path.split('/').filter(item => item)
      return parts.map(item => camelize(item, 'upper')).join(' ')
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$screen.xl) {
        this.collapsedState = !this.collapsedState
      } else {
        this.$drawer.toggle('main-sidebar')
      }
    }
  }
}
