
import { isEqual } from 'vessel/utils'

export default {
  name: 'PromotionOrderRule',
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.minAmount = newValue.options.minAmount || 0
          this.maxAmount = newValue.options.maxAmount || 0
        }
      }
    },
    minAmount: 'updateValue',
    maxAmount: 'updateValue'
  },
  data() {
    return {
      minAmount: 0,
      maxAmount: 0
    }
  },
  methods: {
    updateValue(newValue, oldValue) {
      if (newValue != oldValue) {
        const { minAmount, maxAmount } = this

        this.$emit('input', {
          ...this.value,
          options: { minAmount, maxAmount },
          type: 'order'
        })
      }
    }
  }
}
