
export default {
  name: 'LogoBar',
  props: {
    text: {
      type: String,
      default: 'STATICA ADMIN'
    },
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    bar: {
      paddingRight: 'md',
      $collapsed: {
        paddingLeft(value) {
          return value ? 'md' : '2xl'
        }
      }
    },
    text: {
      height: 'md',
      textColor: 'white',
      fontWeight: 'bold',
      userSelect: 'none'
    }
  }
}
