
import Input from '@/mixins/input'
import { isEqual } from 'vessel/utils'

export default {
  name: 'Taxons',
  mixins: [Input],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    columns: {
      type: [Number, String, Object],
      default: 1
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.$nextTick(async () => {
          if (!this.taxonomies) {
            await this.fetchQuery()
          }

          this.parseValue(newValue)
        })
      }
    },
    locale(newValue, oldValue) {
      if (newValue !== oldValue && !this.skip) {
        this.fetchQuery()
      }
    },
    taxons: {
      deep: true,
      handler(value) {
        const newValue = this.normalizeValue(Object.values(value))
        const oldValue = this.normalizeValue(this.value)

        if (!isEqual(newValue, oldValue)) {
          this.$emit('input', newValue)
        }
      }
    }
  },
  data() {
    return {
      taxons: {},
      taxonomies: null,
      loading: false
    }
  },
  methods: {
    async fetchQuery() {
      this.loading = true

      const result = await this.$graphql.query({
        path: 'eshop/taxonomy',
        query: 'TaxonomyTaxons',
        fetchPolicy: 'no-cache',
        collection: true,
        locale: this.locale
      })

      this.taxonomies = result.data
      this.loading    = false
    },
    selected({ taxons, multiple }) {
      const active = [...this.value]
      const select = taxId => taxons.some(({ id }) => id == taxId)

      return multiple ? active.filter(select) : active.find(select)
    },
    normalizeValue(value) {
      return value.flat().sort().filter(item => item != '')
    },
    parseValue(newValue) {
      if (newValue) {
        this.taxonomies.forEach(taxonomy => {
          const value = this.selected(taxonomy)
          value && this.$set(this.taxons, taxonomy.id, value)
        })
      } else {
        this.taxons = {}
      }
    }
  },
  mounted() {
    this.$nextTick(this.fetchQuery)
  }
}
