
export default {
  props: {
    fields: {
      type: Array,
      default: null
    },
    active: {
      type: Number,
      default: null
    },
    activeChild: {
      type: Number,
      default: null
    }
  },
  computed: {
    draggable() {
      return {
        handle: '.handle',
        setData(dataTransfer, el) {
          const clone = el.cloneNode(false)
          clone.style.opacity = 0

          dataTransfer.setDragImage(clone, 0, 0)
        }
      }
    }
  },
  methods: {
    inputTag({ type }) {
      const tags = { group: 'input-form-builder-group' }
      return tags[type] || 'input-form-builder-field'
    },
    onSortEnd(event) {
      const items = [...this.fields]
      const field = items.splice(event.oldIndex, 1)[0]

      if (field) {
        items.splice(event.newIndex, 0, field)
      }

      this.$emit('sort', event, items)
    }
  }
}
