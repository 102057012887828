const options = {
  columns: 4
}

const Cart = {
  options,
  inputs: {
    orderItemsAttributes: {
      $input: 'order-items',
      span: 4,
      required: true,
      label: 'orderItems'
    }
  }
}

const AddressDetails = {
  firstName: {
    span: 2,
    required: true
  },
  lastName: {
    span: 2,
    required: true
  },
  phone: {
    span: 2,
    required: true
  },
  // alternativePhone: {
  //   span: 2
  // },
  address1: {
    span: 2,
    required: true
  },
  // address2: {
  //   span: 2
  // },
  countryId: {
    $input: 'country-select',
    span: 2,
    required: true
  },
  regionName: {
    $input: 'region-select',
    $el: {
      valueKey: 'name'
    },
    span: 2,
    required: true
  },
  city: {
    span: 2,
    required: true
  },
  zipcode: {
    span: 2,
    required: true
  },
  notes: {
    span: 4,
    type: 'textarea'
  }
}

const AddressCompany = {
  company: {
    span: 2,
    required: true,
    $enableWhen: { issueInvoice: true }
  },
  businessActivity: {
    span: 2,
    required: true,
    $enableWhen: { issueInvoice: true }
  },
  companyNumber: {
    span: 2,
    required: true,
    $enableWhen: { issueInvoice: true }
  },
  taxOffice: {
    span: 2,
    required: true,
    $enableWhen: { issueInvoice: true }
  }
}

const Customer = {
  options,
  inputs: {
    email: {
      $input: 'user-email',
      span: 2,
      required: true,
      on: {
        selected(user, { form }) {
          const fields = [
            'shipToBilling',
            'billingAddress',
            'shippingAddress'
          ]

          fields.forEach(id => {
            form.updateValue({ id, value: user[id] })
          })
        }
      }
    },
    guestCheckout: {
      $input: 'switch',
      span: 2
    },
    shipToBilling: {
      $input: 'switch',
      span: 2
    },
    issueInvoice: {
      $input: 'switch',
      span: 2
    },
    billingAddress: {
      $type: 'group',
      $component: 'form-group',
      $columns: 4,
      $inputs: {
        ...AddressCompany,
        ...AddressDetails
      },
      span: 4
    },
    shippingAddress: {
      $type: 'group',
      $component: 'form-group',
      $enableWhen: { shipToBilling: false },
      $columns: 4,
      $inputs: {
        ...AddressDetails
      },
      span: 4
    }
  }
}

export default {
  Cart,
  Customer
}
