
import menuItems from '@/utils/menu-items'

export default {
  name: 'MenuItems',
  inject: {
    $layout: {
      default: {}
    }
  },
  props: {
    path: {
      type: String,
      required: true
    },
    route: {
      type: String,
      default: '/'
    },
    localeKey: {
      type: String,
      default: 'pages'
    }
  },
  computed: {
    routePath() {
      return this.$route.path.replace(/\/$/, '')
    },
    basePath() {
      const base = this.route.startsWith('/') ? this.route : `/${this.route}`

      if (this.$layout.routePrefix) {
        return `${this.$layout.routePrefix}${base}`
      } else {
        return base
      }
    },
    filePath() {
      if (this.$layout.menuPath) {
        return `${this.$layout.menuPath}/${this.path}`
      } else {
        return this.path
      }
    },
    newPage() {
      return this.routePath.endsWith('new')
    },
    editPage() {
      return this.routePath.search(/edit\/\d+/) > 0
    }
  },
  render() {
    const schema = require(`@/menus/${this.filePath}`)
    const config = schema.default || schema

    if (this.$scopedSlots && this.$scopedSlots.default) {
      return this.$scopedSlots.default({
        items: menuItems(this, this.localeKey, this.basePath, config)
      })
    }

    return null
  }
}
