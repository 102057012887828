import { Editor as CoreEditor } from '@tiptap/core'

import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'

const defaultExtensions = [
  Underline,
  StarterKit.configure({
    heading: {
      levels: [1, 2, 3]
    }
  })
]

export default class Editor extends CoreEditor {
  constructor({ extensions, ...options }) {
    super({
      extensions: [
        ...defaultExtensions,
        ...extensions || []
      ],
      ...options
    })
  }

  parseHTML(html) {
    return html == '<p></p>' ? null : html
  }

  fetchHTML() {
    return this.parseHTML(this.getHTML())
  }
}
