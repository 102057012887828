import { isPlainObject, isFunction, reject, camelize } from 'vessel/utils'

function formItem(context, localeKey, name, config, gridCols) {
  const { translates, locale, defaultLocale } = context

  const localize = text => context.$t(`${localeKey}.${text}`)
  const labelize = text => {
    let label = text || name

    label = label.replace(/Ids$/, 's')
    label = label.replace(/ys$/, 'ies')
    label = label.replace(/(Attributes|Ids|Id)$/, '')

    return localize(label)
  }

  const columns = gridCols || Object(context.formProps).columns || 1
  const input   = isFunction(config) ? config.call(context) : config
  const ignore  = ['required', 'rules', 'span', 'label', '$attrs', '$el', '$input']
  const options = reject(input, key => ignore.includes(key))
  const label   = input.label == false ? null : labelize(input.label)
  const rules   = []
  const props   = { ...input.$el }

  if (input.required) {
    rules.push({
      required: true,
      message: localize('required')
    })
  }

  if (input.rules) {
    rules.push(...input.rules)
  }

  if (input.type) {
    props.type = input.type
  }

  if (translates && !translates.includes(name)) {
    props.disabled = locale !== defaultLocale
  }

  if (input.$input) {
    const tagName = camelize(input.$input, 'upper')
    options.$tag = `Input${tagName}`
  }

  if (isPlainObject(input.span)) {
    input.span = { all: columns, ...input.span }
  } else {
    input.span = { all: columns, md: input.span }
  }

  return {
    $type: 'input',
    $id: name,
    $prop: name,
    $el: props,
    $attrs: {
      span: input.span,
      ...input.$attrs
    },
    label: label,
    rules: rules,
    ...options
  }
}

export default function formItems(context, localeKey, inputs, columns) {
  return Object.entries(inputs).reduce((result, [name, input]) => {
    const item = formItem(context, localeKey, name, input, columns)

    if (item.$type && item.$type == 'group') {
      const inputs = { ...item.$inputs }
      delete item.$inputs

      item.$items = formItems(context, localeKey, inputs, item.$columns)
      item.$el = { label: item.label, required: input.required }
    }

    result.push(item)
    return result
  }, [])
}
