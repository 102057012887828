const expiresAt = ({ expires_in }) => {
  const now = new Date()
  now.setSeconds(now.getSeconds() + expires_in - 5)

  return now.getTime()
}

const state = () => ({
  token: null,
  refreshToken: null,
  expiresAt: null,
  redirect: null,
  lastPath: null
})

const mutations = {
  SET_TOKEN (state, { access_token, refresh_token, ...data }) {
    state.token = access_token
    state.refreshToken = refresh_token
    state.expiresAt = expiresAt(data)
  },

  SET_REDIRECT (state, value) {
    state.redirect = value
  },

  SET_LAST (state, value) {
    state.lastPath = value
  },

  RESET_LAST (state) {
    state.lastPath = null
  },

  RESET (state) {
    state.token = null
    state.refreshToken = null
    state.expiresAt = null
    state.redirect = null
  }
}

const getters = {
  token(state) {
    return state.token
  },

  refreshToken(state) {
    return state.refreshToken
  },

  expiresAt(state) {
    return state.expiresAt
  },

  lastPath(state) {
    return state.lastPath
  },

  redirect(state) {
    return state.redirect
  }
}

const actions = {
  setToken({ commit }, data) {
    commit('SET_TOKEN', data)
  },

  setRedirect({ commit }, data) {
    commit('SET_REDIRECT', data)
  },

  setLast({ commit }, data) {
    commit('SET_LAST', data)
  },

  resetLast({ commit }) {
    commit('RESET_LAST')
  },

  reset({ commit }) {
    commit('RESET')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
