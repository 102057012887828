import { render, staticRenderFns } from "./Preview.vue?vue&type=template&id=5af17c7c&"
import script from "./Preview.vue?vue&type=script&lang=js&"
export * from "./Preview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ActionBar: require('/opt/build/repo/components/ActionBar.vue').default,InputFormBuilderFields: require('/opt/build/repo/inputs/form/builder/Fields.vue').default})
