export default {
  options: {
    columns: 4
  },
  inputs: {
    variantId() {
      return {
        $input: 'relation-select',
        required: true,
        span: 4,
        $el: {
          path: 'eshop/variant',
          textKey: 'sku',
          labelKey: 'label',
          variables: { where: { product: { id: this.$route.params.id } } },
          filter(data) {
            if (data.length > 1) {
              return data.filter(({ master }) => !master)
            } else {
              return data
            }
          }
        }
      }
    },
    stockLocationId: {
      $input: 'relation-select',
      required: true,
      span: 4,
      $el: {
        path: 'eshop/stock-location',
        textKey: 'id'
      }
    },
    countOnHand: {
      $input: 'number',
      span: 4,
      $el: {
        label: 'pcs',
        precision: 0
      }
    }
  }
}
