import { reactive } from 'vue'

const browsers = [
  ['Samsung', /SamsungBrowser/i],
  ['Miui', /MiuiBrowser/i],
  ['Opera', /OPR\//i],
  ['Edge', /edg([ea]|ios|)\//i],
  ['Firefox', /firefox|iceweasel|fxios/i],
  ['Chrome', /chrome|crios|crmo/i],
  ['Safari', /safari|applewebkit/i]
]

const systems = [
  ['Ios', /iPad|iPhone|iPod/],
  ['Android', /android/i],
  ['Windows', /Windows/],
  ['MacOS', /Mac OS X/],
  ['Linux', /Linux/i]
]

const matchRegex = (items, agent) => {
  for (const item of items) {
    if (item[1].test(agent)) {
      return item[0]
    }
  }
  return ''
}

const defineMatches = (items, object, value) => {
  for (const [name] of items) {
    object[name.toLowerCase()] = value === name
  }
}

const getDisplayMode = query => {
  if (document.referrer.startsWith('android-app://')) {
    return 'twa'
  } else if (navigator.standalone || query.matches) {
    return 'standalone'
  } else {
    return 'browser'
  }
}

export default (_ctx, inject) => {
  const userAgent = navigator.userAgent || 'Chrome Android'

  const system  = matchRegex(systems, userAgent)
  const browser = matchRegex(browsers, userAgent)
  const device  = reactive({ userAgent, system, browser, displayMode: 'browser', standalone: false })

  defineMatches(systems, device, system)
  defineMatches(browsers, device, browser)

  if (typeof window !== 'undefined') {
    const standalone = window.matchMedia('(display-mode: standalone)')

    const syncDisplayMode = evt => {
      device.displayMode = getDisplayMode(evt)
      device.standalone  = device.displayMode !== 'browser'
    }

    standalone.addEventListener('change', syncDisplayMode)
    syncDisplayMode(standalone)
  }

  inject('device', device)
}
