
import copy from 'clipboard-copy'

export default {
  name: 'Paperclip',
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      buttonType: 'default',
      iconName: 'paperclip'
    }
  },
  methods: {
    onClick() {
      copy(this.value)

      this.iconName = 'check'
      this.buttonType = 'success'

      setTimeout(() => {
        this.iconName = 'paperclip'
        this.buttonType = 'default'
      }, 1000)
    }
  }
}
