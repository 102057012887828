export default {
  options: {
    columns: 6
  },
  translates: [
    'seoMeta'
  ],
  inputs: {
    seoMeta: {
      $input: 'seo-meta',
      label: false,
      span: 6,
      $el: {
        label: 'm',
        showInputs: true
      }
    }
  }
}
