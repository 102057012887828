
export default {
  name: 'FormDialog',
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: 'Dialog'
    },
    value: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: '40rem'
    },
    buttonText: {
      type: String,
      default: 'submit'
    },
    buttons: {
      type: Array,
      default: null
    },
    disabledButtons: {
      type: Array,
      default: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.visible = newValue
        }
      }
    },
    visible(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit('input', newValue)
      }
    }
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    modalAttrs() {
      const attrs = {
        appendToBody: true,
        ...this.$attrs
      }

      if (this.autoClose) {
        attrs.closeOnEsc   = false
        attrs.closeOnClick = false
      }

      return attrs
    }
  },
  methods: {
    onCancel() {
      this.visible = false
      this.$emit('cancel')
    },
    onReset() {
      this.$emit('reset')
    },
    onSubmit() {
      this.$emit('submit')
    }
  }
}
