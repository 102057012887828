
import Input from '@/mixins/input'
import { customAlphabet } from 'nanoid'

export default {
  name: 'CodeInput',
  mixins: [Input],
  props: {
    value: {
      type: String,
      default: null
    },
    prefix: {
      type: String,
      default: 'CP'
    },
    length: {
      type: Number,
      default: 10
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    wrapper: {
      borderRadius: 'default',
      overflow: 'hidden',
      $disabled: {
        backgroundColor(value) {
          return value ? 'grey-lightest' : null
        }
      },
      $active: {
        borderColor(value) {
          return value ? { all: 'primary', hover: 'primary' } : null
        }
      }
    },
    button: {
      borderLeftWidth: '1',
      borderColor: 'grey-lighter',
      paddingX: 'sm',
      whiteSpace: 'no-wrap',
      cursor: 'pointer',
      $disabled: {
        color(value) {
          return value ? 'grey' : 'grey-dark'
        }
      },
      hover: {
        color: 'grey-darkest'
      }
    }
  },
  watch: {
    value(newValue) {
      if (newValue != this.code) {
        this.code = newValue
      }
    }
  },
  data() {
    return {
      code: this.value,
      active: false
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
    generate() {
      const max = this.length - this.prefix.length
      const key = customAlphabet('1234567890', max)

      this.code = `${this.prefix}${key()}`
      this.onChange(this.code)
    }
  }
}
