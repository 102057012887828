
import Input from '@/mixins/input'
import { isBlank } from 'vessel/utils'

export default {
  mixins: [Input],
  inheritAttrs: false,
  props: {
    parseUrl: {
      type: Function,
      default: null
    },
    value: {
      type: String,
      default: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.slug = newValue
        }
      }
    }
  },
  data() {
    return {
      slug: null
    }
  },
  computed: {
    showLink() {
      return this.parseUrl != null
    },
    link() {
      return this.parseUrl && !isBlank(this.slug)
        ? this.parseUrl(this.slug)
        : null
    }
  }
}
