
import Input from '@/mixins/input'
import { whereCode, search } from '../utils/iso-countries'

export default {
  name: 'CountryDropdown',
  mixins: [Input],
  props: {
    value: {
      type: [Array, String],
      default: null
    }
  },
  watch: {
    value(newValue) {
      this.country = newValue
    }
  },
  data() {
    return {
      country: this.value,
      search: []
    }
  },
  computed: {
    countries() {
      const active = [].concat(this.value)
      return whereCode([...active, ...this.search])
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
    searchCountry(value) {
      this.search = search(value).map(({ code }) => code)
    }
  }
}
