import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3974a0b3 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _af6b291e = () => interopDefault(import('../pages/admins/index.vue' /* webpackChunkName: "pages/admins/index" */))
const _ebf520b2 = () => interopDefault(import('../pages/bookings/index.vue' /* webpackChunkName: "pages/bookings/index" */))
const _103edbcc = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _0dcac010 = () => interopDefault(import('../pages/forms/index.vue' /* webpackChunkName: "pages/forms/index" */))
const _1eb3803a = () => interopDefault(import('../pages/hotel.vue' /* webpackChunkName: "pages/hotel" */))
const _3a09733d = () => interopDefault(import('../pages/hotel/index.vue' /* webpackChunkName: "pages/hotel/index" */))
const _7942c263 = () => interopDefault(import('../pages/newsletters/index.vue' /* webpackChunkName: "pages/newsletters/index" */))
const _5c32c212 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _fc71dd5e = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _bcfac6c6 = () => interopDefault(import('../pages/promotions/index.vue' /* webpackChunkName: "pages/promotions/index" */))
const _63da8781 = () => interopDefault(import('../pages/rooms/index.vue' /* webpackChunkName: "pages/rooms/index" */))
const _52e89ee6 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _db514e32 = () => interopDefault(import('../pages/store.vue' /* webpackChunkName: "pages/store" */))
const _2901562a = () => interopDefault(import('../pages/store/index.vue' /* webpackChunkName: "pages/store/index" */))
const _325796e2 = () => interopDefault(import('../pages/store/countries/index.vue' /* webpackChunkName: "pages/store/countries/index" */))
const _8492b9a0 = () => interopDefault(import('../pages/store/feeds/index.vue' /* webpackChunkName: "pages/store/feeds/index" */))
const _794b2c06 = () => interopDefault(import('../pages/store/payment-methods/index.vue' /* webpackChunkName: "pages/store/payment-methods/index" */))
const _95813fcc = () => interopDefault(import('../pages/store/regions/index.vue' /* webpackChunkName: "pages/store/regions/index" */))
const _451b5e94 = () => interopDefault(import('../pages/store/shipping-categories/index.vue' /* webpackChunkName: "pages/store/shipping-categories/index" */))
const _2ff24e2e = () => interopDefault(import('../pages/store/shipping-methods/index.vue' /* webpackChunkName: "pages/store/shipping-methods/index" */))
const _5f07d8fc = () => interopDefault(import('../pages/store/stock-locations/index.vue' /* webpackChunkName: "pages/store/stock-locations/index" */))
const _f8a7014e = () => interopDefault(import('../pages/store/tax-categories/index.vue' /* webpackChunkName: "pages/store/tax-categories/index" */))
const _074ea88c = () => interopDefault(import('../pages/store/tax-rates/index.vue' /* webpackChunkName: "pages/store/tax-rates/index" */))
const _544aea28 = () => interopDefault(import('../pages/store/warranty-categories/index.vue' /* webpackChunkName: "pages/store/warranty-categories/index" */))
const _666c0430 = () => interopDefault(import('../pages/store/warranty-options/index.vue' /* webpackChunkName: "pages/store/warranty-options/index" */))
const _005c9f3c = () => interopDefault(import('../pages/store/zones/index.vue' /* webpackChunkName: "pages/store/zones/index" */))
const _638432de = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _9941f696 = () => interopDefault(import('../pages/bookings/new.vue' /* webpackChunkName: "pages/bookings/new" */))
const _6f5e9bd7 = () => interopDefault(import('../pages/forms/edit/index.vue' /* webpackChunkName: "pages/forms/edit/index" */))
const _9b3cc8ce = () => interopDefault(import('../pages/forms/messages/index.vue' /* webpackChunkName: "pages/forms/messages/index" */))
const _2813d574 = () => interopDefault(import('../pages/forms/new.vue' /* webpackChunkName: "pages/forms/new" */))
const _2a5a95a9 = () => interopDefault(import('../pages/newsletters/lists/index.vue' /* webpackChunkName: "pages/newsletters/lists/index" */))
const _183d88df = () => interopDefault(import('../pages/newsletters/subscribers/index.vue' /* webpackChunkName: "pages/newsletters/subscribers/index" */))
const _d500520c = () => interopDefault(import('../pages/orders/edit.vue' /* webpackChunkName: "pages/orders/edit" */))
const _6c69b9fd = () => interopDefault(import('../pages/orders/edit/index.vue' /* webpackChunkName: "pages/orders/edit/index" */))
const _78a8e330 = () => interopDefault(import('../pages/orders/edit/_id/index.vue' /* webpackChunkName: "pages/orders/edit/_id/index" */))
const _d6010cec = () => interopDefault(import('../pages/orders/edit/_id/adjustment/index.vue' /* webpackChunkName: "pages/orders/edit/_id/adjustment/index" */))
const _554b5cfd = () => interopDefault(import('../pages/orders/edit/_id/cart/index.vue' /* webpackChunkName: "pages/orders/edit/_id/cart/index" */))
const _0432db3b = () => interopDefault(import('../pages/orders/edit/_id/customer/index.vue' /* webpackChunkName: "pages/orders/edit/_id/customer/index" */))
const _65469ea6 = () => interopDefault(import('../pages/orders/edit/_id/history/index.vue' /* webpackChunkName: "pages/orders/edit/_id/history/index" */))
const _5f0024d7 = () => interopDefault(import('../pages/orders/edit/_id/notes.vue' /* webpackChunkName: "pages/orders/edit/_id/notes" */))
const _485750c2 = () => interopDefault(import('../pages/orders/edit/_id/payment/index.vue' /* webpackChunkName: "pages/orders/edit/_id/payment/index" */))
const _34c4d1f7 = () => interopDefault(import('../pages/orders/edit/_id/shipment/index.vue' /* webpackChunkName: "pages/orders/edit/_id/shipment/index" */))
const _49f81440 = () => interopDefault(import('../pages/orders/new.vue' /* webpackChunkName: "pages/orders/new" */))
const _00b89463 = () => interopDefault(import('../pages/orders/new/index.vue' /* webpackChunkName: "pages/orders/new/index" */))
const _08773b02 = () => interopDefault(import('../pages/orders/reports.vue' /* webpackChunkName: "pages/orders/reports" */))
const _3b7aa2d7 = () => interopDefault(import('../pages/products/collections/index.vue' /* webpackChunkName: "pages/products/collections/index" */))
const _129523db = () => interopDefault(import('../pages/products/edit.vue' /* webpackChunkName: "pages/products/edit" */))
const _449fcbc4 = () => interopDefault(import('../pages/products/edit/index.vue' /* webpackChunkName: "pages/products/edit/index" */))
const _6c92f609 = () => interopDefault(import('../pages/products/edit/_id/index.vue' /* webpackChunkName: "pages/products/edit/_id/index" */))
const _07e78c78 = () => interopDefault(import('../pages/products/edit/_id/notes.vue' /* webpackChunkName: "pages/products/edit/_id/notes" */))
const _070d27d4 = () => interopDefault(import('../pages/products/edit/_id/seo.vue' /* webpackChunkName: "pages/products/edit/_id/seo" */))
const _0bd48743 = () => interopDefault(import('../pages/products/edit/_id/stock-items/index.vue' /* webpackChunkName: "pages/products/edit/_id/stock-items/index" */))
const _73c4452c = () => interopDefault(import('../pages/products/edit/_id/variants/index.vue' /* webpackChunkName: "pages/products/edit/_id/variants/index" */))
const _0e51253d = () => interopDefault(import('../pages/products/filters/index.vue' /* webpackChunkName: "pages/products/filters/index" */))
const _20ddee42 = () => interopDefault(import('../pages/products/new.vue' /* webpackChunkName: "pages/products/new" */))
const _cdf5a7bc = () => interopDefault(import('../pages/products/new/index.vue' /* webpackChunkName: "pages/products/new/index" */))
const _e7731fb6 = () => interopDefault(import('../pages/products/prototypes/index.vue' /* webpackChunkName: "pages/products/prototypes/index" */))
const _197756c3 = () => interopDefault(import('../pages/products/taxonomies/index.vue' /* webpackChunkName: "pages/products/taxonomies/index" */))
const _4f2a31fd = () => interopDefault(import('../pages/products/taxons/index.vue' /* webpackChunkName: "pages/products/taxons/index" */))
const _6fdb1bb8 = () => interopDefault(import('../pages/promotions/categories/index.vue' /* webpackChunkName: "pages/promotions/categories/index" */))
const _0a5601e2 = () => interopDefault(import('../pages/promotions/edit.vue' /* webpackChunkName: "pages/promotions/edit" */))
const _0e23be52 = () => interopDefault(import('../pages/promotions/edit/index.vue' /* webpackChunkName: "pages/promotions/edit/index" */))
const _3571603d = () => interopDefault(import('../pages/promotions/edit/_id/index.vue' /* webpackChunkName: "pages/promotions/edit/_id/index" */))
const _2daee1f0 = () => interopDefault(import('../pages/promotions/edit/_id/seo.vue' /* webpackChunkName: "pages/promotions/edit/_id/seo" */))
const _11e211aa = () => interopDefault(import('../pages/promotions/new.vue' /* webpackChunkName: "pages/promotions/new" */))
const _222227ab = () => interopDefault(import('../pages/rooms/edit.vue' /* webpackChunkName: "pages/rooms/edit" */))
const _0581fc24 = () => interopDefault(import('../pages/rooms/edit/index.vue' /* webpackChunkName: "pages/rooms/edit/index" */))
const _506d144e = () => interopDefault(import('../pages/rooms/edit/_id/index.vue' /* webpackChunkName: "pages/rooms/edit/_id/index" */))
const _7e815ba4 = () => interopDefault(import('../pages/rooms/edit/_id/seo.vue' /* webpackChunkName: "pages/rooms/edit/_id/seo" */))
const _5b6c4b0f = () => interopDefault(import('../pages/rooms/new.vue' /* webpackChunkName: "pages/rooms/new" */))
const _89dbeb5c = () => interopDefault(import('../pages/rooms/new/index.vue' /* webpackChunkName: "pages/rooms/new/index" */))
const _2a479eda = () => interopDefault(import('../pages/forms/messages/edit.vue' /* webpackChunkName: "pages/forms/messages/edit" */))
const _160e8654 = () => interopDefault(import('../pages/forms/messages/edit/index.vue' /* webpackChunkName: "pages/forms/messages/edit/index" */))
const _d649067e = () => interopDefault(import('../pages/forms/messages/edit/_id/index.vue' /* webpackChunkName: "pages/forms/messages/edit/_id/index" */))
const _30301330 = () => interopDefault(import('../pages/forms/messages/edit/_id/notes.vue' /* webpackChunkName: "pages/forms/messages/edit/_id/notes" */))
const _31f36cd6 = () => interopDefault(import('../pages/products/collections/edit.vue' /* webpackChunkName: "pages/products/collections/edit" */))
const _e18ee750 = () => interopDefault(import('../pages/products/collections/edit/index.vue' /* webpackChunkName: "pages/products/collections/edit/index" */))
const _63c3bb43 = () => interopDefault(import('../pages/products/collections/edit/_id/index.vue' /* webpackChunkName: "pages/products/collections/edit/_id/index" */))
const _7841f38e = () => interopDefault(import('../pages/products/collections/edit/_id/seo.vue' /* webpackChunkName: "pages/products/collections/edit/_id/seo" */))
const _34317836 = () => interopDefault(import('../pages/products/collections/new.vue' /* webpackChunkName: "pages/products/collections/new" */))
const _edb1b06a = () => interopDefault(import('../pages/products/filters/new.vue' /* webpackChunkName: "pages/products/filters/new" */))
const _3b7be69a = () => interopDefault(import('../pages/products/prototypes/new.vue' /* webpackChunkName: "pages/products/prototypes/new" */))
const _34252fae = () => interopDefault(import('../pages/products/taxonomies/edit.vue' /* webpackChunkName: "pages/products/taxonomies/edit" */))
const _14eb6bec = () => interopDefault(import('../pages/products/taxonomies/edit/index.vue' /* webpackChunkName: "pages/products/taxonomies/edit/index" */))
const _0f14b0d7 = () => interopDefault(import('../pages/products/taxonomies/edit/_id/index.vue' /* webpackChunkName: "pages/products/taxonomies/edit/_id/index" */))
const _5cbb3e22 = () => interopDefault(import('../pages/products/taxonomies/edit/_id/seo.vue' /* webpackChunkName: "pages/products/taxonomies/edit/_id/seo" */))
const _273ebf98 = () => interopDefault(import('../pages/products/taxonomies/edit/_id/taxons.vue' /* webpackChunkName: "pages/products/taxonomies/edit/_id/taxons" */))
const _65cffa5e = () => interopDefault(import('../pages/products/taxonomies/new.vue' /* webpackChunkName: "pages/products/taxonomies/new" */))
const _19353daf = () => interopDefault(import('../pages/products/taxons/edit.vue' /* webpackChunkName: "pages/products/taxons/edit" */))
const _927af61c = () => interopDefault(import('../pages/products/taxons/edit/index.vue' /* webpackChunkName: "pages/products/taxons/edit/index" */))
const _4e0bd6dd = () => interopDefault(import('../pages/products/taxons/edit/_id/index.vue' /* webpackChunkName: "pages/products/taxons/edit/_id/index" */))
const _359fa4b0 = () => interopDefault(import('../pages/products/taxons/edit/_id/seo.vue' /* webpackChunkName: "pages/products/taxons/edit/_id/seo" */))
const _425c648b = () => interopDefault(import('../pages/products/taxons/new.vue' /* webpackChunkName: "pages/products/taxons/new" */))
const _045e609a = () => interopDefault(import('../pages/products/filters/edit/_id.vue' /* webpackChunkName: "pages/products/filters/edit/_id" */))
const _2f53669c = () => interopDefault(import('../pages/products/prototypes/edit/_id.vue' /* webpackChunkName: "pages/products/prototypes/edit/_id" */))
const _c403f420 = () => interopDefault(import('../pages/bookings/edit/_id.vue' /* webpackChunkName: "pages/bookings/edit/_id" */))
const _70bfa7ff = () => interopDefault(import('../pages/forms/edit/_id.vue' /* webpackChunkName: "pages/forms/edit/_id" */))
const _6fd06aa4 = () => interopDefault(import('../pages/products/_attribute.vue' /* webpackChunkName: "pages/products/_attribute" */))
const _643dd29e = () => interopDefault(import('../pages/products/_attribute/index.vue' /* webpackChunkName: "pages/products/_attribute/index" */))
const _99a63382 = () => interopDefault(import('../pages/products/_attribute/new.vue' /* webpackChunkName: "pages/products/_attribute/new" */))
const _54318aa6 = () => interopDefault(import('../pages/products/_attribute/edit/_id.vue' /* webpackChunkName: "pages/products/_attribute/edit/_id" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _d6fc104c = () => interopDefault(import('../node_modules/@evince/auth/lib/pages/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _171fda10 = () => interopDefault(import('../node_modules/@evince/auth/lib/pages/invitation.vue' /* webpackChunkName: "pages/auth/invitation" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _3974a0b3,
    name: "account"
  }, {
    path: "/admins",
    component: _af6b291e,
    name: "admins"
  }, {
    path: "/bookings",
    component: _ebf520b2,
    name: "bookings"
  }, {
    path: "/dashboard",
    component: _103edbcc,
    name: "dashboard"
  }, {
    path: "/forms",
    component: _0dcac010,
    name: "forms"
  }, {
    path: "/hotel",
    component: _1eb3803a,
    children: [{
      path: "",
      component: _3a09733d,
      name: "hotel"
    }]
  }, {
    path: "/newsletters",
    component: _7942c263,
    name: "newsletters"
  }, {
    path: "/orders",
    component: _5c32c212,
    name: "orders"
  }, {
    path: "/products",
    component: _fc71dd5e,
    name: "products"
  }, {
    path: "/promotions",
    component: _bcfac6c6,
    name: "promotions"
  }, {
    path: "/rooms",
    component: _63da8781,
    name: "rooms"
  }, {
    path: "/settings",
    component: _52e89ee6,
    name: "settings"
  }, {
    path: "/store",
    component: _db514e32,
    children: [{
      path: "",
      component: _2901562a,
      name: "store"
    }, {
      path: "countries",
      component: _325796e2,
      name: "store-countries"
    }, {
      path: "feeds",
      component: _8492b9a0,
      name: "store-feeds"
    }, {
      path: "payment-methods",
      component: _794b2c06,
      name: "store-payment-methods"
    }, {
      path: "regions",
      component: _95813fcc,
      name: "store-regions"
    }, {
      path: "shipping-categories",
      component: _451b5e94,
      name: "store-shipping-categories"
    }, {
      path: "shipping-methods",
      component: _2ff24e2e,
      name: "store-shipping-methods"
    }, {
      path: "stock-locations",
      component: _5f07d8fc,
      name: "store-stock-locations"
    }, {
      path: "tax-categories",
      component: _f8a7014e,
      name: "store-tax-categories"
    }, {
      path: "tax-rates",
      component: _074ea88c,
      name: "store-tax-rates"
    }, {
      path: "warranty-categories",
      component: _544aea28,
      name: "store-warranty-categories"
    }, {
      path: "warranty-options",
      component: _666c0430,
      name: "store-warranty-options"
    }, {
      path: "zones",
      component: _005c9f3c,
      name: "store-zones"
    }]
  }, {
    path: "/users",
    component: _638432de,
    name: "users"
  }, {
    path: "/bookings/new",
    component: _9941f696,
    name: "bookings-new"
  }, {
    path: "/forms/edit",
    component: _6f5e9bd7,
    name: "forms-edit"
  }, {
    path: "/forms/messages",
    component: _9b3cc8ce,
    name: "forms-messages"
  }, {
    path: "/forms/new",
    component: _2813d574,
    name: "forms-new"
  }, {
    path: "/newsletters/lists",
    component: _2a5a95a9,
    name: "newsletters-lists"
  }, {
    path: "/newsletters/subscribers",
    component: _183d88df,
    name: "newsletters-subscribers"
  }, {
    path: "/orders/edit",
    component: _d500520c,
    children: [{
      path: "",
      component: _6c69b9fd,
      name: "orders-edit"
    }, {
      path: ":id",
      component: _78a8e330,
      name: "orders-edit-id"
    }, {
      path: ":id/adjustment",
      component: _d6010cec,
      name: "orders-edit-id-adjustment"
    }, {
      path: ":id/cart",
      component: _554b5cfd,
      name: "orders-edit-id-cart"
    }, {
      path: ":id/customer",
      component: _0432db3b,
      name: "orders-edit-id-customer"
    }, {
      path: ":id/history",
      component: _65469ea6,
      name: "orders-edit-id-history"
    }, {
      path: ":id/notes",
      component: _5f0024d7,
      name: "orders-edit-id-notes"
    }, {
      path: ":id/payment",
      component: _485750c2,
      name: "orders-edit-id-payment"
    }, {
      path: ":id/shipment",
      component: _34c4d1f7,
      name: "orders-edit-id-shipment"
    }]
  }, {
    path: "/orders/new",
    component: _49f81440,
    children: [{
      path: "",
      component: _00b89463,
      name: "orders-new"
    }]
  }, {
    path: "/orders/reports",
    component: _08773b02,
    name: "orders-reports"
  }, {
    path: "/products/collections",
    component: _3b7aa2d7,
    name: "products-collections"
  }, {
    path: "/products/edit",
    component: _129523db,
    children: [{
      path: "",
      component: _449fcbc4,
      name: "products-edit"
    }, {
      path: ":id",
      component: _6c92f609,
      name: "products-edit-id"
    }, {
      path: ":id/notes",
      component: _07e78c78,
      name: "products-edit-id-notes"
    }, {
      path: ":id/seo",
      component: _070d27d4,
      name: "products-edit-id-seo"
    }, {
      path: ":id/stock-items",
      component: _0bd48743,
      name: "products-edit-id-stock-items"
    }, {
      path: ":id/variants",
      component: _73c4452c,
      name: "products-edit-id-variants"
    }]
  }, {
    path: "/products/filters",
    component: _0e51253d,
    name: "products-filters"
  }, {
    path: "/products/new",
    component: _20ddee42,
    children: [{
      path: "",
      component: _cdf5a7bc,
      name: "products-new"
    }]
  }, {
    path: "/products/prototypes",
    component: _e7731fb6,
    name: "products-prototypes"
  }, {
    path: "/products/taxonomies",
    component: _197756c3,
    name: "products-taxonomies"
  }, {
    path: "/products/taxons",
    component: _4f2a31fd,
    name: "products-taxons"
  }, {
    path: "/promotions/categories",
    component: _6fdb1bb8,
    name: "promotions-categories"
  }, {
    path: "/promotions/edit",
    component: _0a5601e2,
    children: [{
      path: "",
      component: _0e23be52,
      name: "promotions-edit"
    }, {
      path: ":id",
      component: _3571603d,
      name: "promotions-edit-id"
    }, {
      path: ":id/seo",
      component: _2daee1f0,
      name: "promotions-edit-id-seo"
    }]
  }, {
    path: "/promotions/new",
    component: _11e211aa,
    name: "promotions-new"
  }, {
    path: "/rooms/edit",
    component: _222227ab,
    children: [{
      path: "",
      component: _0581fc24,
      name: "rooms-edit"
    }, {
      path: ":id",
      component: _506d144e,
      name: "rooms-edit-id"
    }, {
      path: ":id/seo",
      component: _7e815ba4,
      name: "rooms-edit-id-seo"
    }]
  }, {
    path: "/rooms/new",
    component: _5b6c4b0f,
    children: [{
      path: "",
      component: _89dbeb5c,
      name: "rooms-new"
    }]
  }, {
    path: "/forms/messages/edit",
    component: _2a479eda,
    children: [{
      path: "",
      component: _160e8654,
      name: "forms-messages-edit"
    }, {
      path: ":id",
      component: _d649067e,
      name: "forms-messages-edit-id"
    }, {
      path: ":id/notes",
      component: _30301330,
      name: "forms-messages-edit-id-notes"
    }]
  }, {
    path: "/products/collections/edit",
    component: _31f36cd6,
    children: [{
      path: "",
      component: _e18ee750,
      name: "products-collections-edit"
    }, {
      path: ":id",
      component: _63c3bb43,
      name: "products-collections-edit-id"
    }, {
      path: ":id/seo",
      component: _7841f38e,
      name: "products-collections-edit-id-seo"
    }]
  }, {
    path: "/products/collections/new",
    component: _34317836,
    name: "products-collections-new"
  }, {
    path: "/products/filters/new",
    component: _edb1b06a,
    name: "products-filters-new"
  }, {
    path: "/products/prototypes/new",
    component: _3b7be69a,
    name: "products-prototypes-new"
  }, {
    path: "/products/taxonomies/edit",
    component: _34252fae,
    children: [{
      path: "",
      component: _14eb6bec,
      name: "products-taxonomies-edit"
    }, {
      path: ":id",
      component: _0f14b0d7,
      name: "products-taxonomies-edit-id"
    }, {
      path: ":id/seo",
      component: _5cbb3e22,
      name: "products-taxonomies-edit-id-seo"
    }, {
      path: ":id/taxons",
      component: _273ebf98,
      name: "products-taxonomies-edit-id-taxons"
    }]
  }, {
    path: "/products/taxonomies/new",
    component: _65cffa5e,
    name: "products-taxonomies-new"
  }, {
    path: "/products/taxons/edit",
    component: _19353daf,
    children: [{
      path: "",
      component: _927af61c,
      name: "products-taxons-edit"
    }, {
      path: ":id",
      component: _4e0bd6dd,
      name: "products-taxons-edit-id"
    }, {
      path: ":id/seo",
      component: _359fa4b0,
      name: "products-taxons-edit-id-seo"
    }]
  }, {
    path: "/products/taxons/new",
    component: _425c648b,
    name: "products-taxons-new"
  }, {
    path: "/products/filters/edit/:id?",
    component: _045e609a,
    name: "products-filters-edit-id"
  }, {
    path: "/products/prototypes/edit/:id?",
    component: _2f53669c,
    name: "products-prototypes-edit-id"
  }, {
    path: "/bookings/edit/:id?",
    component: _c403f420,
    name: "bookings-edit-id"
  }, {
    path: "/forms/edit/:id",
    component: _70bfa7ff,
    name: "forms-edit-id"
  }, {
    path: "/products/:attribute",
    component: _6fd06aa4,
    children: [{
      path: "",
      component: _643dd29e,
      name: "products-attribute"
    }, {
      path: "new",
      component: _99a63382,
      name: "products-attribute-new"
    }, {
      path: "edit/:id?",
      component: _54318aa6,
      name: "products-attribute-edit-id"
    }]
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }, {
    path: "/auth/login",
    component: _d6fc104c,
    name: "auth-login"
  }, {
    path: "/auth/invitation",
    component: _171fda10,
    name: "auth-invitation"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
