
import Draggable from 'vuedraggable'

export default {
  name: 'NestedDraggable',
  inheritAttrs: false,
  components: {
    Draggable
  },
  props: {
    nested: {
      type: Boolean,
      default: false
    },
    deep: {
      type: Boolean,
      default: true
    },
    parent: {
      type: Object,
      default: null
    },
    sortable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      required: true
    },
    primaryKey: {
      type: String,
      default: 'id'
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    sortKey: {
      type: String,
      default: 'sortOrder'
    },
    nestedKey: {
      type: String,
      default: 'children'
    },
    removeKey: {
      type: String,
      default: '_destroy'
    },
    tag: {
      type: String,
      default: 'div'
    }
  },
  vessel: {
    area: {
      $nested: {
        negativeMarginLeft(value) {
          return value ? null : 'lg'
        }
      }
    },
    wrapper: {
      paddingLeft: 'lg'
    },
    item: {
      paddingY: 'xs',
      paddingX: 'sm',
      negativeMarginBottom: 1,
      borderWidth: 1,
      fontSize: 'sm',
      lineHeight: 'xl',
      $disabled: {
        backgroundColor: val => val ? 'grey-lightest' : 'white',
        color: val => val && 'grey'
      },
      hover: {
        backgroundColor: 'grey-lightest'
      }
    }
  },
  watch: {
    data(newValue, oldValue) {
      const newLength = newValue && newValue.length
      const oldLength = oldValue && oldValue.length

      if (newLength != oldLength) {
        this.updateOrder()
      }
    }
  },
  computed: {
    group() {
      return { name: 'group' }
    }
  },
  methods: {
    onEnd(...args) {
      this.updateOrder()
      this.$emit('end', ...args)
    },
    onAdd(...args) {
      this.updateOrder()
      this.$emit('add', ...args)
    },
    updateOrder() {
      if (this.sortable) {
        this.data.forEach((item, index) => {
          this.$set(item, this.sortKey, index)
        })
      }
    }
  }
}
