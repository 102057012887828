
import { isEqual } from 'vessel/utils'

export default {
  name: 'FlexibleWeightRateShipping',
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.settings = this.parseValue(newValue)
      }
    },
    settings: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      }
    }
  },
  data() {
    return {
      settings: this.parseValue(this.value)
    }
  },
  computed: {
    items() {
      return [
        {
          key: 'baseWeight',
          input: 'input-number',
          label: 'Base Weight',
          attrs: {
            label: 'kg',
            precision: 3
          }
        },
        {
          key: 'baseAmount',
          input: 'input-money',
          label: 'Base Cost'
        },
        {
          key: 'kiloAmount',
          input: 'input-money',
          label: 'Cost per KG'
        }
      ]
    }
  },
  methods: {
    parseValue(value) {
      return { baseWeight: 0, baseAmount: 0, kiloAmount: 0, ...value }
    }
  }
}
