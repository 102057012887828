
export default {
  name: 'FormActions',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: 'submit'
    },
    buttons: {
      type: Array,
      default: null
    },
    disabledButtons: {
      type: Array,
      default: null
    }
  },
  methods: {
    showButton(button) {
      const buttons = this.buttons || ['cancel', 'reset', 'save']
      return buttons.includes(button)
    },
    disableButton(button) {
      const buttons = this.disabledButtons || []
      return buttons.includes(button)
    },
    onCancel() {
      this.$emit('cancel')
    },
    onReset() {
      this.$emit('reset')
    },
    onSubmit() {
      this.$emit('submit')
    }
  }
}
