
import Input from '@/mixins/input'
import { isEqual } from 'vessel/utils'

export default {
  name: 'PromotionRulesInput',
  mixins: [Input],
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    input: {
      overflow: 'hidden',
      $disabled: {
        backgroundColor: val => val && 'grey-lightest',
        color: val => val && 'grey'
      }
    },
    empty: {
      $extend: 'text',
      paddingY: 'xs',
      paddingX: 'sm',
      borderBottomWidth: 1,
      borderColor: 'transparent',
      fontSize: 'sm',
      lineHeight: 'xl'
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.preventUpdate = true

        const value = this.parseValue(newValue)

        this.rules       = value.rules
        this.matchPolicy = value.matchPolicy
      }

      this.preventUpdate = false
    },
    matchPolicy(value) {
      if (!this.preventUpdate) {
        this.$emit('input', { matchPolicy: value, rules: this.rules })
      }
    },
    rules: {
      deep: true,
      handler(value) {
        if (!this.preventUpdate) {
          this.$emit('input', { matchPolicy: this.matchPolicy, rules: value })
        }
      }
    }
  },
  data() {
    return {
      preventUpdate: false,
      matchOptions: [
        { label: 'All rules', value: 'all' },
        { label: 'Any rule', value: 'any' }
      ],
      typeOptions: [
        { label: 'Products', value: 'product' },
        { label: 'Taxons', value: 'taxon' },
        { label: 'Countries', value: 'country' },
        { label: 'Regions', value: 'region' },
        { label: 'Zones', value: 'zone' },
        { label: 'Order', value: 'order' }
      ],
      ...this.parseValue(this.value)
    }
  },
  computed: {
    activeRules() {
      return this.rules.filter(item => !item._destroy)
    }
  },
  methods: {
    parseValue(value) {
      return { rules: [], matchPolicy: 'any', ...value }
    },
    parseTag(type) {
      return `input-promotions-${type}-rule`
    },
    addRule(type) {
      this.rules.push({ type, options: {} })
    },
    onRemove(index) {
      const item = this.rules[index] || {}

      if (item.id) {
        this.$set(item, '_destroy', true)
      } else {
        this.rules.splice(index, 1)
      }
    }
  }
}
