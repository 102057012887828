
import { isEqual } from 'vessel/utils'

export default {
  name: 'PromotionRegionRule',
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.selected = newValue && newValue.options.regionIds
      }
    },
    selected(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit('input', {
          ...this.value,
          type: 'region',
          options: { regionIds: newValue }
        })
      }
    }
  },
  data() {
    return {
      selected: this.value && this.value.options.regionIds
    }
  }
}
