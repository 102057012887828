export default {
  options: {
    columns: 4
  },
  translates: [
    'name'
  ],
  inputs: {
    name: {
      span: 4,
      required: true
    },
    internalName: {
      span: 2,
      required: true
    },
    code: {
      span: 2
    },
    trackingUrl: {
      span: 4
    },
    displayOn: {
      $input: 'enum',
      span: 4,
      $el: {
        enumKey: 'displayOnEnum'
      }
    },
    taxCategoryId: {
      $input: 'relation-select',
      span: 4,
      $el: {
        path: 'eshop/tax-category',
        textKey: 'id'
      }
    },
    zoneIds: {
      $input: 'relation-select',
      span: 4,
      $el: {
        path: 'eshop/zone',
        multiple: true,
        textKey: 'id'
      }
    },
    shippingCategoryIds: {
      $input: 'relation-select',
      span: 4,
      required: true,
      $el: {
        path: 'eshop/shipping-category',
        multiple: true,
        textKey: 'id'
      }
    },
    minDeliveryDays: {
      $input: 'number',
      label: 'minDelivery',
      span: 2,
      $el: {
        label: 'MIN',
        precision: 0
      }
    },
    maxDeliveryDays: {
      $input: 'number',
      label: 'maxDelivery',
      span: 2,
      $el: {
        label: 'MAX',
        precision: 0
      }
    },
    calculatorAttributes: {
      $input: 'shipping-calculator',
      label: 'costCalculator',
      span: 4,
      $el: {
        dataKey: 'calculator'
      }
    }
  }
}
