
import NumberValue from './NumberValue'

export default {
  name: 'AttributeLengthValue',
  extends: NumberValue,
  data() {
    return {
      numberLabel: 'm'
    }
  }
}
