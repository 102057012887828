export default {
  countries: {
    route: 'countries',
    icon: 'globe'
  },
  regions: {
    route: 'regions',
    icon: 'sign-alt'
  },
  zones: {
    route: 'zones',
    icon: 'map-pin-alt'
  },
  stockLocations: {
    route: 'stock-locations',
    icon: 'box'
  },
  taxCategories: {
    route: 'tax-categories',
    icon: 'file-bookmark-alt'
  },
  taxRates: {
    route: 'tax-rates',
    icon: 'percentage'
  },
  shippingCategories: {
    route: 'shipping-categories',
    icon: 'file-upload-alt'
  },
  shippingMethods: {
    route: 'shipping-methods',
    icon: 'truck'
  },
  paymentMethods: {
    route: 'payment-methods',
    icon: 'transaction'
  },
  warrantyCategories: {
    route: 'warranty-categories',
    icon: 'file-shield-alt'
  },
  warrantyOptions: {
    route: 'warranty-options',
    icon: 'shield-check'
  },
  dataFeeds: {
    route: 'feeds',
    icon: 'rss'
  }
}
