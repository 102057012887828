
import { SnippetPreview } from 'vue-yoast'
import { rateTitleLength, rateMetaDescLength } from '../utils.js'

export default {
  name: 'SnippetPreview',
  inheritAttrs: false,
  extends: SnippetPreview,
  props: {
    background: {
      type: Boolean,
      default: true
    },
    outline: {
      type: Boolean,
      default: true
    },
    spacing: {
      type: String,
      default: 'sm'
    }
  },
  vessel: {
    wrapper: {
      $attrs: 'all',
      flexGrow: 'true',
      $spacing: 'padding',
      $background: {
        backgroundColor: val => val ? 'white' : null
      },
      $outline: {
        borderWidth: val => val ? 1 : null
      }
    }
  },
  watch: {
    title() {
      this.$emit('update:titleWidth', this.offsetWidth)
      this.$emit('update:titleLengthPercent', this.titleLengthPercent)
    },
    description() {
      this.$emit('update:descriptionLengthPercent', this.descriptionLengthPercent)
    }
  },
  computed: {
    offsetWidth() {
      return this.$refs.title.offsetWidth
    },
    descriptionLength() {
      return (this.description && this.description.length) || 0
    },
    titleLengthPercent() {
      return (this.offsetWidth / this.maxTitleWidth) * 100
    },
    descriptionLengthPercent() {
      return (this.descriptionLength / this.maxDescriptionLength) * 100
    },
    snippetClass() {
      return {
        'snippet-preview': true,
        'mobile-snippet': this.previewMode == 'mobile'
      }
    },
    urlText() {
      return `${this.baseUrl}${this.url}`
    },
    descriptionText() {
      const desc = this.description || 'No description available'
      const text = desc.substr(0, this.maxDescriptionLength)
      const more = text.length > this.maxDescriptionLength ? '...' : ''

      return `${text}${more}`
    }
  },
  methods: {
    titleRate() {
      return rateTitleLength(this.offsetWidth)
    },
    descriptionRate() {
      return rateMetaDescLength(this.descriptionLength)
    },
    onItemClick(field) {
      this.$emit('activate', field)
    }
  }
}
