
export default {
  name: 'ActionBar',
  inheritAttrs: false,
  props: {
    position: {
      type: String,
      default: 'top'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    actionBar: {
      padding: 'xs',
      fontSize: 'xs',
      $position: {
        borderBottomWidth: val => val == 'top' ? 1 : null,
        borderTopWidth: val => val == 'bottom' ? 1 : null
      },
      $disabled: {
        textColor: val => val ? 'grey' : 'grey-darker'
      }
    }
  },
  computed: {
    classNames() {
      return {
        'action-bar': true,
        'is-disabled': this.disabled
      }
    }
  }
}
