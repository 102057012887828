
import { EditorContent } from '@tiptap/vue-2'

export default {
  name: 'EditorContent',
  components: {
    EditorEditable: EditorContent
  },
  props: {
    editor: {
      type: [Object, Function],
      required: true
    }
  },
  vessel: {
    content: {
      padding: 'sm',
      lineHeight: 'md'
    }
  }
}
