import { render, staticRenderFns } from "./PriceAdjustmentAction.vue?vue&type=template&id=052e419c&"
import script from "./PriceAdjustmentAction.vue?vue&type=script&lang=js&"
export * from "./PriceAdjustmentAction.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputCalculator: require('/opt/build/repo/inputs/Calculator.vue').default,ActionSection: require('/opt/build/repo/components/ActionSection.vue').default})
