
import { select, isPlainObject } from 'vessel/utils'

export default {
  name: 'OrderSummary',
  props: {
    order: {
      type: Object,
      required: true
    },
    cartItems: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    label: {
      marginBottom: 'xs'
    }
  },
  computed: {
    currency() {
      return Object(this.order.total).currency || 'EUR'
    },
    cartSubtotal() {
      return this.cartSum('price')
    },
    cartTax() {
      return this.cartSum('tax')
    },
    cartShipping() {
      return Object(this.order.shipping).value || 0
    },
    cartTotal() {
      return this.cartSubtotal + this.cartTax + this.cartShipping
    },
    data() {
      const computed = {}

      if (this.cartItems) {
        computed.subtotal = this.formatMoney(this.cartSubtotal)
        computed.tax      = this.formatMoney(this.cartTax)
        computed.total    = this.formatMoney(this.cartTotal)
      }

      return { ...this.order, ...computed }
    },
    record() {
      const defaults = {
        number: 'Not Available',
        createdAt: 'Not Available',
        state: 'none',
        shippingState: 'none',
        paymentState: 'none',
        subtotal: '€0,00',
        tax: '€0,00',
        shipping: '€0,00',
        total: '€0,00'
      }

      const props = Object.keys(defaults)
      const order = select(this.data, (key, val) => {
        return props.includes(key) && this.formatted(val) != null
      })

      return { ...defaults, ...order }
    }
  },
  methods: {
    formatMoney(amount) {
      const locale   = this.$activeProperty.defaultLocale
      const currency = this.currency

      return amount.toLocaleString(locale, { style: 'currency', currency })
    },
    formatted(value) {
      if (isPlainObject(value)) {
        return value.formatted
      } else {
        return value
      }
    },
    cartSum(attr) {
      const items = this.cartItems.filter(item => !item._destroy)

      return items.reduce((res, item) => {
        const amount   = item[attr] && item[attr].value || 0
        const quantity = item.quantity

        return res + (amount * quantity)
      }, 0)
    }
  }
}
