
import Input from '@/mixins/input'
import { isEqual, toArray, reduce } from 'vessel/utils'

export default {
  mixins: [Input],
  inheritAttrs: false,
  props: {
    path: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Array],
      default: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.selected = this.parseValue(newValue)
        }
      }
    }
  },
  data() {
    return {
      options: [],
      selected: null,
      loading: false
    }
  },
  methods: {
    async fetchData() {
      if (!this.path) return

      this.loading = true

      const result = await this.$content(this.path).fetch()
      this.options = reduce(result, (data, value, label) => {
        data.push({ value, label })
      }, [])

      this.loading = false
    },
    parseValue(value) {
      return this.multiple ? toArray(value) : value
    },
    onSelect(value) {
      this.$emit('input', this.parseValue(value))
    }
  },
  mounted() {
    this.$nextTick(this.fetchData)
  }
}
