
import Column from '@/mixins/column'

export default {
  name: 'NumberColumn',
  mixins: [Column],
  computed: {
    tagType() {
      return this.value > 0 ? 'success' : 'danger'
    }
  }
}
