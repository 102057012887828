import Vue from 'vue'
import { isPlainObject } from 'vessel/utils'

export default ({ app }) => {
  Vue.prototype.$editRecordPath = function(model, resource, prefix = '') {
    const path = isPlainObject(resource)
      ? `${prefix}${model}/edit/${resource.id}`
      : `${prefix}${model}/edit/${resource}`

    return path.replace(/\/{2,}/g, '/')
  }

  Vue.prototype.$date = function() {
    const today  = new Date()
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }

    return today.toLocaleString(app.i18n.locale, options)
  }

  Vue.prototype.$activeForm = Vue.observable({
    form: null,
    activate(form) {
      this.form = form
    },
    clear() {
      this.form = null
    },
    setLocale(locale, callback) {
      if (this.form) {
        this.form.setLocale(locale, callback)
      }
    },
    get locale() {
      return this.form && this.form.locale
    },
    get localized() {
      return this.form && this.form.isLocalized
    },
    get translateable() {
      return this.form && this.form.translates
    }
  })
}
