export default {
  options: {
    columns: 4
  },
  inputs: {
    name: {
      span: 4,
      required: true
    }
  }
}
