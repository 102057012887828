
import { isEqual } from 'vessel/utils'

export default {
  name: 'AttributeTextValue',
  props: {
    item: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    translating: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    item: {
      immediate: true,
      deep: true,
      handler(value) {
        this.data = value
      }
    },
    data: {
      deep: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.$emit('update', newValue)
        }
      }
    }
  },
  data() {
    return {
      data: {
        value: null,
        internalValue: null
      }
    }
  }
}
