export default {
  options: {
    columns: 4
  },
  inputs: {
    name: {
      span: 4,
      required: true
    },
    taxCategoryId: {
      $input: 'relation-select',
      span: 4,
      required: true,
      $el: {
        path: 'eshop/tax-category',
        textKey: 'id'
      }
    },
    amount: {
      $input: 'percentage',
      span: 4
    },
    zoneId: {
      $input: 'relation-select',
      span: 4,
      required: true,
      $el: {
        path: 'eshop/zone',
        textKey: 'id'
      }
    },
    includedInPrice: {
      $input: 'switch',
      span: 4
    }
  }
}
