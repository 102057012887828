import { dig } from 'vessel/utils'

export default {
  inheritAttrs: false,
  inject: {
    indexPage: {
      default: {}
    }
  },
  props: {
    defaultValue: {
      type: [String, Number, Boolean],
      default: null
    },
    prop: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    rowKey: {
      type: String,
      default: null
    },
    rowValue: {
      type: Object,
      default: null
    }
  },
  computed: {
    row() {
      if (this.rowKey) {
        return this.rowValue[this.rowKey]
      } else {
        return this.rowValue
      }
    },
    value() {
      const value = dig(this.row, this.prop)
      return value == null ? this.defaultValue : value
    }
  }
}
