
import { nanoid } from 'nanoid'

export default {
  name: 'ModelDialog',
  inheritAttrs: false,
  props: {
    model: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'Edit'
    },
    path: {
      type: String,
      required: true
    },
    schema: {
      type: String,
      default: 'Form'
    },
    columns: {
      type: [Number, String],
      default: 1
    },
    buttonText: {
      type: String,
      default: 'save'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      formKey: nanoid()
    }
  },
  methods: {
    open() {
      this.formKey = nanoid()
      this.visible = true
    },
    close() {
      this.visible = false
    },
    onResetForm() {
      this.$refs.form.resetForm()
      this.$emit('reset')
    },
    onSubmitForm() {
      this.$refs.form.submitForm()
      this.$emit('submit')
    },
    onValidForm(value) {
      this.$emit('valid', value)
    },
    onInvalidForm(value) {
      this.$emit('invalid', value)
    }
  }
}
