import { render, staticRenderFns } from "./Layout.vue?vue&type=template&id=06d3739a&"
import script from "./Layout.vue?vue&type=script&lang=js&"
export * from "./Layout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainSidebar: require('/opt/build/repo/components/MainSidebar.vue').default,TopBar: require('/opt/build/repo/components/TopBar.vue').default,BottomBar: require('/opt/build/repo/components/BottomBar.vue').default})
