
export default {
  name: 'BaseInput',
  functional: true,
  props: {
    tag: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'textarea'
    }
  },
  render(h, { slots, data, props }) {
    const merge = (value) => {
      const classes = {
        'el-textarea__inner': props.type == 'textarea',
        'el-input__inner': props.type == 'input'
      }

      if (Array.isArray(value))
        value = [...value, ...Object.keys(classes)]
      else
        value = { ...value, ...classes }

      return value
    }

    const items = Object(slots()).default

    if (items && items.length) {
      const component = items[0]

      if (props.tag) {
        const compData = data || {}

        compData.class = merge({
          'custom-input-wrapper': true,
          ...compData.class
        })

        return h(props.tag, compData, [component])
      } else {
        component.data = component.data || {}

        component.data.class = merge({
          'custom-input': true,
          ...component.data.class
        })

        return component
      }
    }

    return null
  }
}
