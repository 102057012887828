
import Column from '@/mixins/column'

export default {
  name: 'ListColumn',
  mixins: [Column],
  props: {
    textProp: {
      type: String,
      default: 'name'
    }
  },
  computed: {
    visibleItems() {
      return this.value.slice(0, 1)
    },
    remaining() {
      return this.value.slice(0, -1).length
    }
  }
}
