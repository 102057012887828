const state = () => ({
  data: { email: null }
})

const mutations = {
  UPDATE_USER (state, data) {
    state.data = { ...state.data, ...data }
  },

  RESET_USER (state) {
    state.data = { email: null }
  }
}

const getters = {
  data(state) {
    return state.data
  }
}

const actions = {
  updateUser({ commit }, data) {
    commit('UPDATE_USER', data)
  },

  resetUser({ commit }) {
    commit('RESET_USER')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
