export default {
  create() {
    return {
      route: 'new',
      label: 'details',
      icon: 'clipboard-alt',
      visible: this.newPage
    }
  },
  update() {
    return {
      route: `edit/${this.$route.params.id}`,
      label: 'details',
      icon: 'clipboard-alt',
      visible: this.editPage
    }
  },
  seo() {
    return {
      route: `edit/${this.$route.params.id}/seo`,
      icon: 'search-alt',
      disabled: this.newPage
    }
  }
}
