export default {
  options: {
    columns: 8
  },
  translates: [
    'name'
  ],
  inputs: {
    name: {
      span: 4,
      required: true
    },
    internalName: {
      span: 4,
      required: true
    },
    kind: {
      $input: 'enum',
      span: 4,
      $updateValue: [
        {
          id: 'options',
          value: {}
        }
      ],
      $el: {
        enumKey: 'kindEnum'
      }
    },
    displayAs: {
      $input: 'filter-display',
      span: 2,
      $updateValue: [
        {
          id: 'options',
          value: {}
        }
      ]
    },
    position: {
      $input: 'enum',
      span: 2,
      $el: {
        enumKey: 'positionEnum'
      }
    },
    options: {
      $type: 'group',
      $columns: 4,
      $inputs: {
        indexAttribute: {
          $input: 'filter-attribute',
          span: 2
        },
        hideDisabled: {
          $input: 'switch',
          span: 1
        },
        showCounters: {
          $input: 'switch',
          span: 1
        }
      }
    },
    productTaxonomyIds: {
      $input: 'relation-select',
      label: 'taxonomies',
      span: 8,
      $el: {
        path: 'eshop/taxonomy',
        fragment: 'RelatedTaxonomy',
        variables: {
          where: { kind_not_in: ['category', 'brand'] }
        },
        multiple: true
      },
      $enableWhen: { kind: 'TAXONOMY' }
    },
    productPropertyIds: {
      $input: 'relation-select',
      label: 'properties',
      span: 8,
      $el: {
        path: 'eshop/attribute',
        fragment: 'RelatedAttribute',
        variables: {
          where: { type: 'property' }
        },
        multiple: true
      },
      $enableWhen: { kind: 'PROPERTY' }
    },
    productOptionIds: {
      $input: 'relation-select',
      label: 'options',
      span: 8,
      $el: {
        path: 'eshop/attribute',
        fragment: 'RelatedAttribute',
        variables: {
          where: { type: 'option' }
        },
        multiple: true
      },
      $enableWhen: { kind: 'OPTION' }
    },
    selfTaxonIds: {
      $input: 'relation-select',
      label: 'showInCategories',
      span: 8,
      $el: {
        path: 'eshop/taxon',
        fragment: 'RelatedTaxon',
        variables: {
          where: { parent_is_null: false, taxonomy: { kind: 'category' } }
        },
        multiple: true
      }
    }
  }
}
