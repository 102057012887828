export default {
  options: {
    columns: 4
  },
  inputs: {
    email: {
      type: 'email',
      span: 4,
      required: true
    },
    password: {
      type: 'password',
      span: 4
    },
    passwordConfirmation: {
      type: 'password',
      span: 4
    },
    active() {
      return {
        $input: 'switch',
        span: 4
      }
    }
  }
}
