
import { isEqual } from 'vessel/utils'

export default {
  name: 'PriceSackShipping',
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.settings = this.parseValue(newValue)
      }
    },
    settings: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      }
    }
  },
  data() {
    return {
      settings: this.parseValue(this.value)
    }
  },
  computed: {
    items() {
      return [
        {
          key: 'minimal',
          label: 'Minimal Amount'
        },
        {
          key: 'normal',
          label: 'Normal Amount'
        },
        {
          key: 'discount',
          label: 'Discount Amount'
        }
      ]
    }
  },
  methods: {
    parseValue(value) {
      return { minimal: 0, normal: 0, discount: 0, ...value }
    }
  }
}
