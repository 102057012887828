
import { isEqual } from 'vessel/utils'

export default {
  name: 'PromotionZoneRule',
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.selected = newValue && newValue.options.zoneIds
      }
    },
    selected(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit('input', {
          ...this.value,
          type: 'zone',
          options: { zoneIds: newValue }
        })
      }
    }
  },
  data() {
    return {
      selected: this.value && this.value.options.zoneIds
    }
  }
}
