export default {
  update() {
    return {
      route: `edit/${this.$route.params.id}`,
      label: 'details',
      icon: 'clipboard-alt',
      visible: this.editPage
    }
  },
  notes() {
    return {
      route: `edit/${this.$route.params.id}/notes`,
      icon: 'notes',
      disabled: this.newPage
    }
  }
}
