
import Column from '@/mixins/column'

export default {
  mixins: [Column],
  props: {
    parseUrl: {
      type: Function,
      required: true
    }
  }
}
