
export default {
  name: 'TenantMenu',
  vessel: {
    label: {
      color: 'grey-darker'
    },
    selected: {
      fontWeight: 'bold',
      $active: {
        textColor(value) {
          return value ? 'grey-darkest' : 'grey-dark'
        }
      }
    },
    link: {
      outline: 'none'
    }
  },
  computed: {
    active() {
      return this.$activeProperty.tenant
    },
    activeName() {
      return this.$activeProperty.name || 'Select Property'
    },
    activeUrl() {
      return this.$activeProperty.url
    },
    hasProperties() {
      return this.properties.length > 0
    },
    showList() {
      return this.properties.length > 1
    },
    properties() {
      return this.$user.data.properties || []
    }
  }
}
