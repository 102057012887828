
import Column from '@/mixins/column'

export default {
  name: 'StateColumn',
  mixins: [Column],
  props: {
    kind: {
      type: String,
      default: 'order'
    }
  }
}
