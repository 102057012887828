export default {
  options: {
    columns: 4
  },
  inputs: {
    name: {
      span: 4,
      required: true
    },
    minDeliveryDays: {
      $input: 'number',
      label: 'minDelivery',
      span: 2,
      $el: {
        label: 'MIN',
        precision: 0
      }
    },
    maxDeliveryDays: {
      $input: 'number',
      label: 'maxDelivery',
      span: 2,
      $el: {
        label: 'MAX',
        precision: 0
      }
    }
  }
}
