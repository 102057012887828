const form = {
  options: {
    columns: 1
  },
  translates: [
    'name',
    'description'
  ]
}

const inputs = {
  name: {
    span: 1
  },
  description: {
    $input: 'text-editor',
    span: 1,
    $el: {
      minimal: true
    }
  }
}

const metadata = {
  $input: 'upload-meta',
  label: false,
  span: 1
}

const featured = {
  $input: 'switch',
  span: 1
}

const Media = {
  ...form,
  inputs: {
    metadata,
    ...inputs,
    featured
  }
}

const MediaSingle = {
  ...form,
  inputs: {
    metadata,
    ...inputs
  }
}

const File = {
  ...form,
  inputs: {
    ...inputs,
    featured
  }
}

const FileSingle = {
  ...form,
  inputs
}

export default {
  Media,
  MediaSingle,
  File,
  FileSingle
}
