
import Editor         from '../editor'
import EditorToolbar  from './toolbar'
import EditorContent  from './content'
import EditorAssessor from './content-assessor'

import { isString, debounce } from 'vessel/utils'

export default {
  name: 'Editor',
  inheritAttrs: false,
  components: {
    EditorContent,
    EditorToolbar,
    EditorAssessor
  },
  props: {
    value: {
      type: String,
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    },
    toolbar: {
      type: Boolean,
      default: true
    },
    compact: {
      type: Boolean,
      default: true
    },
    background: {
      type: String,
      default: 'white'
    },
    color: {
      type: String,
      default: 'grey-darkest'
    },
    border: {
      type: [Boolean, String, Object],
      default: true
    },
    minimal: {
      type: Boolean,
      default: false
    },
    seo: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    editor: {
      $attrs: 'all',
      borderWidth: 1,
      borderRadius: true,
      $background: 'backgroundColor',
      $color: 'textColor',
      $border: {
        borderWidth(value) {
          return value ? 1 : null
        },
        borderColor(value) {
          return isString(value) ? value : null
        }
      }
    },
    textarea: {
      padding: 'sm',
      lineHeight: 'md',
      borderWidth: 0,
      backgroundColor: 'transparent',
      width: 'full'
    }
  },
  watch: {
    value(newValue) {
      if (this.editor && !this.changed) {
        this.content = this.editor.parseHTML(newValue)
        this.editor.commands.setContent(newValue, false)
      }

      this.changed = false
    }
  },
  data() {
    return {
      editor: null,
      content: null,
      changed: false
    }
  },
  methods: {
    onUpdate() {
      this.changed = true
      this.content = this.editor.fetchHTML()

      this.$emit('input', this.content)
    },
    onFocus() {
      this.$emit('focus')
    },
    onBlur() {
      this.$emit('blur')
    }
  },
  created() {
    this.editor = new Editor({
      content: this.value,
      editable: this.editable,
      onFocus: this.onFocus,
      onBlur: this.onBlur
    })
  },
  mounted() {
    this.onChange = debounce(200)(() => this.onUpdate())
    this.editor.on('update', this.onChange)
  },
  destroyed() {
    this.editor.off('update', this.onChange)
    this.editor.destroy()
  }
}
