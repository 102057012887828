
import Input from '@/mixins/input'
import { isFunction } from 'vessel/utils'

export default {
  name: 'SortableRelation',
  mixins: [Input],
  props: {
    path: {
      type: String,
      default: null,
      required: true
    },
    fragment: {
      type: String,
      default: null
    },
    variables: {
      type: [Object, Function],
      default: () => {}
    },
    sourceKey: {
      type: String,
      required: true
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDisabled: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value(newValue) {
      this.relations = newValue
    }
  },
  data() {
    return {
      relations: []
    }
  },
  computed: {
    emptyItem() {
      return {
        id: null,
        [this.sourceKey]: null
      }
    },
    queryVariables() {
      if (isFunction(this.variables)) {
        return this.variables.call(this)
      } else {
        return this.variables
      }
    },
    popperClass() {
      return this.hideDisabled && 'hide-disabled'
    }
  },
  methods: {
    onChange(property, index, newValue) {
      const valueObj = this.relations[index]

      property[this.sourceKey] = newValue

      if (valueObj) {
        valueObj[this.sourceKey] = newValue
      }

      this.$emit('input', this.relations)
    },
    onSortChange(value) {
      this.$emit('input', value)
    },
    isSelected({ id }) {
      return this.relations.some(({ _destroy, ...obj }) => {
        return !_destroy && obj[this.sourceKey] == id
      })
    }
  }
}
