
import Input from '@/mixins/input'
import { isEqual } from 'vessel/utils'

export default {
  name: 'UploadMeta',
  mixins: [Input],
  inject: {
    fileUpload: {
      default: {
        previewFile: {}
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.metadata = { ...this.defaults, ...newValue }
      }
    }
  },
  data() {
    const defaults = {
      fit: null,
      position: null
    }

    return {
      defaults: defaults,
      metadata: {
        ...defaults,
        ...this.value
      },
      selects: [
        {
          label: 'Fit',
          prop: 'fit',
          options: [
            { label: 'Default', value: null },
            { label: 'Cover', value: 'cover' },
            { label: 'Contain', value: 'contain' }
          ]
        },
        {
          label: 'Position',
          prop: 'position',
          options: [
            { label: 'Default', value: null },
            { label: 'Center', value: 'center' },
            { label: 'Top', value: 'top' },
            { label: 'Right', value: 'right' },
            { label: 'Bottom', value: 'bottom' },
            { label: 'Left', value: 'left' }
          ]
        }
      ]
    }
  },
  computed: {
    type() {
      return this.fileUpload.type
    },
    file() {
      return this.fileUpload.previewFile
    },
    previewTag() {
      return this.type == 'video' ? 'vs-video' : 'vs-image'
    },
    previewAttrs() {
      return {
        class: `${this.type}-preview`,
        controls: this.type == 'video' && true
      }
    }
  },
  methods: {
    onChange() {
      this.$emit('input', this.metadata)
    }
  }
}
