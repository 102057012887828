
import { SeoRating } from '@/packages/yoast'
import Column from '@/mixins/column'

export default {
  name: 'SeoColumn',
  mixins: [Column],
  components: {
    SeoRating
  },
  computed: {
    score() {
      const value = this.value || {}
      return value.overallScore || 0
    }
  }
}
