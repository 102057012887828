import { render, staticRenderFns } from "./OrderItems.vue?vue&type=template&id=3cf20f3f&"
import script from "./OrderItems.vue?vue&type=script&lang=js&"
export * from "./OrderItems.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputActions: require('/opt/build/repo/components/InputActions.vue').default,InputOrdersOrderItem: require('/opt/build/repo/inputs/orders/OrderItem.vue').default,InputOrdersAddOrderItem: require('/opt/build/repo/inputs/orders/AddOrderItem.vue').default,BaseInput: require('/opt/build/repo/components/BaseInput.vue').default,BaseFormItem: require('/opt/build/repo/components/BaseFormItem.vue').default,OrderSummary: require('/opt/build/repo/components/OrderSummary.vue').default})
