
export default {
  name: 'BaseFormItem',
  props: {
    label: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    }
  },
  computed: {
    hasError() {
      return !!this.error
    }
  }
}
