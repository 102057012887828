
export default {
  name: 'BottomBar',
  vessel: {
    common: {
      textSize: 'sm',
      textColor: 'grey-dark',
      backgroundColor: 'white',
      paddingY: 'sm',
      paddingX: 'md'
    }
  }
}
