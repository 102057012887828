import iso from 'iso-3166-1/dist/iso-3166'

export const countries = iso.map(({ alpha2, country }) => ({
  code: alpha2,
  value: alpha2,
  label: country,
  slug: country.toLowerCase()
}))

export const whereCode = (value) => {
  const codes = [].concat(value)
  return countries.filter(({ code }) => codes.includes(code))
}

export const whereName = (value) => {
  const labels = [].concat(value)
  return countries.filter(({ label }) => labels.includes(label))
}

export const search = (value) => {
  const search = value.toLowerCase()
  return countries.filter(({ slug }) => slug.includes(search))
}
