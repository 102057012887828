export default {
  options: {
    columns: 6
  },
  translates: [
    'name',
    'description',
    'excerpt',
    'imageAttributes'
  ],
  inputs: {
    name: {
      span: 6,
      required: true
    },
    description: {
      $input: 'text-editor',
      span: 6,
      $el: {
        seo: true
      }
    },
    excerpt: {
      $input: 'text-editor',
      span: 6,
      $el: {
        minimal: true
      }
    },
    imageAttributes: {
      $input: 'file-upload',
      span: 6,
      $el: {
        clientId: 'eshop',
        type: 'image',
        multiple: false
      }
    },
    code: {
      $input: 'code',
      span: 2
    },
    usageLimit: {
      $input: 'number',
      span: 2,
      $el: {
        label: 'Max',
        step: 1,
        precision: 0
      }
    },
    promotionCategoryId: {
      $input: 'relation-select',
      span: 2,
      $el: {
        path: 'eshop/promotion-category',
        textKey: 'id'
      }
    },
    startsAt: {
      $type: 'date-picker',
      span: 2,
      $el: {
        type: 'datetime'
      }
    },
    expiresAt: {
      $type: 'date-picker',
      span: 2,
      $el: {
        type: 'datetime'
      }
    },
    advertise: {
      $input: 'switch',
      span: 2
    },
    rulesAttributes: {
      $input: 'promotion-rules',
      span: 6
    },
    actionsAttributes: {
      $input: 'promotion-actions',
      span: 6
    }
  }
}
