
import TextValue from './TextValue'

export default {
  name: 'AttributeCountryValue',
  extends: TextValue,
  computed: {
    country() {
      return this.data.internalValue
    }
  }
}
