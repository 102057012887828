
import { LeafletMap } from '@/packages/leaflet'
import Input from '@/mixins/input'

export default {
  name: 'GeoLocation',
  mixins: [Input],
  components: {
    LeafletMap
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    input: {
      $disabled: {
        pointerEvents(value) {
          return value ? 'none' : null
        }
      }
    }
  },
  methods: {
    onChange(data) {
      this.$emit('input', data)
    }
  }
}
