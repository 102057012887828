
import Input from '@/mixins/input'
import { clone, isEqual } from 'vessel/utils'

export default {
  name: 'Nestable',
  mixins: [Input],
  props: {
    value: {
      type: Array,
      default: null
    },
    path: {
      type: String,
      required: true
    },
    schema: {
      type: String,
      default: null
    },
    sortable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    nestedKey: {
      type: String,
      default: 'children'
    },
    deepKey: {
      type: String,
      default: null
    }
  },
  vessel: {
    input: {
      overflow: 'hidden',
      $disabled: {
        backgroundColor: val => val && 'grey-lightest',
        color: val => val && 'grey'
      }
    },
    text: {
      $disabled: {
        color: val => val ? 'grey' : 'grey-darker'
      }
    },
    title: {
      $extend: 'text',
      $disabled: {
        cursor: val => !val && 'pointer'
      }
    },
    info: {
      $extend: 'text',
      paddingX: 'xs'
    },
    icon: {
      padding: 'xs',
      cursor: 'pointer'
    },
    empty: {
      $extend: 'text',
      paddingY: 'xs',
      paddingX: 'sm',
      borderBottomWidth: 1,
      borderColor: 'transparent',
      fontSize: 'sm',
      lineHeight: 'xl'
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.data = clone(newValue)
      }
    },
    data: {
      handler(newValue) {
        this.$emit('input', newValue)
      },
      deep: true
    }
  },
  data() {
    return {
      data: [],
      loading: false,
      createModel: null,
      parentItem: null,
      editItem: null,
      editModel: null,
      editRecord: null
    }
  },
  computed: {
    validItems() {
      return this.data.filter(({ _destroy }) => !_destroy)
    },
    totalItems() {
      return this.validItems.length
    },
    deep() {
      if (this.deepKey) {
        return this.record[this.deepKey] == true
      } else {
        return true
      }
    }
  },
  methods: {
    async fetchRecord({ id }) {
      this.loading = true

      const { data } = await this.$graphql.query({
        path: this.path,
        locale: this.locale,
        variables: { where: { id: id } }
      })

      this.loading = false

      return data
    },
    onAddClick() {
      this.parentItem  = null
      this.createModel = null
      this.$refs.createDialog.open()

      this.$emit('add')
    },
    onAddItemClick(item) {
      this.parentItem  = item
      this.createModel = null
      this.$refs.createDialog.open()

      this.$emit('add', item)
    },
    onRemoveItemClick(item) {
      this.$set(item, '_destroy', true)
      this.$emit('remove', item)
    },
    async onEditItemClick(item) {
      this.editModel = null
      this.$refs.editDialog.open()

      this.editItem   = item
      this.editRecord = item.id ? await this.fetchRecord(item) : item
      this.editModel  = clone({ ...this.editRecord, ...item })
    },
    onCreateReset() {
      this.createModel = null
    },
    onCreateSubmit(value) {
      const newItem = { ...value, [this.nestedKey]: [] }

      if (this.parentItem) {
        this.parentItem[this.nestedKey].push(newItem)
        this.parentItem = null
      } else {
        this.data.push(newItem)
      }

      this.$refs.createDialog.close()
    },
    onEditReset() {
      this.editModel = clone(this.editRecord)
    },
    onEditSubmit(data) {
      Object.entries(data).forEach(([key, value]) => {
        this.$set(this.editItem, key, value)
      })

      this.$refs.editDialog.close()
    }
  }
}
