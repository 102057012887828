
import Input from '@/mixins/input'
import { isEqual } from 'vessel/utils'

export default {
  name: 'RelationInput',
  mixins: [Input],
  props: {
    model: {
      type: [Array, String, Number],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: null
    },
    path: {
      type: String,
      default: null
    },
    fragment: {
      type: String,
      default: null
    },
    parser: {
      type: Function,
      default: val => val
    },
    filter: {
      type: Function,
      default: val => val
    },
    variables: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    skip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      data: null,
      selection: null
    }
  },
  watch: {
    model: {
      handler(newValue) {
        this.selection = newValue
      },
      immediate: true
    },
    locale(newValue, oldValue) {
      if (newValue !== oldValue && !this.skip) {
        this.fetchQuery()
      }
    },
    variables(newValue, oldValue) {
      if (!this.skip && !isEqual(newValue, oldValue)) {
        this.fetchQuery()
      }
    },
    skip(newValue) {
      if (!newValue && !this.data) {
        this.fetchQuery()
      }
    }
  },
  computed: {
    itemsValue() {
      return this.items && this.items.map(item => this.parser(item))
    },
    dataValue() {
      return this.path ? this.data : this.itemsValue
    }
  },
  methods: {
    async fetchQuery(options) {
      if (!this.path) return

      this.loading = true

      const { data } = await this.$graphql.query({
        path: this.path,
        fragment: this.fragment,
        prefetch: false,
        collection: true,
        variables: this.variables,
        locale: this.locale,
        fetchPolicy: 'no-cache',
        ...this.options,
        ...options
      })

      this.data    = this.filter(data || []).map(item => this.parser(item))
      this.loading = false
    },
    onChange(newValue) {
      const inputVal = newValue == '' ? null : newValue
      this.selection = inputVal

      this.$emit('input', inputVal)
    }
  },
  mounted() {
    if (!this.skip) {
      this.$nextTick(this.fetchQuery)
    }
  },
  render() {
    return this.$scopedSlots.default({
      data: this.dataValue,
      loading: this.loading,
      selection: this.selection,
      onChange: this.onChange,
      fetchQuery: this.fetchQuery
    })
  }
}
