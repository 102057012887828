const options = {
  columns: 4
}

const translates = [
  'name',
  'valuesAttributes'
]

const baseInputs = {
  name: {
    span: 2,
    required: true
  },
  internalName: {
    span: 2,
    required: true
  },
  kind: {
    $input: 'enum',
    span: 2,
    required: true,
    $el: {
      enumKey: 'kindEnum'
    }
  },
  filterable: {
    $input: 'switch',
    span: 2
  }
}

const multiInputs = {
  multiple: {
    $input: 'switch',
    span: 2
  },
  displayAs: {
    $input: 'enum',
    span: 2,
    $el: {
      enumKey: 'displayAsEnum'
    }
  }
}

const valueInputs = {
  valuesAttributes: {
    $input: 'attribute-options',
    span: 4,
    required: true
  }
}

const Singular = {
  options,
  translates,
  inputs: {
    ...baseInputs,
    ...valueInputs
  }
}

const Multiple = {
  options,
  translates,
  inputs: {
    ...baseInputs,
    ...multiInputs,
    ...valueInputs
  }
}

export default {
  Option: Singular,
  Property: Multiple
}
