
export default {
  name: 'OrderSection',
  props: {
    label: {
      type: String,
      default: null
    },
    total: {
      type: Number,
      default: null
    },
    button: {
      type: String,
      default: 'Details'
    },
    section: {
      type: String,
      default: null
    },
    actions: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    sectionPath() {
      const path = this.$editRecordPath('orders', this.$route.params.id)
      return `/${path}/${this.section}`
    }
  }
}
