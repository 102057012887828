import { isFunction } from 'vessel/utils'

function menuItem(context, localeKey, basePath, name, item) {
  const link = isFunction(item) ? item.call(context) : item

  let itemPath = link.route || basePath

  if (link.route && !link.route.startsWith('/')) {
    const baseRoute = basePath.replace(/\/$/, '')

    itemPath = baseRoute.endsWith(link.route)
      ? baseRoute
      : `${baseRoute}/${link.route}`
  }

  const route = context.$pagePath(itemPath.replace(/\/$/, ''))
  const label = context.$t(`${localeKey}.${link.label || name}`)

  let visible = link.visible !== false

  if (visible && link.policy) {
    const { service, feature } = link.policy
    visible = context.$activeProperty.hasFeature(service, feature)
  }

  return {
    label: label,
    href: route,
    value: route,
    route: route,
    icon: link.icon,
    disabled: link.disabled,
    visible: visible
  }
}

export default function menuItems(context, localeKey, basePath, links) {
  return Object.entries(links).reduce((result, [name, link]) => {
    const item = menuItem(context, localeKey, basePath, name, link)

    item.children = link.children
      ? menuItems(context, localeKey, item.route, link.children)
      : null

    result.push(item)
    return result
  }, [])
}
