
import ToolbarButton from './toolbar-button'

export default {
  name: 'EditorToolbarGroup',
  components: {
    ToolbarButton
  },
  props: {
    actions: {
      type: Object,
      default: () => ({})
    },
    justify: {
      type: [String, Object],
      default: null
    }
  },
  computed: {
    actionItems() {
      return Object.entries(this.actions).reduce((result, [key, value]) => {
        result.push({ id: key, name: key, ...value })
        return result
      }, [])
    }
  }
}
