export default {
  inheritAttrs: false,
  inject: {
    recordForm: {
      default: {
        $refs: {}
      }
    },
    elFormItem: {
      default: {}
    }
  },
  watch: {
    'recordForm.locale': {
      handler(newValue) {
        this.locale = newValue
      },
      immediate: true
    }
  },
  data() {
    return {
      locale: null
    }
  },
  computed: {
    record() {
      return this.recordForm.record || {}
    },
    form() {
      return this.recordForm.$refs.form || {}
    },
    formItem() {
      return this.elFormItem
    },
    formData() {
      return this.recordForm.formData
    },
    prop() {
      return this.formItem.prop
    },
    isNested() {
      return this.formItem.isNested
    },
    defaultLocale() {
      return this.recordForm.defaultLocale
    },
    isLocalized() {
      return this.recordForm.isLocalized
    },
    isTranslating() {
      return this.locale !== this.defaultLocale
    }
  }
}
