export default {
  options: {
    columns: 1
  },
  inputs: {
    position: {
      $input: 'number',
      label: false,
      $el: {
        step: 1,
        min: 1,
        precision: 0,
        label: 'Position'
      }
    }
  }
}
