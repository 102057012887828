
import TextValue from './TextValue'

export default {
  name: 'AttributeNumberValue',
  extends: TextValue,
  props: {
    precision: {
      type: Number,
      default: 3
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler({ internalValue }) {
        this.value = parseFloat(internalValue)
      }
    }
  },
  data() {
    return {
      value: null,
      numberLabel: null
    }
  },
  methods: {
    onChange(value) {
      this.data.internalValue = `${value}`
    }
  }
}
