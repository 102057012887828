
export default {
  inheritAttrs: false,
  props: {
    index: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: 'Label'
    },
    description: {
      type: String,
      default: null
    },
    fields: {
      type: Array,
      default: null
    },
    options: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Boolean,
      default: false
    },
    activeChild: {
      type: Number,
      default: null
    }
  },
  computed: {
    hasFields() {
      return this.fields && this.fields.filter(item => !item._destroy).length
    },
    isFocus() {
      return this.active && this.activeChild == null
    },
    selected() {
      return this.active ? this.activeChild : null
    }
  },
  methods: {
    onFocus() {
      this.$emit('activate', this.index)
    },
    onSortChange(event, items) {
      this.$emit('sort-group', this.index, event, items)
    },
    onActivate(index) {
      this.$emit('activate-child', this.index, index)
    },
    onAddField() {
      this.$emit('add-group-field', this.index)
    }
  }
}
