
export default {
  name: 'VideoUpload',
  props: {
    file: {
      type: Object,
      default: null
    },
    removable: {
      type: Boolean,
      default: true
    }
  },
  vessel: {
    remove: {
      opacity: '50',
      pointerEvents: 'none'
    }
  },
  computed: {
    percentage() {
      if (this.file) {
        return Math.round(this.file.percentage)
      } else {
        return 0
      }
    },
    title() {
      return this.file.name || 'Uploading...'
    }
  }
}
