
import Column from '@/mixins/column'

export default {
  name: 'LabelColumn',
  mixins: [Column],
  props: {
    format: {
      type: String,
      default: 'long'
    }
  }
}
