import { render, staticRenderFns } from "./FlatRate.vue?vue&type=template&id=4f59624e&"
import script from "./FlatRate.vue?vue&type=script&lang=js&"
export * from "./FlatRate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputMoney: require('/opt/build/repo/inputs/Money.vue').default})
