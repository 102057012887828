
import NumberValue from './NumberValue'

export default {
  name: 'AttributeVolumeValue',
  extends: NumberValue,
  data() {
    return {
      numberLabel: 'l'
    }
  }
}
