
import Input from '@/mixins/input'

export default {
  name: 'LinkedSelect',
  mixins: [Input],
  props: {
    value: {
      type: [Array, String, Number],
      default: null
    },
    data: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledItems: {
      type: Array,
      default: null
    },
    hideDisabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue) {
      this.selected = newValue
    }
  },
  data() {
    return {
      activated: null,
      selected: this.value
    }
  },
  computed: {
    dataItems() {
      return this.data || []
    },
    options() {
      const active = this.dataItems.find(({ value }) => value == this.active)
      return Object(active).options
    },
    activeItem() {
      const active = this.dataItems.find(({ options }) => {
        return options.some(({ value }) => value == this.value)
      })

      return Object(active).value
    },
    active: {
      get() {
        return this.activated || this.activeItem
      },
      set(value) {
        this.activated = value
      }
    },
    popperClass() {
      return this.hideDisabled && 'hide-disabled'
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value)
    },
    onActivate(value) {
      this.selected = null

      this.$emit('input', null)
      this.$emit('active', value)
    },
    isSelected({ value }) {
      const disabled = this.disabledItems || []
      return disabled.includes(value)
    }
  }
}
