
import Input from '@/mixins/input'

export default {
  name: 'Attributes',
  mixins: [Input],
  props: {
    type: {
      type: String,
      default: null,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDisabled: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value(newValue) {
      this.attributes = newValue
    }
  },
  data() {
    return {
      attributes: []
    }
  },
  computed: {
    emptyItem() {
      return {
        id: null,
        propertyId: null
      }
    },
    popperClass() {
      return this.hideDisabled && 'hide-disabled'
    }
  },
  methods: {
    onChange(property, index, newValue) {
      const valueObj = this.attributes[index]

      property.propertyId = newValue

      if (valueObj) {
        valueObj.propertyId = newValue
      }

      this.$emit('input', this.attributes)
    },
    onSortChange(value) {
      this.$emit('input', value)
    },
    isSelected({ id }) {
      return this.attributes.some(({ propertyId, _destroy }) => {
        return !_destroy && propertyId == id
      })
    }
  }
}
