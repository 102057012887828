
import Input from '@/mixins/input'

export default {
  name: 'AttributeOptions',
  mixins: [Input],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    kindKey: {
      type: String,
      default: 'kind'
    }
  },
  watch: {
    value(newValue) {
      this.options = newValue
    }
  },
  data() {
    return {
      component: 'input-attributes-text-value',
      options: []
    }
  },
  computed: {
    translating() {
      return this.locale != this.defaultLocale
    },
    formValue() {
      return this.form.value
    },
    emptyItem() {
      return {
        id: null,
        value: null,
        internalValue: null,
        sortOrder: null
      }
    }
  },
  methods: {
    onSortChange(value) {
      this.$emit('input', value)
    },
    onUpdateItem(index, value) {
      this.options[index] = value
    },
    onFormUpdate(value) {
      const data = value || {}
      const kind = data[this.kindKey] || 'text'
      const name = kind.toLowerCase()
      const comp = `input-attributes-${name}-value`

      if (this.component != comp) {
        this.component = comp
      }
    }
  },
  beforeMount() {
    this.recordForm.$on('update', this.onFormUpdate)
  },
  beforeDestroy() {
    this.recordForm.$off('update', this.onFormUpdate)
  }
}
