import { hasProp } from 'vessel/utils'

export default function({ app, route, redirect }) {
  if (app.$auth.loggedIn) {
    const meta = route.meta.find(i => hasProp('redirect', i))

    if (meta && meta.redirect) {
      redirect({ path: meta.redirect })
    }
  }
}
