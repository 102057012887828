import { render, staticRenderFns } from "./Code.vue?vue&type=template&id=4b31a3c1&"
import script from "./Code.vue?vue&type=script&lang=js&"
export * from "./Code.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseInput: require('/opt/build/repo/components/BaseInput.vue').default})
