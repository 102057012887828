
import { isEqual } from 'vessel/utils'

export default {
  name: 'FlatRatePayment',
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.settings = this.parseValue(newValue)
      }
    },
    settings: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      }
    }
  },
  data() {
    return {
      settings: this.parseValue(this.value)
    }
  },
  methods: {
    parseValue(value) {
      return { rate: 0, ...value }
    }
  }
}
