
export default {
  name: 'SelectActionBar',
  props: {
    height: {
      type: Number,
      default: 38
    },
    icon: {
      type: String,
      default: 'plus-circle'
    },
    text: {
      type: String,
      default: 'Add new'
    }
  },
  vessel: {
    button: {
      paddingY: 'xs',
      paddingX: 'sm',
      backgroundColor: 'white',
      color: 'grey-dark',
      fontSize: 'sm',
      cursor: 'pointer',
      borderTopWidth: '1',
      borderColor: 'grey-light',
      width: 'full',
      userSelect: 'none',
      position: 'absolute',
      pinLeft: true,
      pinBottom: true,
      borderBottomRadius: true,
      hover: {
        color: 'grey-darkest'
      }
    }
  },
  computed: {
    wrapperStyle() {
      return { height: `${this.height}px` }
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
