export default {
  options: {
    columns: 6
  },
  translates: [
    'name',
    'description',
    'excerpt',
    'imageAttributes'
  ],
  inputs: {
    name: {
      span: 4,
      required: true
    },
    advertise: {
      $input: 'switch',
      span: 2
    },
    description: {
      $input: 'text-editor',
      span: 6,
      $el: {
        seo: true
      }
    },
    excerpt: {
      $input: 'text-editor',
      span: 6,
      $el: {
        minimal: true
      }
    },
    imageAttributes: {
      $input: 'file-upload',
      span: 6,
      $el: {
        clientId: 'eshop',
        type: 'image',
        multiple: false
      }
    },
    collectionProductsAttributes: {
      $input: 'sortable-relation',
      label: 'products',
      span: 6,
      $el: {
        path: 'eshop/product',
        fragment: 'RelatedProduct',
        sourceKey: 'productId'
      }
    }
  }
}
