
import NumberValue from './NumberValue'

export default {
  name: 'AttributeWeightValue',
  extends: NumberValue,
  data() {
    return {
      numberLabel: 'kg'
    }
  }
}
