import userQuery from '@/graphql/default/current-user.gql'

export default ({ app, store }, inject) => {
  inject('user', {
    get data() {
      return store.getters['user/data'] || {}
    },
    get email() {
      return this.data.email
    },
    reset() {
      store.commit('user/RESET_USER')
    },
    async fetch() {
      try {
        const { data } = await app.$graphql.query({
          clientId: 'default',
          query: userQuery
        })

        store.commit('user/UPDATE_USER', data)
      } catch (e) {
        console.log(e)
      }
    }
  })
}
