
import Column from '@/mixins/column'

export default {
  name: 'ImageColumn',
  mixins: [Column],
  props: {
    fallback: {
      type: String,
      default: '/image-missing-fill.svg'
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  data() {
    return {
      imageAttrs: {
        width: 'sm',
        fit: 'cover',
        ratio: 'square',
        lazy: true,
        marginX: 'auto',
        borderRadius: true,
        borderWidth: 1,
        overflow: 'hidden'
      }
    }
  },
  computed: {
    source() {
      const value = this.value || {}
      return value[this.size] || this.fallback
    }
  }
}
