export default {
  options: {
    columns: 4
  },
  inputs: {
    name: {
      span: 4,
      required: true
    },
    internalName: {
      span: 4,
      required: true
    },
    countryId: {
      $input: 'country-select',
      span: 4,
      required: true
    },
    address1: {
      span: 4
    },
    address2: {
      span: 4
    },
    city: {
      span: 2
    },
    regionId: {
      $input: 'relation-select',
      span: 2,
      $el: {
        path: 'eshop/region',
        textKey: 'id'
      }
    },
    zipcode: {
      span: 2
    },
    phone: {
      span: 2
    },
    active: {
      $input: 'switch',
      span: 2
    },
    default: {
      $input: 'switch',
      span: 2
    }
  }
}
