export default {
  options: {
    columns: 4
  },
  translates: [
    'name',
    'description'
  ],
  inputs: {
    name: {
      span: 4,
      required: true
    },
    internalName: {
      span: 2,
      required: true
    },
    kind: {
      $input: 'enum',
      span: 2,
      $el: {
        enumKey: 'kindEnum'
      }
    },
    description: {
      $input: 'text-editor',
      span: 4
    },
    duration: {
      $input: 'number',
      span: 4,
      $el: {
        label: 'Months',
        step: 1,
        precision: 0
      }
    },
    includedInPrice: {
      $input: 'switch',
      span: 4
    },
    taxCategoryId: {
      $input: 'relation-select',
      span: 4,
      $el: {
        path: 'eshop/tax-category',
        textKey: 'id'
      }
    },
    warrantyCategoryIds: {
      $input: 'relation-select',
      span: 4,
      required: true,
      $el: {
        path: 'eshop/warranty-category',
        multiple: true,
        textKey: 'id'
      }
    }
  }
}
