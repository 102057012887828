export default {
  options: {
    columns: 4
  },
  inputs: {
    email: {
      span: 4,
      required: true
    },
    listIds: {
      $input: 'relation-select',
      span: 4,
      $el: {
        path: 'site/newsletter-list',
        multiple: true,
        textKey: 'id'
      }
    }
  }
}
