
import { isBlank } from 'vessel/utils'
import { useFileDialog } from '@vueuse/core'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'

import 'leaflet.locatecontrol/dist/L.Control.Locate.min.js'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'

import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

const layerToJson = (layer) => {
  const json = layer.toGeoJSON()

  if (layer instanceof L.Circle) {
    json.properties.radius = layer.getRadius()
  }

  return json
}

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    view: {
      type: Array,
      default: () => ([38.1288337, 22.2389048])
    },
    zoom: {
      type: Number,
      default: 7
    }
  },
  watch: {
    data(value) {
      if (value && !this.hasLayers()) {
        this.loadGeoJSON(value)
      }
    },
    'filePicker.files': {
      async handler(files) {
        if (files && files.length) {
          const data = JSON.parse(await files[0].text())

          try {
            this.loadGeoJSON(data)
            this.$emit('change', this.getGeoJSON())
          } catch (e) {
            this.$message.error(e.message)
          }

          this.filePicker.reset()
        }
      }
    }
  },
  data() {
    return {
      filePicker: useFileDialog()
    }
  },
  methods: {
    addListeners(layer, isCreate = false) {
      const emitter = (name) => (evt) => {
        this.$emit(name, evt)
        this.$emit('change', this.getGeoJSON())
      }

      layer.on('pm:edit', emitter('edit'))
      layer.on('pm:remove', emitter('remove'))

      if (isCreate) {
        const onCreate = emitter('create')
        onCreate(layer)
      }
    },
    getLayers() {
      return this.map ? this.map.pm.getGeomanLayers() : []
    },
    hasLayers() {
      return this.getLayers().length > 0
    },
    getGeoJSON() {
      const features = this.getLayers().map(layerToJson)
      return { type: 'FeatureCollection', features }
    },
    loadGeoJSON(data) {
      if (!this.map || isBlank(data)) return

      const layer = L.geoJson(data, {
        pointToLayer: (feature, latlng) => {
          if (feature.properties.radius) {
            return new L.Circle(latlng, feature.properties.radius)
          } else {
            return new L.Marker(latlng)
          }
        }
      })

      layer.addTo(this.map)
      this.addListeners(layer)

      if (this.hasLayers() && !this.mapCentered) {
        this.mapCentered = true
        this.map.fitBounds(layer.getBounds())
      }
    }
  },
  mounted() {
    if (this.map) return

    this.map = L.map(this.$refs.map, {
      zoomControl: false,
      attributionControl: false
    })

    const tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png')
    this.map.addLayer(tiles)

    const zoom = L.control.zoom({ position: 'bottomright' })
    zoom.addTo(this.map)

    const screen = new L.Control.Fullscreen({ position: 'topright' })
    screen.addTo(this.map)

    const locate = L.control.locate({ position: 'topright' })
    locate.addTo(this.map)

    this.map.setView(this.view, this.zoom)

    this.map.pm.Toolbar.copyDrawControl('Circle', {
      name: 'PolygonCircle',
      block: 'draw',
      className: 'control-icon leaflet-pm-icon-circle-marker',
      title: 'Draw Circle with points',
    })

    this.map.pm.Toolbar.createCustomControl({
      name: 'ImportGeoJSON',
      block: 'custom',
      className: 'uil uil-import',
      title: 'Import GeoJSON data',
      toggle: false,
      onClick: () => this.filePicker.open({
        multiple: false,
        accept: 'application/json'
      })
    })

    this.map.pm.setPathOptions(
      {
        color: 'orange',
        fillColor: 'orange',
        fillOpacity: 0.4
      },
      {
        ignoreShapes: ['Polygon', 'Circle', 'Rectangle']
      }
    )

    this.map.pm.addControls({
      positions: {
        draw: 'topleft',
        custom: 'bottomleft',
        edit: 'bottomleft'
      },
      drawMarker: false,
      drawCircleMarker: false,
      drawText: false,
      drawPolygon: true,
      editMode: true,
      drawPolyline: false,
      removalMode: true,
      rotateMode: false
    })

    const circleToPoly = (layer, shape) => {
      if (shape != 'PolygonCircle') return layer

      const poly = L.PM.Utils.circleToPolygon(layer, 30)
      this.map.removeLayer(layer)

      this.map.addLayer(poly)
      return poly
    }

    this.map.on('pm:create', ({ layer, shape }) => {
      this.mapCentered = true

      const object = circleToPoly(layer, shape)
      this.addListeners(object, true)
    })

    this.map.on('pm:cut', ({ layer }) => {
      this.addListeners(layer)
    })

    this.loadGeoJSON(this.data)
  },
  destroyed() {
    if (!this.map) return

    this.map.off()
    this.map.remove()

    this.map = null
  }
}
