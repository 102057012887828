
import Input from '@/mixins/input'

export default {
  mixins: [Input],
  props: {
    value: {
      type: String,
      default: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.selected = newValue
      }
    },
    enabled(newValue) {
      if (newValue == false && this.selected) {
        this.$emit('input', null)
      }
    }
  },
  data() {
    return {
      kindName: null,
      selected: null
    }
  },
  computed: {
    kind() {
      return this.kindName || this.record.kind
    },
    enabled() {
      const attribs = ['PROPERTY', 'OPTION']
      return attribs.includes(this.kind)
    },
    options() {
      const options = [{ label: 'Default', value: null }]

      if (this.enabled) {
        options.push(
          { label: 'Label', value: 'value' },
          { label: 'Value', value: 'internal_value' }
        )
      }

      return options
    }
  },
  methods: {
    onFormUpdate(value) {
      this.kindName = value.kind
    },
    onChange(value) {
      this.$emit('input', value)
    }
  },
  beforeMount() {
    this.recordForm.$on('update', this.onFormUpdate)
  },
  beforeDestroy() {
    this.recordForm.$off('update', this.onFormUpdate)
  }
}
