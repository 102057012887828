
import Input from '@/mixins/input'

export default {
  name: 'NumberInput',
  mixins: [Input],
  props: {
    label: {
      type: String,
      default: null
    },
    min: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 0.1
    },
    precision: {
      type: Number,
      default: 2
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    wrapper: {
      borderRadius: 'default',
      overflow: 'hidden',
      $disabled: {
        backgroundColor(value) {
          return value ? 'grey-lightest' : null
        }
      },
      $active: {
        borderColor(value) {
          return value ? { all: 'primary', hover: 'primary' } : null
        }
      }
    },
    label: {
      borderRightWidth: '1',
      borderColor: 'grey-lighter',
      paddingX: 'sm',
      whiteSpace: 'no-wrap',
      $disabled: {
        textColor(value) {
          return value ? 'grey' : 'grey-dark'
        }
      }
    }
  },
  data() {
    return {
      active: false
    }
  }
}
