
import scoreToRating from 'yoastseo/src/interpreters/scoreToRating.js'
import { getRatingVariant, getRatingIcon } from '../utils.js'

export default {
  name: 'SeoRating',
  props: {
    tag: {
      type: String,
      default: 'vs-tag'
    },
    score: {
      type: [Number, String],
      default: 0
    },
    showText: {
      type: Boolean,
      default: true
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    defaultColor: {
      type: String,
      default: 'info'
    },
    defaultIcon: {
      type: String,
      default: 'equal-circle'
    }
  },
  computed: {
    rating() {
      return scoreToRating(parseInt(this.score || 0) / 10)
    },
    color() {
      return getRatingVariant(this.rating) || this.defaultColor
    },
    icon() {
      return getRatingIcon(this.rating) || this.defaultIcon
    },
    text() {
      return `Score: ${this.score || 0}`
    },
    tagAttrs() {
      const attrs = { ...this.$attrs }

      switch (this.tag) {
        case 'vs-tag':
          attrs.type = this.color
          break
        case 'vs-tooltip':
          attrs.content = this.text
          break
        default:
          attrs['text-color'] = this.color
      }

      return attrs
    }
  }
}
