export default {
  dashboard: {
    route: 'dashboard',
    icon: 'estate'
  },
  bookings: {
    route: 'bookings',
    icon: 'calendar-alt',
    policy: {
      service: 'hotel',
      feature: 'bookings'
    },
    children: {
      bookings: {
        route: 'bookings'
      }
    }
  },
  rooms: {
    route: 'rooms',
    icon: 'bed',
    policy: {
      service: 'hotel'
    },
    children: {
      rooms: {
        route: 'rooms'
      }
    }
  },
  orders: {
    route: 'orders',
    icon: 'shopping-cart',
    policy: {
      service: 'eshop',
      feature: 'orders'
    },
    children: {
      orders: {
        route: 'orders',
      }
    }
  },
  products: {
    route: 'products',
    icon: 'archive',
    policy: {
      service: 'eshop'
    },
    children: {
      products: {
        route: 'products'
      },
      prototypes: {
        route: 'prototypes'
      },
      properties: {
        route: 'properties'
      },
      options: {
        route: 'options'
      },
      collections: {
        route: 'collections'
      },
      taxonomies: {
        route: 'taxonomies'
      },
      taxons: {
        route: 'taxons'
      },
      filters: {
        route: 'filters'
      }
    }
  },
  promotions: {
    route: 'promotions',
    icon: 'gift',
    policy: {
      service: 'eshop'
    },
    children: {
      promotions: {
        route: 'promotions'
      },
      categories: {
        route: 'categories'
      }
    }
  },
  forms: {
    route: 'forms',
    icon: 'envelope',
    children: {
      forms: {
        route: 'forms'
      },
      messages: {
        route: 'messages'
      }
    }
  },
  newsletters: {
    route: 'newsletters',
    icon: 'message',
    children: {
      lists: {
        route: 'lists'
      },
      subscribers: {
        route: 'subscribers'
      }
    }
  }
}
