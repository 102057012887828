
import ToolbarGroup from './toolbar-group'
import { select, reject, debounce } from 'vessel/utils'

export default {
  name: 'EditorToolbar',
  components: {
    ToolbarGroup
  },
  provide() {
    return {
      toolbar: this
    }
  },
  props: {
    editor: {
      type: [Object, Function],
      required: true
    },
    buttons: {
      type: Array,
      default: null
    },
    compact: {
      type: Boolean,
      default: true
    },
    minimal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      history: ['undo', 'redo'],
      basic: ['bold', 'italic', 'strike', 'underline'],
      actions: {
        bold: {
          icon: 'bold'
        },
        italic: {
          icon: 'italic'
        },
        strike: {
          icon: 'text-strike-through'
        },
        underline: {
          icon: 'underline'
        },
        code: {
          icon: 'code'
        },
        paragraph: {
          icon: 'paragraph',
          command: 'setParagraph'
        },
        h1: {
          name: 'heading',
          text: 'H1',
          options: { level: 1 }
        },
        h2: {
          name: 'heading',
          text: 'H2',
          options: { level: 2 }
        },
        h3: {
          name: 'heading',
          text: 'H3',
          options: { level: 3 }
        },
        bulletList: {
          icon: 'list-ul'
        },
        orderedList: {
          icon: 'list-ui-alt'
        },
        blockquote: {
          icon: 'comment-alt-message'
        },
        codeBlock: {
          icon: 'brackets-curly'
        },
        horizontalRule: {
          icon: 'border-horizontal',
          command: 'setHorizontalRule'
        },
        undo: {
          icon: 'history',
          command: 'undo'
        },
        redo: {
          icon: 'redo',
          command: 'redo'
        }
      }
    }
  },
  computed: {
    mainActions() {
      if (this.minimal) {
        return select(this.actions, key => this.basic.includes(key))
      } else {
        return reject(this.actions, key => this.history.includes(key))
      }
    },
    historyActions() {
      return select(this.actions, key => this.history.includes(key))
    },
    groups() {
      return [
        { key: 'main', actions: this.mainActions },
        { key: 'hist', actions: this.historyActions, justify: 'end' }
      ]
    }
  },
  methods: {
    isActive(command, options) {
      return this.editor.isActive(command, options)
    },
    runCommand(name, options) {
      const command = this.editor.commands[name]
      command(options)

      this.$emit('update:active')
    }
  },
  mounted() {
    this.onSelect = debounce(100)(() => this.$emit('update:active'))
    this.editor.on('selectionUpdate', this.onSelect)
  },
  destroyed() {
    this.editor.off('selectionUpdate', this.onSelect)
  }
}
