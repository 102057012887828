
import Input from '@/mixins/input'
import { isEqual } from 'vessel/utils'

export default {
  name: 'PromotionActionsInput',
  mixins: [Input],
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    input: {
      overflow: 'hidden',
      $disabled: {
        backgroundColor: val => val && 'grey-lightest',
        color: val => val && 'grey'
      }
    },
    counter: {
      marginX: '2xs',
      fontWeight: 'bold',
      $disabled: {
        color(value) {
          return value ? 'grey' : 'primary'
        }
      }
    },
    empty: {
      $extend: 'text',
      paddingY: 'xs',
      paddingX: 'sm',
      borderBottomWidth: 1,
      borderColor: 'transparent',
      fontSize: 'sm',
      lineHeight: 'xl'
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.preventUpdate = true

        const value  = this.parseValue(newValue)
        this.actions = value.actions
      }

      this.preventUpdate = false
    },
    actions: {
      deep: true,
      handler(value) {
        if (!this.preventUpdate) {
          this.$emit('input', { actions: value })
        }
      }
    }
  },
  data() {
    return {
      preventUpdate: false,
      typeOptions: [
        { label: 'Price Adjustment', value: 'price_adjustment' },
        { label: 'Order Adjustment', value: 'order_adjustment' },
        { label: 'Shipping Adjustment', value: 'shipping_adjustment' }
      ],
      ...this.parseValue(this.value)
    }
  },
  computed: {
    activeActions() {
      return this.actions.filter(item => !item._destroy)
    }
  },
  methods: {
    parseValue(value) {
      return { actions: [], ...value }
    },
    parseTag(type) {
      const comp = type.replace(/_/g, '-')
      return `input-promotions-${comp}-action`
    },
    addAction(type) {
      this.actions.push({ type, options: {} })
    },
    onRemove(index) {
      const item = this.actions[index] || {}

      if (item.id) {
        this.$set(item, '_destroy', true)
      } else {
        this.actions.splice(index, 1)
      }
    }
  }
}
