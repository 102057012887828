import { getRatingVariant, getRatingIcon } from './utils.js'

export default {
  props: {
    resultFilter: {
      type: Function,
      default({ rating, score, socre, text }) {
        return { rating, text, score: score || socre }
      }
    }
  },
  watch: {
    assessorResults: 'emitUpdate'
  },
  data() {
    return {
      overallScore: 0,
      overallRating: 'na'
    }
  },
  computed: {
    overallColor() {
      return getRatingVariant(this.overallRating)
    },
    overallIcon() {
      return getRatingIcon(this.overallRating)
    }
  },
  methods: {
    emitUpdate() {
      this.$emit('update', {
        keyword: this.keyword,
        results: this.assessorResults,
        resultsByRating: this.assessorResultsByRating,
        overallScore: this.overallScore,
        overallRating: this.overallRating,
        overallIcon: this.overallIcon,
        overallColor: this.overallColor
      })
    }
  }
}
