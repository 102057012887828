export default {
  hotel: {
    route: 'hotel',
    icon: 'building',
    policy: {
      service: 'hotel'
    }
  },
  store: {
    route: 'store',
    icon: 'store',
    policy: {
      service: 'eshop'
    }
  },
  users: {
    route: 'users',
    icon: 'user',
    policy: {
      service: 'auth'
    }
  },
  admins: {
    route: 'admins',
    icon: 'user-md'
  },
  // settings: {
  //   route: 'settings',
  //   icon: 'cog'
  // }
}
