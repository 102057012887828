
import { isEqual } from 'vessel/utils'

export default {
  name: 'FlexibleRatePerItemShipping',
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.settings = this.parseValue(newValue)
      }
    },
    settings: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      }
    }
  },
  data() {
    return {
      settings: this.parseValue(this.value)
    }
  },
  computed: {
    items() {
      return [
        {
          key: 'firstItem',
          input: 'input-money',
          label: 'First Item'
        },
        {
          key: 'additionalItem',
          input: 'input-money',
          label: 'Additional Item'
        },
        {
          key: 'maxItems',
          input: 'input-number',
          label: 'Max. Items',
          attrs: {
            label: 'MAX',
            step: 1,
            precision: 0
          }
        }
      ]
    }
  },
  methods: {
    parseValue(value) {
      return { firstItem: 0, additionalItem: 0, maxItems: 0, ...value }
    }
  }
}
