export default {
  options: {
    columns: 6
  },
  translates: [
    'name',
    'description',
    'excerpt',
    'imagesAttributes',
    'videosAttributes',
    'brochuresAttributes'
  ],
  inputs: {
    name: {
      span: 6,
      required: true
    },
    sku: {
      required: true,
      span: 2
    },
    price: {
      $input: 'number',
      required: true,
      span: 2,
      $el: {
        label: 'EUR'
      }
    },
    costPrice: {
      $input: 'number',
      span: 2,
      $el: {
        label: 'EUR'
      }
    },
    description: {
      $input: 'text-editor',
      span: 6,
      $el: {
        seo: true
      }
    },
    excerpt: {
      $input: 'text-editor',
      span: 6,
      $el: {
        minimal: true
      }
    },
    imagesAttributes: {
      $input: 'file-upload',
      span: 6,
      $el: {
        clientId: 'eshop',
        type: 'image'
      }
    },
    videosAttributes: {
      $input: 'file-upload',
      span: 6,
      $el: {
        clientId: 'eshop',
        type: 'video'
      }
    },
    brochuresAttributes: {
      $input: 'file-upload',
      span: 6,
      $el: {
        clientId: 'eshop'
      }
    },
    mpn: {
      span: 3,
      $el: {
        'prefix-icon': 'box'
      }
    },
    ean: {
      span: 3,
      $el: {
        'prefix-icon': 'qrcode-scan'
      }
    },
    availableOn: {
      $type: 'date-picker',
      span: 3,
      $el: {
        type: 'datetime'
      }
    },
    discontinueOn: {
      $type: 'date-picker',
      span: 3,
      $el: {
        type: 'datetime'
      }
    },
    active: {
      $input: 'switch',
      span: 3
    },
    featured: {
      $input: 'switch',
      span: 3
    },
    promotable: {
      $input: 'switch',
      span: 3
    },
    backorderable: {
      $input: 'switch',
      span: 3
    },
    taxCategoryId: {
      $input: 'relation-select',
      span: 3,
      $el: {
        path: 'eshop/tax-category',
        textKey: 'id'
      }
    },
    shippingCategoryId: {
      $input: 'relation-select',
      span: 3,
      $el: {
        path: 'eshop/shipping-category',
        textKey: 'id'
      }
    },
    warrantyCategoryId: {
      $input: 'relation-select',
      span: 6,
      $el: {
        path: 'eshop/warranty-category',
        textKey: 'id'
      }
    },
    selfTaxonIds: {
      $input: 'taxons',
      span: 6,
      label: false,
      clearable: true
    },
    length: {
      $input: 'number',
      span: 3,
      $el: {
        label: 'm',
        precision: 3
      }
    },
    width: {
      $input: 'number',
      span: 3,
      $el: {
        label: 'm',
        precision: 3
      }
    },
    height: {
      $input: 'number',
      span: 3,
      $el: {
        label: 'm',
        precision: 3
      }
    },
    weight: {
      $input: 'number',
      span: 3,
      $el: {
        label: 'kg',
        precision: 3
      }
    },
    propertyAssignmentsAttributes: {
      $input: 'attribute-values',
      label: 'properties',
      span: 6,
      $el: {
        type: 'PROPERTY',
        attributesKey: 'propertyIds'
      }
    },
    optionAssignmentsAttributes: {
      $input: 'attributes',
      label: 'options',
      span: 6,
      $el: {
        type: 'OPTION'
      }
    },
    relatedRelationsAttributes: {
      $input: 'sortable-relation',
      label: 'related',
      span: 6,
      $el: {
        path: 'eshop/product',
        fragment: 'RelatedProduct',
        sourceKey: 'targetId',
        variables() {
          return { where: { id_not: this.$route.params.id } }
        }
      }
    }
  }
}
