
import { SeoAssessor } from 'vue-yoast'
import AssessorList from './assessor-list.vue'
import AssessorMixin from '../mixin.js'

export default {
  name: 'SeoAssessor',
  extends: SeoAssessor,
  mixins: [AssessorMixin],
  components: {
    AssessorList
  },
  watch: {
    overallSeoRating: {
      immediate: true,
      handler(value) {
        this.overallRating = value
        this.emitUpdate()
      }
    },
    overallSeoScore: {
      immediate: true,
      handler(value) {
        this.overallScore = value
        this.emitUpdate()
      }
    }
  }
}
