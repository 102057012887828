export default {
  create() {
    return {
      route: 'new',
      label: 'cart',
      icon: 'shopping-cart',
      visible: this.newPage
    }
  },
  update() {
    return {
      route: `edit/${this.$route.params.id}`,
      label: 'summary',
      icon: 'clipboard-alt',
      visible: this.editPage
    }
  },
  cart() {
    return {
      route: `edit/${this.$route.params.id}/cart`,
      label: 'cart',
      icon: 'shopping-cart',
      visible: this.editPage
    }
  },
  customer() {
    return {
      route: `edit/${this.$route.params.id}/customer`,
      icon: 'user',
      disabled: this.newPage
    }
  },
  shipment() {
    return {
      route: `edit/${this.$route.params.id}/shipment`,
      icon: 'truck',
      disabled: this.newPage
    }
  },
  // adjustment() {
  //   return {
  //     route: `edit/${this.$route.params.id}/adjustment`,
  //     icon: 'cog',
  //     disabled: this.newPage
  //   }
  // },
  payment() {
    return {
      route: `edit/${this.$route.params.id}/payment`,
      icon: 'credit-card',
      disabled: this.newPage
    }
  },
  // history() {
  //   return {
  //     route: `edit/${this.$route.params.id}/history`,
  //     icon: 'sync',
  //     disabled: this.newPage
  //   }
  // },
  notes() {
    return {
      route: `edit/${this.$route.params.id}/notes`,
      icon: 'notes',
      disabled: this.newPage
    }
  }
}
