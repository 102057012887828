export default {
  options: {
    columns: 4
  },
  inputs: {
    name: {
      span: 4,
      required: true
    },
    default: {
      $input: 'switch',
      $default: false,
      span: 4
    },
    active: {
      $input: 'switch',
      span: 4
    }
  }
}
