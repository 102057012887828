
import { isEqual, clone, toArray, reduce, reject } from 'vessel/utils'

export default {
  props: {
    types: {
      type: Array,
      default: () => ([])
    },
    input: {
      type: Object,
      default: null
    },
    translating: {
      type: Boolean,
      default: false
    },
    breakpoint: {
      type: String,
      default: 'desktop'
    }
  },
  watch: {
    input: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.model = clone(newValue)
        }
      }
    }
  },
  data() {
    return {
      model: {}
    }
  },
  computed: {
    isGroup() {
      return this.input && this.input.type == 'group'
    },
    typeOptions() {
      return toArray(this.types).map(
        ({ kind, name }) => ({ label: name, value: kind })
      ).filter(
        ({ value }) => value != 'group'
      )
    },
    activeType() {
      return this.types.find(({ kind }) => kind == this.input.type)
    },
    optionsInputs() {
      const types = { boolean: 'input-switch', number: 'input-number' }
      const sizes = { private: 3, ignore: 3 }

      return reduce(this.activeType.options, (opts, item) => {
        const kname = item.name
        const value = this.input.options[kname]

        opts[kname] = {
          span: sizes[kname],
          label: item.label,
          tag: types[item.type],
          value: value == null ? item.default : value
        }
      }, {})
    },
    inputs() {
      return this.isGroup ? this.groupInputs : this.fieldInputs
    },
    options() {
      return this.isGroup ? this.groupOptions : this.fieldOptions
    },
    groupInputs() {
      return {
        name: {
          label: 'Name',
          required: true
        },
        label: {
          label: 'Title',
          translatable: true,
          required: true
        },
        description: {
          label: 'Desctiption',
          translatable: true,
          tag: 'input-text-editor',
          minimal: true
        }
      }
    },
    fieldInputs() {
      return {
        type: {
          label: 'Type',
          tag: 'input-enum',
          enumeration: this.typeOptions,
          span: 3
        },
        required: {
          label: 'Required',
          tag: 'input-switch',
          span: 3
        },
        name: {
          label: 'Name',
          required: true
        },
        label: {
          label: 'Label',
          translatable: true,
          required: true
        },
        placeholder: {
          label: 'Placeholder',
          translatable: true
        },
        description: {
          label: 'Desctiption',
          translatable: true,
          tag: 'input-text-editor',
          minimal: true
        }
      }
    },
    groupOptions() {
      const invalid = ['size']
      return reject(this.optionsInputs, key => invalid.includes(key))
    },
    fieldOptions() {
      const invalid = ['size']
      return reject(this.optionsInputs, key => invalid.includes(key))
    }
  },
  methods: {
    onUpdate(prop, value) {
      this.$emit('update', prop, value)
    },
    onUpdateOptions(prop, value) {
      this.$emit('update', 'options', {
        ...this.input.options,
        [prop]: value
      })
    },
    onRemove() {
      this.$emit('remove')
    }
  }
}
