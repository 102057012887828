
import Column from '@/mixins/column'
import { isBlank } from 'vessel/utils'

export default {
  name: 'LabelColumn',
  mixins: [Column],
  computed: {
    iconAttrs() {
      return isBlank(this.value)
        ? { name: 'minus-circle', color: 'grey-light' }
        : { name: 'check-circle', color: 'success' }
    }
  }
}
