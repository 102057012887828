
import SeoRating from './seo-rating'

export default {
  name: 'AssessorList',
  components: {
    SeoRating
  },
  props: {
    results: {
      type: Object,
      default: () => ({})
    },
    border: {
      type: Boolean,
      default: true
    },
    outline: {
      type: Boolean,
      default: true
    },
    background: {
      type: Boolean,
      default: true
    },
    spacing: {
      type: String,
      default: 'xs'
    },
    fontSize: {
      type: String,
      default: 'sm'
    }
  },
  vessel: {
    list: {
      $fontSize: 'fontSize',
      textColor: 'grey-darkest',
      maxWidth: 'full',
      overflowX: 'auto',
      $outline: {
        borderWidth(value) {
          return value ? 1 : null
        }
      },
      $background: {
        backgroundColor(value) {
          return value ? 'white' : null
        }
      }
    },
    item: {
      $spacing: 'padding',
      overflowX: 'visible'
    },
    text: {
      padding: '2xs',
      whiteSpace: 'no-wrap'
    }
  },
  computed: {
    ratings() {
      const types = ['feedback', 'bad', 'ok', 'good']

      return types.reduce((results, type) => {
        const items = this.results[type]

        if (items && items.length) {
          items.forEach(item => {
            if (item && item.text != '')
              results.push(item)
          })
        }

        return results
      }, [])
    },
    iconSize() {
      return this.fontSize == 'xs' ? 'sm' : 'lg'
    }
  },
  methods: {
    itemBorder(index) {
      return (this.border && (index + 1) < this.ratings.length) ? 1 : null
    }
  }
}
