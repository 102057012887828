
import Input from '@/mixins/input'

const typeKinds = {
  RANGE: ['PRICE'],
  COLOR: ['PROPERTY', 'OPTION'],
  TREE:  ['CATEGORIES', 'TAXONOMY']
}

export default {
  mixins: [Input],
  props: {
    value: {
      type: String,
      default: null
    }
  },
  watch: {
    value(newValue) {
      this.selected = newValue
    },
    kind(newKind) {
      if (this.needsChange) {
        this.changeValue('SELECT')
      }

      if (newKind == 'PRICE') {
        this.changeValue('RANGE')
      }
    }
  },
  data() {
    return {
      kindName: null,
      selected: null
    }
  },
  computed: {
    kind() {
      return this.kindName || this.record.kind
    },
    needsChange() {
      return !this.isValid(this.selected)
    },
    options() {
      const data = this.record['displayAsEnum']
      const opts = Object(data).enumeration || []

      return opts.filter(item => this.isValid(item.value))
    }
  },
  methods: {
    onFormUpdate(value) {
      this.kindName = value.kind
    },
    onChange(value) {
      this.$emit('input', value)
    },
    changeValue(value) {
      if (value != this.selected) {
        this.$emit('input', value)
      }
    },
    isValid(type) {
      const kinds = typeKinds[type]
      const valid = kinds ? kinds.includes(this.kind) : true

      return this.kind == 'PRICE' ? type == 'RANGE' : valid
    }
  },
  beforeMount() {
    this.recordForm.$on('update', this.onFormUpdate)
  },
  beforeDestroy() {
    this.recordForm.$off('update', this.onFormUpdate)
  }
}
