
import Input from '@/mixins/input'
import { nanoid } from 'nanoid'

export default {
  name: 'AttributeValues',
  mixins: [Input],
  props: {
    type: {
      type: String,
      default: null,
      required: true
    },
    value: {
      type: [Array],
      default: () => []
    },
    attributesKey: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDisabled: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value(newValue) {
      this.values = newValue
    },
    record(newValue) {
      if (this.attributesKey) {
        this.attributeIds = newValue[this.attributesKey]
      }
    },
    attributesKey(newValue) {
      if (newValue && this.record) {
        this.attributeIds = this.record[newValue]
      }
    },
    attributeIds(newValue) {
      if (newValue) {
        this.values = newValue.map(() => ({ ...this.emptyItem, valueId: '' }))

        this.$nextTick(() => {
          newValue.forEach((id, index) => {
            const item = this.$refs[`select-${index}`] || {}
            item.activated = id
          })
        })
      }
    }
  },
  data() {
    return {
      values: [],
      attributeIds: [],
      dialogKey: nanoid(),
      createModel: {},
      createProperty: null,
      createSelect: null,
      dialogLoading: false
    }
  },
  computed: {
    emptyItem() {
      return {
        id: null,
        valueId: null,
        sortOrder: null
      }
    }
  },
  methods: {
    parser(data) {
      return {
        value: data.id,
        label: data.internalName || data.name,
        multiple: data.multiple,
        options: data.values.map(
          ({ id, value }) => ({ value: id, label: value })
        )
      }
    },
    selectedAttr(index) {
      if (this.attributeIds) {
        return this.attributeIds[index]
      }
    },
    disabledItems(data) {
      const result = data || []
      const values = this.values || []

      if (!result.length || !values.length) {
        return []
      }

      return values.reduce((disabled, { _destroy, valueId }) => {
        if (!_destroy) {
          const active = result.find(({ options }) => {
            return options.some(({ value }) => value == valueId)
          })

          if (active && !active.multiple) {
            disabled.push(active.value)
          }
        }

        return disabled
      }, [])
    },
    onChange(property, index, newValue) {
      const valueObj = this.values[index]

      property.valueId = newValue

      if (valueObj) {
        valueObj.valueId = newValue
      }

      this.$emit('input', this.values)
    },
    onSortChange(value) {
      this.$emit('input', value)
    },
    onAddValueClick(index) {
      const object = this.$refs[`select-${index}`]
      const active = object && object.active

      if (active) {
        this.createSelect   = object
        this.createProperty = active

        this.$nextTick(() => this.$refs.createDialog.open())
      } else {
        this.createSelect   = null
        this.createProperty = null
      }
    },
    onCreateReset() {
      this.createModel = {}
    },
    async onCreateValid(value) {
      const dialog = this.$refs.createDialog

      this.dialogLoading = true

      const { data } = await this.$graphql.mutate({
        path: 'eshop/attribute-value',
        mutation: 'Create',
        locale: this.locale,
        variables: {
          input: {
            data: {
              propertyId: this.createProperty,
              ...value
            }
          }
        }
      })

      await this.$refs.query.refetch()

      this.dialogLoading = false

      this.$nextTick(() => {
        this.createSelect.onSelect(data.id)

        this.createModel    = {}
        this.createSelect   = null
        this.createProperty = null

        dialog.close()
        this.dialogKey = nanoid()
      })
    }
  }
}
